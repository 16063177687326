import { State } from "../state";
import { Action } from "../actions";

export function aboutReducer(
  state: State["about"],
  action: Action
): State["about"] {
  switch (action.type) {
    case "openAboutModal":
      return { isModalOpen: true };
    case "closeAboutModal":
      return { isModalOpen: false };
  }
  return state;
}
