import { isEqual } from "lodash-es";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { State } from "../../state";
import { GameState, gameStateToQueryParams } from "../../state/game";
import { isPWA } from "../../pwaUtil";
import { savePath } from "../../localStorage";

export const AddressBar: React.FC = () => {
  const gameState = useSelector<State, GameState>(state => state.gameState);
  const params = gameStateToQueryParams(gameState);
  const paramsStr = queryString.stringify(params);
  const turn = useSelector<State, number>(state => state.gameState.turn);
  const gameInitSequence = useSelector<State, State["gameInitSequence"]>(
    state => state.gameInitSequence
  );
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      gameInitSequence.type === "initial" ||
      gameInitSequence.type === "kifuLoaded"
    )
      return;
    const currentParamsStr = location.search.slice(1);
    const currentParams = queryString.parse(currentParamsStr);
    const currentTurn = Number.parseInt(location.hash.slice(1), 10);
    try {
      if (!isEqual(currentParamsStr, paramsStr) || currentTurn !== turn) {
        const path = params ? `?${paramsStr}#${turn}` : `#${turn}`;
        if (currentParams.kifu !== params.kifu) {
          history.push(path);
        } else {
          history.replace(path);
        }

        if (isPWA()) {
          savePath(path);
        }
      }
    } catch (error) {
      console.error(error);
    }
  });
  return <></>;
};

export type QueryParams = {
  kifu?: string;
  moves?: string;
  branch?: string;
  branchFrom?: string;
  initpos?: string;
};
