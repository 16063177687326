import { Action } from "../actions";
import { initialState, State } from "../state";
import { aboutReducer } from "./about";
import { errorMessageReducer } from "./errorMessage";
import { flashMessageQueueReducer } from "./flashMessageQueue";
import { gameInitSequenceReducer } from "./gameInitSequence";
import { gameStateReducer } from "./gameState";
import { kifuApiReducer } from "./kifuApi";
import { kifuLoaderReducer } from "./kifuLoader";
import { moveInputReducer } from "./moveInput";
import { scoreDatabaseMultiReducer } from "./scoreDatabaseMulti";
import { scoreDetailReducer } from "./scoreDetail";
import { settingReducer } from "./setting";
import { userReducer } from "./user";

export function reducer(state: State = initialState, action: Action): State {
  return {
    ...generalReducer(state, action),
    gameState: gameStateReducer(state, action),
    gameInitSequence: gameInitSequenceReducer(state, action),
    moveInput: moveInputReducer(state, action),
    scoreDatabaseMulti: scoreDatabaseMultiReducer(state, action),
    kifuLoader: kifuLoaderReducer(state.kifuLoader, action),
    about: aboutReducer(state.about, action),
    setting: settingReducer(state, action),
    user: userReducer(state.user, action),
    errorMessage: errorMessageReducer(state.errorMessage, action),
    flashMessageQueue: flashMessageQueueReducer(
      state.flashMessageQueue,
      action
    ),
    scoreDetail: scoreDetailReducer(state.scoreDetail, action),
    kifuApi: kifuApiReducer(state.kifuApi, action),
  };
}

function generalReducer(state: State, action: Action): State {
  switch (action.type) {
    case "warsErrorModal":
      return {
        ...state,
        showWarsError: action.dir === "open",
      };
    case "loginModal":
      return {
        ...state,
        loginModal: {
          isModalOpen: action.dir === "open",
          showNoLoginButton: action.showNoLoginButton,
          message: action.message,
          isCancellable: action.isCancellable,
        },
      };
    default:
      return state;
  }
}
