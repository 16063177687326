import {
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Button
} from "@chakra-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../state";
import { Dispatch } from "../store";
import { FlashMessage as FlashMessageContent } from "../state/flashMessage";

export const FlashMessage: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const initialRef = React.useRef();

  const onClose = () => dispatch({ type: "flashMessageClose" });
  const flashMessage = useSelector<State, FlashMessageContent | null>(
    state => state.flashMessageQueue[0] || null
  );

  if (!flashMessage) return <></>;

  const closeOnOverlayClick = flashMessage.modalType === "light";
  const showsModalCloseButton = flashMessage.modalType === "light";

  return (
    <Modal
      isOpen={true}
      initialFocusRef={initialRef as any}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" top="32" ref={initialRef}>
        <ModalHeader textAlign="center">
          {flashMessage.title || (
            <>
              <Icon name="info-outline" size="18px" mb="1" /> Information
            </>
          )}
        </ModalHeader>
        {showsModalCloseButton ? <ModalCloseButton /> : null}
        <ModalBody mb="4" mx="4">
          {flashMessage.messages.map((message, idx) => (
            <Text key={idx} fontSize="sm">
              {message}
            </Text>
          ))}
          {flashMessage.content ? flashMessage.content : null}
        </ModalBody>
        {flashMessage.modalType === "agree" ? (
          <ModalFooter>
            <Button variantColor="teal" onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
