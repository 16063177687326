import { applyMiddleware, compose, createStore, Store } from "redux";
import { createEpicMiddleware } from "redux-observable";
import thunk, {
  ThunkAction as ReduxThunkAction,
  ThunkDispatch
} from "redux-thunk";
import { Action } from "./actions";
import { rootEpic } from "./epic";
import { reducer } from "./reducer";
import { State } from "./state";

const epicMiddleware = createEpicMiddleware<Action, Action, State>();

export function configureStore(): Store<State, Action> {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(epicMiddleware, thunk))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

export type Dispatch = ThunkDispatch<State, undefined, Action>;
export type ThunkAction = ReduxThunkAction<void, State, undefined, Action>;
