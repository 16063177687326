import {
  Box,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea
} from "@chakra-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  parseSfenPosition,
  parseSfenString,
  parseKif,
  parseCsa
} from "shogi-ts";
import { loadKifUrl, startParsedGame } from "../../actions/game";
import { analytics } from "../../firebase";
import { State } from "../../state";
import { Dispatch } from "../../store";

export type kifuLoaderProps = {};

export const KifuLoader: React.FC<kifuLoaderProps> = (
  props: kifuLoaderProps
) => {
  const [value, setValue] = React.useState<string>("");
  const dispatch = useDispatch<Dispatch>();

  const focusElem: any = React.useRef();
  const isOpen = useSelector<State, boolean>(
    state => state.kifuLoader.isModalOpen
  );

  if (!isOpen && value.length > 0) setValue("");
  const onClose = () => {
    dispatch({ type: "closeKifuLoaderModal" });
  };

  useEffect(() => {
    if (!isOpen) return;
    parseValue(value, dispatch);
  });

  return (
    <Modal initialFocusRef={focusElem} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg" {...props}>
        <ModalHeader pb="0">棋譜読み込み</ModalHeader>
        <ModalCloseButton />
        <ModalBody mt="2" mb="4">
          <Box fontSize="sm" color="gray.500" mb="2">
            Available formats:
            <List styleType="disc">
              <ListItem>KIF</ListItem>
              <ListItem>CSA</ListItem>
              <ListItem>SFEN</ListItem>
              <ListItem>棋譜ファイルのURL (KIF, CSA)</ListItem>
            </List>
          </Box>
          <Textarea
            ref={focusElem}
            placeholder="position startpos moves 7g7f 3c3d 2g2f"
            onChange={(e: any) => setValue(e.target.value)}
            value={value}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function parseValue(value: string, dispatch: Dispatch): boolean {
  const lines = value.split("\n");
  {
    const result = parseSfenString(lines[0]);
    if (result.type === "success") {
      dispatch({ type: "closeKifuLoaderModal" });
      dispatch(startParsedGame(result.game, undefined));
      return true;
    }
  }
  {
    const result = parseSfenPosition(lines[0]);
    if (result.type === "success") {
      dispatch({ type: "closeKifuLoaderModal" });
      dispatch(startParsedGame(result.game, undefined));
      return true;
    }
  }

  if (lines.length > 1) {
    {
      const result = parseKif(value);
      if (result.type === "success") {
        dispatch({ type: "closeKifuLoaderModal" });
        dispatch(startParsedGame(result.game, undefined));
        return true;
      }
    }
    {
      const result = parseCsa(value);
      if (result.type === "success") {
        dispatch({ type: "closeKifuLoaderModal" });
        dispatch(startParsedGame(result.game, undefined));
        return true;
      }
    }
  }

  const urls = value.match(/\bhttps?:\/\/\S+/gi);
  const warsUrl = (urls || []).find(
    url =>
      url.startsWith("https://kif-pona.heroz.jp/games/") ||
      url.startsWith("https://shogiwars.heroz.jp/games/")
  );
  if (warsUrl) {
    analytics.logEvent("open_wars");
    dispatch({ type: "closeKifuLoaderModal" });
    dispatch({ type: "warsErrorModal", dir: "open" });
    return true;
  }
  const kifuUrl = (urls || []).find(
    url =>
      url.split("?")[0].endsWith(".kif") || url.split("?")[0].endsWith(".csa")
  );
  if (kifuUrl) {
    dispatch({ type: "closeKifuLoaderModal" });
    dispatch(loadKifUrl(kifuUrl, 9999, undefined));
    return true;
  }
  return false;
}
