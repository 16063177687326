import { Box, Divider, Heading, Select, Text, Checkbox } from "@chakra-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../actions/user";
import { UserDetail } from "../../state/user";
import { Dispatch } from "../../store";

export type UserDataProps = {
  user: UserDetail;
};
export const EvalSetting: React.FC<UserDataProps> = ({ user }) => {
  const dispatch = useDispatch<Dispatch>();
  const updateMultiPv = (multiPv: number) => {
    if (user.userSubscription.type !== "active") return;
    dispatch(updateUserData({ ...user.userData, multiPv }));
  };
  const updateDisplayMobileMultiPv = (multiPv: number) => {
    dispatch(
      updateUserData({ ...user.userData, displayMobileMultiPv: multiPv })
    );
  };
  const updateLogisticChart = (logisticChart: boolean) => {
    dispatch(updateUserData({ ...user.userData, logisticChart }));
  };
  return (
    <>
      <Heading size="md" mt="8">
        解析設定
      </Heading>
      <Divider />
      <Box pl="4">
        <Heading size="xs" mb="2">
          解析候補手数
        </Heading>
        <Box>
          <Select
            aria-labelledby="multiPv"
            size="sm"
            w="10rem"
            defaultValue={user.userData.multiPv.toString()}
            onChange={(e: any) =>
              updateMultiPv(Number.parseInt(e.target.value))
            }
            isDisabled={user.userSubscription.type !== "active"}
          >
            <option value="1">1 (高精度)</option>
            <option value="2">2</option>
            <option value="3">3 (バランス)</option>
            <option value="4">4</option>
            <option value="5">5 (低精度)</option>
          </Select>
        </Box>
        <Heading size="xs" mb="2" mt="4">
          表示候補手数 (スマホ)
        </Heading>
        <Text fontSize="sm" color="gray.500">
          端末の画面サイズに合わせて変更してください。
        </Text>
        <Box>
          <Select
            aria-labelledby="displayMobileMultiPv"
            size="sm"
            w="10rem"
            defaultValue={user.userData.displayMobileMultiPv.toString()}
            onChange={(e: any) =>
              updateDisplayMobileMultiPv(Number.parseInt(e.target.value))
            }
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </Select>
        </Box>
        <Heading size="xs" mb="2" mt="4">
          評価値グラフ
        </Heading>
        <Box>
          <Checkbox
            size="sm"
            defaultIsChecked={user.userData.logisticChart}
            onChange={(e: any) => updateLogisticChart(e.target.checked)}
          >
            (beta) 勝率換算で表示する
          </Checkbox>
        </Box>
        <Text fontSize="sm" color="gray.500" mt=".25rem">
          ±2000 を超える評価値が途切れずに反映されます
        </Text>
      </Box>
    </>
  );
};
