import {
  Box,
  Button,
  InputGroup,
  InputRightAddon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast
} from "@chakra-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../../actions/user";
import { updateUserSubscriptionQuantity } from "../../payment";
import { LoggedInUser } from "../../state/user";
import { Dispatch } from "../../store";

export type QuantityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultQuantity: number;
  user: LoggedInUser;
};
export const QuantityModal: React.FC<QuantityModalProps> = ({
  isOpen,
  onClose,
  defaultQuantity,
  user
}) => {
  const initialRef = React.useRef();
  const [quantity, setQuantity] = useState<string>(defaultQuantity.toString());
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useDispatch<Dispatch>();
  const toast = useToast();
  const updateQuantity = async (quantity: number) => {
    setIsSubmitting(true);
    const isSuccess = await updateUserSubscriptionQuantity(
      user.firebaseUser,
      quantity
    );
    if (isSuccess) {
      toast({
        title: "口数更新",
        description: "口数を更新しました。",
        status: "success",
        duration: 9000,
        isClosable: true
      });
    } else {
      toast({
        title: "エラー",
        description:
          "口数の更新に失敗しました。時間をあけて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
    setIsSubmitting(false);
    onClose();
    dispatch(setLoggedInUser(user.firebaseUser, false));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef as any}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>支援口数を入力してください</ModalHeader>
        <ModalBody mb="4" mx="4">
          <Box
            display="flex"
            mt="4"
            mb="8"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="semibold" color="gray.500">
              月額500円
            </Text>
            <Text fontSize="lg" fontWeight="semibold" color="gray.500" mx="4">
              ×
            </Text>
            <InputGroup w="120px">
              <Select
                aria-labelledby="quantity"
                onChange={(e: any) => setQuantity(e.target.value)}
                value={quantity}
              >
                {Array.from(Array(10).keys()).map(i => (
                  <option key={i} value={(i + 1).toString()}>
                    {i + 1}
                  </option>
                ))}
              </Select>
              <InputRightAddon>口</InputRightAddon>
            </InputGroup>
          </Box>
          <List listStyleType="disc" fontSize="sm">
            <ListItem>
              1口 (月額500円) で、すべての特典が受けられます。
            </ListItem>
            <ListItem>次回の決済から新しい支援口数が反映されます。</ListItem>
          </List>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            variantColor="teal"
            onClick={async () =>
              await updateQuantity(Number.parseInt(quantity))
            }
          >
            更新する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
