import { Box, BoxProps } from "@chakra-ui/core";
import React from "react";
import { Hand, Piece as ShogiPiece } from "shogi-ts";
import { BasePieceHeightPx, BasePieceWidthPx } from ".";
import { Piece } from "./Piece";
import { mapResponsiveValue } from "./util";

export type HandProps = {
  hand: Hand;
  side: "b" | "w";
  highlightPieces?: ShogiPiece[];
  onClickHand?: (piece: ShogiPiece, side: "b" | "w") => void;
} & BoxProps;
export const HandBoard: React.FC<HandProps> = ({
  hand,
  side,
  onClickHand,
  highlightPieces = [],
  ...props
}: HandProps) => {
  const pieces = PieceDisplayOrder[side]
    .filter(piece => hand[piece] && hand[piece] > 0)
    .map(piece => ({ piece, count: hand[piece] }));
  return (
    <Box
      bg="orange.100"
      border="2px"
      borderColor="gray.600"
      mt={side === "w" ? 0 : "auto"}
      borderRadius="md"
      h={mapResponsiveValue(
        BasePieceHeightPx,
        v => `calc((${v} + 2px) * 8 + 2px)`
      )}
      w={BasePieceWidthPx}
      shadow="md"
      {...props}
    >
      {pieces.map(({ piece, count }, idx) => (
        <Piece
          key={idx}
          piece={{ piece, side }}
          count={count}
          highlight={highlightPieces.includes(piece)}
          onClick={() => {
            if (onClickHand === undefined) return;
            onClickHand(piece, side);
          }}
        ></Piece>
      ))}
    </Box>
  );
};

const PieceDisplayOrder = {
  b: ["r", "b", "g", "s", "n", "l", "p"],
  w: ["p", "l", "n", "s", "g", "b", "r"]
} as const;
