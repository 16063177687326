import { pickBy } from "lodash-es";
import {
  convertExtendedMoveToSfen,
  convertMoveToSfen,
  ExtendedGame,
  ExtendedMove,
  Game,
  generateSfen,
  getInitialPosition,
  Position
} from "shogi-ts";
import { ScoreEntry } from "../evalMulti";
import { QueryParams } from "../pages/main/AddressBar";

export type GameState = KifuGame | NarabeGame;

export type NarabeGame = {
  type: "narabe";
  turn: number;
  game: Game;
  currentSfen: string;
  currentPosition: Position;
  branch?: Branch;
};

export type KifuGame = {
  type: "kifu";
  turn: number;
  game: Game;
  url: string;
  currentSfen: string;
  currentPosition: Position;
  branch?: Branch;
};

type Branch = {
  branch: ExtendedGame;
  pv: Array<ScoreEntry | undefined>;
  branchFrom: number;
};

export function getInitialState(): GameState {
  const position = getInitialPosition();
  const sfen = generateSfen(position);
  return {
    type: "narabe",
    game: {
      positions: [position],
      sfenPositions: [sfen],
      isChecked: [false],
      moves: [],
      turns: 1
    },
    turn: 0,
    currentSfen: sfen,
    currentPosition: position
  };
}

export function getBranchMoves({ branch, branchFrom }: Branch): ExtendedMove[] {
  return branch.moves.slice(branchFrom);
}

export function gameStateToQueryParams(
  gameState: GameState,
  anonymize: boolean = false
): QueryParams {
  const branch = gameState.branch
    ? getBranchMoves(gameState.branch)
        .map(convertExtendedMoveToSfen)
        .join(".") || undefined
    : undefined;
  const branchFrom = gameState.branch ? gameState.branch.branchFrom : undefined;
  if (gameState.type === "kifu" && !anonymize) {
    return pickBy(
      {
        kifu: gameState.url,
        branch,
        branchFrom
      },
      v => v !== undefined
    );
  }
  const moves = gameState.game.moves.map(convertMoveToSfen).join(".");
  return pickBy(
    {
      moves,
      branch,
      branchFrom,
      initpos: generateInitPos(gameState.game) || undefined
    },
    v => v !== undefined && v !== ""
  );
}

function generateInitPos(game: Game): string | null {
  const position = game.sfenPositions[0];
  if (
    position.startsWith(
      "lnsgkgsnl/1r5b1/ppppppppp/9/9/9/PPPPPPPPP/1B5R1/LNSGKGSNL b -"
    )
  )
    return null;
  return position;
}
