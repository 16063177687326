import queryString from "query-string";
import { useSelector } from "react-redux";
import { State } from "../../state";
import { GameState, gameStateToQueryParams } from "../../state/game";

const ShareDomain = "https://share.kento-shogi.com";

export function useShare() {
  const gameState = useSelector<State, GameState>(state => state.gameState);
  const queryParams = queryString.stringify(
    gameStateToQueryParams(gameState, true)
  );
  const urlToShare =
    ShareDomain +
    (queryParams ? `/?${queryParams}&turn=${gameState.turn}` : "");
  const twitterLink = `https://twitter.com/intent/tweet?${new URLSearchParams({
    url: urlToShare
  }).toString()}`;

  return {
    urlToShare,
    twitterLink,
    isWebShareApiEnable: isWebShareApiEnable(),
    doWebShare: () => doWebShare(urlToShare)
  };
}

function isWebShareApiEnable(): boolean {
  return !!(window.navigator as any).share;
}

function doWebShare(url: string) {
  if (!isWebShareApiEnable()) return;
  (window.navigator as any)
    .share({
      url
    })
    .catch(() => {});
}
