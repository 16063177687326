import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase, { analytics } from "../../firebase";
import { State } from "../../state";
import { Dispatch } from "../../store.js";
import { isValidEmail } from "../../login";

export const LoginModal: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const onClose = () => dispatch({ type: "loginModal", dir: "close" });
  const initialRef = React.useRef();
  const bg = "white";

  const handleTwitterLogin = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    analytics.logEvent("login", { method: "twitter.com" });
    firebase.auth().signInWithRedirect(provider);
  };

  const handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    analytics.logEvent("login", { method: "google.com" });
    firebase.auth().signInWithRedirect(provider);
  };

  const handleNotLogin = () => {
    dispatch({ type: "loginModal", dir: "close" });
  };

  const handleEmailLinkLogin = async (email: string) => {
    if (!(await isValidEmail(email))) {
      analytics.logEvent("loginFailed", { method: "password" });
      dispatch({ type: "loginModal", dir: "close" });
      dispatch({
        type: "flashMessageOpen",
        messages: [`${email} は登録されていないメールアドレスです。`],
        modalType: "agree"
      });
      return;
    }
    analytics.logEvent("login", { method: "password" });
    await firebase.auth().sendSignInLinkToEmail(email, {
      url: window.location.href,
      handleCodeInApp: true
    });
    window.localStorage.setItem("emailForSignIn", email);
    dispatch({ type: "loginModal", dir: "close" });
    dispatch({
      type: "flashMessageOpen",
      messages: [
        `${email} にログインリンクを送信しました。`,
        "このタブを閉じて、メールのリンクを開いてください。"
      ],
      modalType: "dead"
    });
  };

  const { showNoLoginButton, isModalOpen, isCancellable } = useSelector<
    State,
    State["loginModal"]
  >(state => state.loginModal);

  return (
    <Modal
      isOpen={isModalOpen}
      initialFocusRef={initialRef as any}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" backgroundColor={bg} ref={initialRef}>
        <ModalHeader textAlign="center">KENTO にログイン</ModalHeader>
        {isCancellable ? <ModalCloseButton /> : null}
        <ModalBody mb="4" mx="4">
          <Box fontSize="xs" mb="4" color="gray.500">
            ログインすることにより、
            <Link color="teal.500" href="/agreement" isExternal>
              利用規約・プライバシーポリシー
            </Link>
            を読み、これに同意するものとします。
          </Box>
          <Box textAlign="center">
            <GoogleLoginButton onClick={handleGoogleLogin} />
            <TwitterLoginButton onClick={handleTwitterLogin} mt="4" />
            {showNoLoginButton ? (
              <Button onClick={handleNotLogin} bg={bg} mt="4">
                ログインせずに続ける
              </Button>
            ) : (
              undefined
            )}
            <Divider my="6" />
            <Text fontSize="sm" color="gray.500" mb="3">
              または
            </Text>
            <MailLinkLogin onClickSendButton={handleEmailLinkLogin} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type LoginButtonProps = {
  onClick: () => void;
} & Omit<ButtonProps, "children">;

const GoogleLoginButton: React.FC<LoginButtonProps> = ({
  onClick,
  ...props
}) => (
  <Button
    onClick={onClick}
    width="100%"
    height="48px"
    bg="white"
    shadow="sm"
    borderWidth="1px"
    _hover={{ bg: "white" }}
    {...props}
  >
    <Image src="/assets/logo_google2.png" size="32px" />
    <Box>Google でログイン</Box>
  </Button>
);

const TwitterLoginButton: React.FC<LoginButtonProps> = ({
  onClick,
  ...props
}) => (
  <Button
    onClick={onClick}
    width="100%"
    height="48px"
    bg="#1da1f2"
    shadow="sm"
    borderWidth="1px"
    _hover={{ bg: "#1da1f2" }}
    color="white"
    {...props}
  >
    <Image src="/assets/logo_twitter.png" size="32px" />
    <Box>Twitter でログイン</Box>
  </Button>
);

const emailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
type MailLinkLoginProps = {
  onClickSendButton: (email: string) => void;
};
const MailLinkLogin: React.FC<MailLinkLoginProps> = ({ onClickSendButton }) => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const handleEmailChange = (value: string) => {
    setEmailValue(value);
    setIsButtonActive(emailValidation.test(value));
  };

  return (
    <Box>
      <Input
        placeholder="your_mail@example.com"
        value={emailValue}
        onChange={(e: any) => handleEmailChange(e.target.value)}
      />
      <Button
        variant="ghost"
        size="sm"
        mt="4"
        isDisabled={!isButtonActive}
        isLoading={isButtonLoading}
        onClick={() => {
          setIsButtonLoading(true);
          onClickSendButton(emailValue);
        }}
      >
        メールアドレスにログインリンクを送信
      </Button>
    </Box>
  );
};
