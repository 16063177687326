import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "../state";
import { analytics } from "../firebase";
import { getUserPlan } from "../state/user";

export const Analytics: React.FC = () => {
  const user = useSelector<State, State["user"]>(state => state.user);

  useEffect(() => {
    if (user.type === "loading") return;
    const userPlan = getUserPlan(user);
    const authProvider =
      user.type === "loggedIn"
        ? user.firebaseUser.providerData.map(p => p?.providerId).join("/")
        : undefined;

    analytics.setUserProperties({
      user_plan: userPlan,
      auth_provider: authProvider
    });

    if (user.type === "loggedIn") {
      analytics.setUserId(user.firebaseUser.uid);
    }
  }, [user]);
  return <></>;
};
