import { Badge, Box, BoxProps, Spinner, Text } from "@chakra-ui/core";
import React from "react";
import { FaCrown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { convertMoveJp } from "shogi-ts";
import { applyPv } from "../../actions/game";
import { State } from "../../state";
import { Dispatch } from "../../store";
import { displayScore, getScoreView, ScoreView } from "./scoreMoveUtil";

export const ScoreMoveRowMulti: React.FC<BoxProps> = props => {
  const { type, bestScore, isProPerf, isCalculating } = useSelector<
    State,
    ScoreView
  >(state => getScoreView(state, state.gameState.turn));
  const dispatch = useDispatch<Dispatch>();

  const move = bestScore?.pv?.[0];
  const moveJp = move ? convertMoveJp(move) : "-";
  const pv = bestScore?.pv?.slice(1, 5) || [];
  const pvJp = pv.map(convertMoveJp);

  const textColor = type === "low_perf" ? "gray.700" : "gray.700";
  const grayTextColor = type === "low_perf" ? "gray.500" : "gray.500";
  const onClickScore = () => {
    if (type === "low_perf" || type === "normal") {
      dispatch(applyPv(0));
    }
    if (type === "pv") {
      dispatch({ type: "forward" });
    }
  };

  return (
    <Box display="flex" height="48px" onClick={onClickScore} {...props}>
      <Box mr="2">
        {type === "low_perf" ? (
          <Box h="18px" lineHeight="14px">
            <Badge h="18px" lineHeight="18px" variantColor="red">
              低精度
            </Badge>
          </Box>
        ) : type === "pv" ? (
          <Box h="18px" lineHeight="14px">
            <Badge h="18px" lineHeight="18px" variantColor="teal">
              読み筋
            </Badge>
          </Box>
        ) : (
          <Box display="flex" alignItems="flex-end">
            {isProPerf ? (
              <Box
                as={FaCrown}
                height="14px"
                mr="1"
                color="yellow.500"
                mb="0.25rem"
              />
            ) : null}
            <Text
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="xs"
              letterSpacing="wide"
              color="teal.600"
            >
              score
            </Text>
          </Box>
        )}
        <Box display="flex">
          {isCalculating ? (
            <Box height="30px" display="flex" mr={1} alignItems="center">
              <Spinner speed="0.65s" />
            </Box>
          ) : null}
          {displayScore(bestScore) ? (
            <Text
              lineHeight="30px"
              fontSize="xl"
              whiteSpace="nowrap"
              fontWeight="semibold"
              color={textColor}
            >
              {displayScore(bestScore)}
            </Text>
          ) : null}
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box mr="2">
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="xs"
            letterSpacing="wide"
            color="teal.600"
            h="18px"
          >
            moves
          </Text>
          <Text
            fontSize="xl"
            fontWeight="semibold"
            whiteSpace="nowrap"
            color={textColor}
          >
            {moveJp}
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          flexWrap="wrap"
          height="100%"
          overflowY="scroll"
          pb="2px"
        >
          {pvJp.map((pv, idx) => (
            <Text
              fontSize="xs"
              color={grayTextColor}
              key={idx}
              whiteSpace="nowrap"
            >
              {pv}
            </Text>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
