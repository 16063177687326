import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/core";
import React from "react";
import { MdMenu } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isSupporterTestingTarget } from "../../abTesting";
import { startNarabeGame } from "../../actions/game";
import firebase from "../../firebase";
import { State } from "../../state";
import { User } from "../../state/user";
import { Dispatch } from "../../store";
export type AppMenuProps = BoxProps;

export const AppMenu: React.FC<BoxProps> = (props) => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, User>((state) => state.user);
  const handleLogoutClick = () => {
    firebase.auth().signOut();
  };
  return (
    <Box {...props}>
      <Menu>
        <MenuButton>
          <Box as={MdMenu} fontSize="28px" />
        </MenuButton>
        <MenuList>
          {isSupporterTestingTarget(user) ? (
            <Box mx="4">
              <Link to="/supporter">
                <Button as={Box} size="sm" variantColor="pink">
                  KENTO Pro
                </Button>
              </Link>
              <MenuDivider />
            </Box>
          ) : null}
          <MenuGroup title="Game">
            <MenuItem
              onClick={() => {
                dispatch(startNarabeGame(0, undefined, [], undefined));
              }}
            >
              新規盤面
            </MenuItem>
            <Link to="/games">
              <MenuItem>棋譜一覧</MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                dispatch({ type: "openKifuLoaderModal" });
              }}
            >
              棋譜読み込み
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Setting">
            <Link to="/setting">
              <MenuItem>設定</MenuItem>
            </Link>
            <Link to="/account">
              <MenuItem>アカウント</MenuItem>
            </Link>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Help">
            <a
              href="https://note.com/shogi_kento/n/nea5e736f5311"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem>棋譜読み込み方法まとめ</MenuItem>
            </a>
            <Link to="/releases">
              <MenuItem>リリースノート</MenuItem>
            </Link>
            <MenuItem onClick={() => dispatch({ type: "openAboutModal" })}>
              KENTO について
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuItem onClick={handleLogoutClick}>ログアウト</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
