import {
  Box,
  BoxProps,
  CSSReset,
  Divider,
  Heading,
  IconButton,
  List,
  ListItem,
  ThemeProvider,
} from "@chakra-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { analytics } from "../../firebase";

type Note = {
  version: string;
  date: string;
  messages: Message[];
};
type Message = {
  title?: string;
  body: string;
};
const notes: Note[] = [
  {
    version: "2.7.12",
    date: "2022/12/09",
    messages: [
      {
        title: "ログイン挙動修正",
        body: "iOS 16 でのログインエラーを修正しました。",
      },
    ],
  },
  {
    version: "2.7.11",
    date: "2021/08/10",
    messages: [
      {
        title: "エンジン・評価関数更新",
        body: "エンジンをやねうら王V6.00にバージョンアップ、評価関数を水匠4に更新しました。",
      },
    ],
  },
  {
    version: "2.7.5",
    date: "2020/05/05",
    messages: [
      {
        title: "エンジン・評価関数更新",
        body: "エンジンをやねうら王V4.89にバージョンアップ、評価関数を水匠2に更新しました。",
      },
    ],
  },
  {
    version: "2.7.0",
    date: "2020/04/08",
    messages: [
      {
        title: "(beta) 評価値グラフの勝率ベース表示",
        body: "±2000 を超える評価値がグラフに反映されます。[設定] より有効化できます。",
      },
    ],
  },
  {
    version: "2.6.3",
    date: "2020/04/01",
    messages: [
      { body: "一部のケースで計算結果が表示されない不具合を修正" },
      { body: "未ログイン時の計算精度を制限" },
    ],
  },
  {
    version: "2.6.0",
    date: "2020/03/18",
    messages: [
      {
        title: "画像付きシェア",
        body: "シェアボタンからリンク共有した際に、SNS 上で局面画像が表示されるようになりました",
      },
    ],
  },
  {
    version: "2.5.6",
    date: "2020/03/11",
    messages: [{ body: "レイアウト調整" }],
  },
  {
    version: "2.5.5",
    date: "2020/03/06",
    messages: [
      { body: "一括解析処理の画面のもたつきを解消、サクサクになりました" },
    ],
  },
  {
    version: "2.5.3",
    date: "2020/02/28",
    messages: [
      { body: "一部局面でスコア読み込みが終わらないバグを修正" },
      { body: "アプリ登録時、前回開いていた画面を開く" },
    ],
  },
  {
    version: "2.5.0",
    date: "2020/02/24",
    messages: [
      {
        title: "棋譜読み込み機能",
        body: "各種形式の棋譜を直接読み込めるようになりました。メニューの [棋譜読み込み] より棋譜を貼り付けてください。",
      },
      {
        body: "シェアURLが常に最終手になってしまうバグを修正",
      },
      { body: "棋譜リストAPIをHTTPに対応" },
    ],
  },
  {
    version: "2.4.2",
    date: "2020/02/22",
    messages: [
      {
        title: "シェア機能(スマホのみ)",
        body: "シェアボタンを追加しました。現在の棋譜・分岐・スコアをそのままSNSにシェアできます。",
      },
    ],
  },
  {
    version: "2.4.0",
    date: "2020/02/21",
    messages: [
      {
        title: "読み筋展開",
        body: "読み筋をクリックすると、読み筋が分岐に反映されます。",
      },
      {
        title: "パス検討",
        body: "盤面左下の矢印クロスアイコンをクリックすると、局面そのままで手番が相手に移ります。狙い筋・詰みの有無などを調べたいときに便利です。",
      },
      {
        title: "Twitter共有",
        body: "メニューにTwitter共有機能を追加しました。",
      },
    ],
  },
  {
    version: "2.3.9",
    date: "2020/02/18",
    messages: [{ body: "画面表示高速化" }, { body: "その他バグ修正" }],
  },
  {
    version: "2.3.4",
    date: "2020/02/12",
    messages: [
      {
        title: "スマホ複数候補手表示",
        body: "[設定]メニューより、表示する候補手の数をスマホの画面サイズに合わせて変更できます。",
      },
    ],
  },
  {
    version: "2.3.1",
    date: "2020/02/09",
    messages: [
      {
        title: "将棋クエスト対応",
        body: "[設定]から将棋クエストIDを登録すると、[棋譜一覧]に棋譜が表示されます。",
      },
    ],
  },
];

export const ReleaseNote: React.FC<BoxProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    analytics.logEvent("page_view_releases");
    window.document.title = "KENTO | リリースノート";
  }, []);

  return (
    <ThemeProvider>
      <CSSReset />
      <Box
        my={{ base: 2, md: 4 }}
        mx="auto"
        px={{ base: 1, md: 8 }}
        maxWidth="1024px"
        color="gray.700"
      >
        <Box display="flex" alignItems="flex-end">
          <Heading w="100%" onClick={() => history.push("/")}>
            <span style={{ cursor: "pointer", userSelect: "none" }}>
              ☗ KENTO
            </span>
          </Heading>
          <IconButton
            aria-label="close"
            icon="close"
            variant="ghost"
            onClick={() => history.push("/")}
          />
        </Box>
        <Box fontSize="sm" mt="8" mx="2" {...props}>
          <Heading size="md" mt="4">
            リリースノート
          </Heading>
          <Divider />
          {notes.map((note, idx) => (
            <Box key={idx} my="6">
              <Heading size="sm" my="2">
                {note.date} (version {note.version})
              </Heading>
              <List styleType="disc">
                {note.messages.map((line, mIdx) => (
                  <ListItem key={mIdx}>
                    {line.title ? (
                      <span
                        style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                      >
                        [{line.title}]
                      </span>
                    ) : null}
                    {line.body}
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
