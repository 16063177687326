import { UserDataRecord, UserSubscriptionRecord } from "./record";
import { UserDetail } from "./state/user";

export function saveUser(user: UserDetail) {
  window.localStorage.setItem("user", JSON.stringify(user));
}

export function loadUser(): UserDetail | null {
  const item = window.localStorage.getItem("user");
  if (!item) return null;
  const user = JSON.parse(item) as UserDetail;
  if (
    !UserDataRecord.validate(user.userData).success ||
    !UserSubscriptionRecord.validate(user.userSubscription).success
  ) {
    clearSavedUser();
    return null;
  }
  return user;
}

export function clearSavedUser() {
  window.localStorage.removeItem("user");
}

export function saveVersion(version: string) {
  window.localStorage.setItem("version", version);
}

export function loadVersion(): string | null {
  const item = window.localStorage.getItem("version");
  if (!item) return null;
  return item;
}

export function clearSavedVersion() {
  window.localStorage.removeItem("version");
}

type PathData = {
  path: string;
  savedAt: number;
};
export function savePath(path: string) {
  const savedAt = Date.now() / 1000;
  window.localStorage.setItem("path", JSON.stringify({ path, savedAt }));
}

export function loadSavedPath(): string | null {
  const item = window.localStorage.getItem("path");
  if (!item) return null;
  const pathData = JSON.parse(item) as PathData;
  if (
    typeof pathData.savedAt === "number" &&
    typeof pathData.path === "string"
  ) {
    if (Date.now() / 1000 - pathData.savedAt < 60 * 60 * 24) {
      return pathData.path;
    }
  }
  return null;
}

export function clearSavedPath() {
  window.localStorage.removeItem("path");
}

type NonLoginEvalHistory = Array<number>;
const NonLoginEvalLimitPerHour = 5;
export function tryNonLoginEval(): boolean {
  const item = window.localStorage.getItem("nonLoginEvalHistory");
  const history = (item
    ? (JSON.parse(item) as NonLoginEvalHistory)
    : []
  ).filter(h => h > Date.now() / 1000 - 60 * 60);

  if (history.length >= NonLoginEvalLimitPerHour) return false;
  history.push(Date.now() / 1000);
  window.localStorage.setItem("nonLoginEvalHistory", JSON.stringify(history));
  return true;
}
