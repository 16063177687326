import { isEqual } from "lodash-es";
import { Cell, getLegalMoves, Move, Piece } from "shogi-ts";
import { analytics } from "../firebase";
import { ThunkAction } from "../store";

export type MoveInputActions =
  | InputCompleteAction
  | InputMoveAlmostCompleteAction
  | InputMoveFromCellAction
  | InputMoveFromHandAction
  | InputMoveToAction
  | DoPassAction
  | ClearMoveInputAction
  | ClearBranchAction;

export type InputCompleteAction = {
  type: "inputMoveComplete";
  move: Move;
  applyingBranch?: "branch" | "main";
};

export type InputMoveAlmostCompleteAction = {
  type: "inputMoveAlmostComplete";
  move: Move;
};

export type InputMoveFromCellAction = {
  type: "inputMoveFromCell";
  piece: Piece;
  cell: Cell;
  legalMoves: Move[];
};

export type InputMoveFromHandAction = {
  type: "inputMoveFromHand";
  piece: Piece;
  legalMoves: Move[];
};

export type InputMoveToAction = {
  type: "inputMoveTo";
  cell: Cell;
  legalMoves: Move[];
};

export type ClearMoveInputAction = {
  type: "clearMoveInput";
};

export type DoPassAction = {
  type: "doPass";
};

export type ClearBranchAction = {
  type: "clearBranch";
};

function inputMoveComplete(
  move: Move,
  applyingBranch: "branch" | "main" | undefined = undefined
): ThunkAction {
  return (dispatch, getState) => {
    const { gameState } = getState();
    if (!applyingBranch && gameState.type === "narabe" && !gameState.branch) {
      if (
        gameState.game.turns === gameState.turn + 1 ||
        isEqual(gameState.game.moves[gameState.turn], move)
      ) {
        dispatch({ type: "inputMoveComplete", move, applyingBranch: "main" });
      } else {
        dispatch({ type: "inputMoveAlmostComplete", move });
      }
    } else {
      dispatch({ type: "inputMoveComplete", move, applyingBranch });
    }
  };
}

export function handleHandClick(piece: Piece, side: "b" | "w"): ThunkAction {
  return (dispatch, getState) => {
    const { gameState, setting } = getState();
    const { currentPosition } = gameState;
    if (setting.clickBoardToMove && gameState.type === "kifu") return;
    if (setting.rotateBoard) {
      side = side === "b" ? "w" : "b";
    }

    if (side !== currentPosition.side) {
      dispatch({ type: "clearMoveInput" });
      return;
    }
    const legalMoves = getLegalMoves(currentPosition).filter(
      move =>
        move.type === "move_from_hand" &&
        move.piece === piece &&
        move.side === side
    );
    if (legalMoves.length > 0) {
      dispatch({ type: "inputMoveFromHand", piece, legalMoves });
      return;
    }
    dispatch({ type: "clearMoveInput" });
  };
}

export function handleCellClick(cell: Cell): ThunkAction {
  return (dispatch, getState) => {
    const { moveInput, gameState, setting } = getState();
    const { currentPosition } = gameState;
    if (setting.clickBoardToMove && gameState.type === "kifu") return;
    if (setting.rotateBoard) {
      cell = {
        file: 10 - cell.file,
        rank: 10 - cell.rank
      };
    }

    if (moveInput.status === "waitingTo") {
      const moves = moveInput.legalMoves.filter(move => isEqual(move.to, cell));
      if (moves.length === 1) {
        dispatch(inputMoveComplete(moves[0]));
        return;
      }
      if (moves.length > 1) {
        dispatch({ type: "inputMoveTo", cell, legalMoves: moves });
        return;
      }
    }

    const legalMoves = getLegalMoves(currentPosition).filter(
      move => move.type === "move_from_cell" && isEqual(move.from, cell)
    );
    if (legalMoves.length > 0) {
      dispatch({
        type: "inputMoveFromCell",
        piece: legalMoves[0].piece,
        cell,
        legalMoves
      });
      return;
    }

    dispatch({ type: "clearMoveInput" });
  };
}

export function handleInputPromote(promote: boolean): ThunkAction {
  return (dispatch, getState) => {
    const {
      moveInput,
      gameState: {
        currentPosition: { side }
      }
    } = getState();
    if (moveInput.status !== "waitingPromote") return;
    dispatch(
      inputMoveComplete({
        type: "move_from_cell",
        side,
        piece: moveInput.from.piece,
        from: moveInput.from.from,
        to: moveInput.to,
        promote
      })
    );
  };
}

export function handleInputApplyingBranch(
  branch: "main" | "branch"
): ThunkAction {
  return (dispatch, getState) => {
    const { moveInput } = getState();
    if (moveInput.status === "waitingApplyingBranch") {
      dispatch(inputMoveComplete(moveInput.move, branch));
    }
  };
}

export function doPass(): DoPassAction {
  analytics.logEvent("click_do_pass");
  return { type: "doPass" };
}
