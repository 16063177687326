import { useDispatch, useSelector } from "react-redux";
import { loadHighPerfScoreMulti } from "../../actions/gameScore";
import { ScoreEntry } from "../../evalMulti";
import { getPriority, isPro } from "../../perfMulti";
import { Score, State } from "../../state";
import { getScore } from "../../state/scoreDatabaseMulti";
import { getEvalSetting } from "../../state/user";
import { Dispatch } from "../../store";

export type ScoreView = {
  type: "low_perf" | "normal" | "pv";
  candidates: ScoreEntry[];
  bestScore?: ScoreEntry;
  isProPerf: boolean;
  canUpgrade: boolean;
  isCalculating: boolean;
};

export function getScoreView(state: State, turn: number): ScoreView {
  const { gameState, user } = state;
  const evalSetting = getEvalSetting(state.user, gameState);
  const currentGame = gameState.branch
    ? gameState.branch.branch
    : gameState.game;
  const currentSfen = currentGame.sfenPositions[turn];
  const currentSide = currentGame.positions[turn].side;
  const scoreEntry = getScore(
    state.scoreDatabaseMulti,
    currentSfen,
    evalSetting.evalMultiPv
  );
  const pvScoreEntry = !gameState.branch
    ? undefined
    : gameState.branch.pv[turn];

  const candidates =
    scoreEntry?.score.multiScores
      .sort(
        (a, b) =>
          (currentSide === "b" ? -1 : 1) *
          (numericScore(a, currentSide) - numericScore(b, currentSide))
      )
      .slice(0, 5) || (pvScoreEntry !== undefined ? [pvScoreEntry] : []);

  const isPv = !scoreEntry && pvScoreEntry !== undefined;
  const scoreType = isPv
    ? "pv"
    : scoreEntry !== null && getPriority(scoreEntry.perf) <= 20
    ? "low_perf"
    : "normal";
  const canUpgrade =
    (scoreType === "pv" || scoreType === "low_perf") &&
    user.type === "loggedIn";
  const isProPerf = scoreEntry ? isPro(scoreEntry) : false;
  const isCalculating = scoreEntry?.type === "temp";

  return {
    type: scoreType,
    candidates,
    bestScore: candidates[0],
    isProPerf,
    canUpgrade,
    isCalculating
  };
}

export function useUpgradeScore(): () => void {
  const dispatch = useDispatch<Dispatch>();
  const currentSfen = useSelector<State, string>(
    state => state.gameState.currentSfen
  );
  return () => dispatch(loadHighPerfScoreMulti(currentSfen));
}

export function displayScore(score: Score | undefined): string | null {
  if (!score) return null;
  if (score.scoreMate !== undefined) {
    if (score.scoreMate === 0) return "詰み";
    const winner = score.scoreMate > 0 ? "先手" : "後手";
    return `${winner}勝ち(${Math.abs(score.scoreMate)}手)`;
  }
  if (score.scoreCp !== undefined) {
    return score.scoreCp >= 0 ? `+${score.scoreCp}` : `${score.scoreCp}`;
  }
  return null;
}

export function numericScore(
  score: Score | undefined,
  side: "b" | "w"
): number {
  if (score?.scoreCp !== undefined) return score.scoreCp;
  if (score?.scoreMate !== undefined) {
    if (score.scoreMate > 0) return 100000;
    if (score.scoreMate < 0) return -100000;
    return side === "b" ? -100000 : 100000;
  }
  return 0;
}
