import firebase, { analytics } from "./firebase";
import { Dispatch } from "./store";
import {
  updateEmailFailedAction,
  deleteAccountFailedAction
} from "./actions/flashMessage";
import * as Sentry from "@sentry/browser";

export type RedirectActions = DeleteAccount | UpdateEmail;

export type DeleteAccount = {
  type: "deleteAccount";
};

export type UpdateEmail = {
  type: "updateEmail";
  email: string;
};

function saveAction(action: RedirectActions) {
  window.localStorage.setItem("redirectAction", JSON.stringify(action));
}

function loadAction(): RedirectActions | null {
  const item = window.localStorage.getItem("redirectAction");
  if (!item) return null;
  return JSON.parse(item);
}

function clearSavedAction() {
  window.localStorage.removeItem("redirectAction");
}

export function doActionWithReauth(
  user: firebase.User,
  action: RedirectActions
): boolean {
  let provider: firebase.auth.AuthProvider | undefined;
  if (
    user.providerData.some(provider => provider?.providerId === "twitter.com")
  ) {
    provider = new firebase.auth.TwitterAuthProvider();
  }
  if (
    user.providerData.some(provider => provider?.providerId === "google.com")
  ) {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  if (!provider) return false;

  saveAction(action);
  user.reauthenticateWithRedirect(provider);
  return true;
}

export async function handleRedirect(user: firebase.User, dispatch: Dispatch) {
  const action = loadAction();
  if (!action) return;
  clearSavedAction();
  doAction(user, action, dispatch);
}

async function doAction(
  user: firebase.User,
  action: RedirectActions,
  dispatch: Dispatch
) {
  switch (action.type) {
    case "deleteAccount": {
      try {
        await user.delete();
        analytics.logEvent("withdraw");
      } catch (error) {
        Sentry.captureException(error);
        dispatch(deleteAccountFailedAction(error.toString()));
      }
      return;
    }
    case "updateEmail": {
      try {
        await user.updateEmail(action.email);
        analytics.logEvent("update_email");
        window.location.reload();
      } catch (error) {
        Sentry.captureException(error);
        dispatch(updateEmailFailedAction(action.email, error.toString()));
      }
      return;
    }
  }
}
