import { Box, BoxProps, Image, Text } from "@chakra-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../state";
import { getScoreView } from "./scoreMoveUtil";

type AffiliateBannerProps = BoxProps;

export const AffiliateBannerPC: React.FC<AffiliateBannerProps> = (
  props: AffiliateBannerProps
) => {
  const item = getItem();
  const hasSpace = useSelector<State, boolean>(
    (state) => getScoreView(state, state.gameState.turn).candidates.length <= 1
  );
  if (!hasSpace) return null;
  return (
    <Box {...props}>
      <Text
        color="gray.400"
        fontSize="sm"
        fontWeight="semibold"
        textTransform="uppercase"
      >
        Sponcered
      </Text>
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <Box
          display="inline-block"
          borderWidth="1px"
          rounded="md"
          overflow="hidden"
          boxShadow="lg"
          ml="2"
          mt="1"
          h="180px"
        >
          <Image h="180px" objectFit="contain" src={item.image} />
        </Box>
      </a>
    </Box>
  );
};

export const AffiliateBannerMobile: React.FC<AffiliateBannerProps> = (
  props: AffiliateBannerProps
) => {
  const item = getItem();
  return (
    <Box {...props}>
      <Text
        color="gray.400"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        h="18px"
      >
        sponcered
      </Text>
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <Box
          borderWidth="1px"
          rounded="md"
          overflow="hidden"
          boxShadow="lg"
          h="122px"
          w="85px"
        >
          <Image h="122px" objectFit="contain" src={item.image} />
        </Box>
      </a>
    </Box>
  );
};

export function useShowsAffiliateBanner(): boolean {
  return useSelector<State, boolean>(({ user }) => {
    return false;
    // if (user.type === "loading") return false;
    // const abTestPassed =
    //   user.type === "loggedIn" ? [0, 1].includes(getBucketNumber(user)) : true;
    // return (
    //   !(getUserDetail(user)?.userSubscription?.type === "active") &&
    //   abTestPassed
    // );
  });
}

function getItem(): { url: string; image: string } {
  return items[new XorShift().nextInt(items.length)];
}

class XorShift {
  x: number;
  y: number;
  z: number;
  w: number;
  constructor(seed: number = Math.floor(Date.now() / 1000 / 60)) {
    this.x = 123456789;
    this.y = 362436069;
    this.z = 521288629;
    this.w = seed;
  }

  next() {
    let t;

    t = this.x ^ (this.x << 11);
    this.x = this.y;
    this.y = this.z;
    this.z = this.w;
    return (this.w = this.w ^ (this.w >>> 19) ^ (t ^ (t >>> 8)));
  }

  nextInt(max: number) {
    const r = Math.abs(this.next());
    return r % max;
  }
}

const items = [
  {
    url:
      "https://www.amazon.co.jp/%E8%A7%92%E6%8F%9B%E3%82%8F%E3%82%8A%E3%81%AE%E6%89%8B%E7%AD%8B-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E7%9C%9F%E7%94%B0%E5%9C%AD%E4%B8%80/dp/483997103X/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li2&tag=kento01e-22&linkId=8794664b7b297ecd7e8159d441089026&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=483997103X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%AF%BE%E6%8C%AF%E3%82%8A%E9%A3%9B%E8%BB%8A%E3%81%AE%E5%A4%A7%E9%9D%A9%E5%91%BD-%E3%82%A8%E3%83%AB%E3%83%A2%E5%9B%B2%E3%81%84%E6%80%A5%E6%88%A6-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E6%9D%91%E7%94%B0%E9%A1%95%E5%BC%98/dp/4839969124/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=99026bd565bfdc88be8c1cc29d89f931&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839969124&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E3%83%BB%E5%9F%BA%E6%9C%AC%E6%88%A6%E6%B3%95%E3%81%BE%E3%82%8B%E3%82%8F%E3%81%8B%E3%82%8A%E4%BA%8B%E5%85%B8-%E5%B1%85%E9%A3%9B%E8%BB%8A%E7%B7%A8-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E4%BD%90%E8%97%A4-%E6%85%8E%E4%B8%80/dp/4839962170/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=c18d87698dd39e12ff37b484729dc4bb&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839962170&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E9%AB%98%E6%A9%8B%E9%81%93%E9%9B%84%E3%81%AE%E5%B0%86%E6%A3%8B%E9%81%93%E5%A0%B4-%E5%88%9D%E6%AE%B5%E7%AA%81%E7%A0%B4%E3%81%AE%E3%82%B3%E3%83%8450-%E3%82%B3%E3%83%84%E3%81%8C%E3%82%8F%E3%81%8B%E3%82%8B%E6%9C%AC-%E9%AB%98%E6%A9%8B-%E9%81%93%E9%9B%84/dp/4780420938/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=b28146566967e5247d2d67385cee33c1&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4780420938&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B-%E9%A7%92%E5%BE%97%E3%81%AE%E6%89%8B%E7%AD%8B-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%AB-%E9%95%B7%E6%B2%BC%E6%B4%8B/dp/483997084X/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=305541ec2c6661786bb9899e8e7349a2&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=483997084X&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E6%88%A6%E6%B3%95%E4%BA%8B%E5%85%B8100-%E7%8E%8B%E9%81%93-%E7%8F%8D%E6%88%A6%E6%B3%95-%E5%AE%8C%E5%85%A8%E7%B6%B2%E7%BE%85-%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8CSpecial/dp/4839970904/ref=as_li_ss_il?_encoding=UTF8&psc=1&refRID=2GYR6DEYZRV4NKHV6GS3&linkCode=li3&tag=kento01e-22&linkId=13770fe6d7492d8b0989972d994e0387&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839970904&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%AF%84%E3%81%9B%E3%81%AE%E6%89%8B%E7%AD%8B200-%E6%9C%80%E5%BC%B7%E5%B0%86%E6%A3%8B%E3%83%AC%E3%82%AF%E3%83%81%E3%83%A3%E3%83%BC%E3%83%96%E3%83%83%E3%82%AF%E3%82%B9-%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7/dp/4861370272/ref=as_li_ss_il?_encoding=UTF8&psc=1&refRID=2GYR6DEYZRV4NKHV6GS3&linkCode=li3&tag=kento01e-22&linkId=002e7bc4bd0ad902457b755f14113f8f&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370272&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%9B%9B%E9%96%93%E9%A3%9B%E8%BB%8A%E3%82%92%E6%8C%87%E3%81%97%E3%81%93%E3%81%AA%E3%81%99%E6%9C%AC%E3%80%881%E3%80%89-%E6%9C%80%E5%BC%B7%E5%B0%86%E6%A3%8B%E5%A1%BE-%E8%97%A4%E4%BA%95-%E7%8C%9B/dp/4309721869/ref=as_li_ss_il?_encoding=UTF8&psc=1&refRID=2GYR6DEYZRV4NKHV6GS3&linkCode=li3&tag=kento01e-22&linkId=b2456a3297959651108654abe51669d9&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4309721869&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E8%A7%92%E4%BA%A4%E6%8F%9B%E5%9B%9B%E9%96%93%E9%A3%9B%E8%BB%8A%E3%81%AE%E6%96%B0%E5%B8%B8%E8%AD%98-%E6%9C%80%E5%BC%B7%E2%96%B33%E4%B8%89%E8%A7%92%E5%9E%8B-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E5%8F%A4%E6%A3%AE%E6%82%A0%E5%A4%AA/dp/4839969981/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=80bc0f9dd0a46d55f2b69308effdc7b3&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839969981&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E3%80%8C%E6%AC%A1%E3%81%AE%E4%B8%80%E6%89%8B%E3%80%8D%E3%81%A7%E8%A6%9A%E3%81%88%E3%82%8B%E5%B0%86%E6%A3%8B%E5%9F%BA%E6%9C%AC%E6%89%8B%E7%AD%8B%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3432-%E5%B0%86%E6%A3%8B%E9%80%A3%E7%9B%9F%E6%96%87%E5%BA%AB-%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C/dp/4839960070/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=ab7944cc1b584c74293ba9e585e453fb&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839960070&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E3%80%8C%E6%AC%A1%E3%81%AE%E4%B8%80%E6%89%8B%E3%80%8D%E3%81%A7%E8%A6%9A%E3%81%88%E3%82%8B-%E9%A7%92%E8%90%BD%E3%81%A1%E5%AE%9A%E8%B7%A1%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3404-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%AB-%E6%89%80%E5%8F%B8%E5%92%8C%E6%99%B4/dp/4839968101/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=5ae43da7d23c5e240434503022ea2ff4&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839968101&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E5%A6%99%E6%89%8B%E3%81%AB%E4%BF%97%E6%89%8B%E3%80%81%E9%A7%92%E4%BD%99%E3%82%8A%E3%82%82%E3%81%82%E3%82%8A-%E5%AE%9F%E6%88%A6%E8%A9%B0%E3%82%81%E7%AD%8B%E4%BA%8B%E5%85%B8-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%AB-%E6%9C%AC%E9%96%93%E5%8D%9A/dp/4839967911/ref=as_li_ss_il?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=kento01e-22&linkId=44c73282ff6515a28660584659733133&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839967911&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
  {
    url:
      "https://www.amazon.co.jp/%E6%96%B0%E6%89%8B%E5%B9%B4%E9%91%912020%E5%B9%B4%E7%89%88%EF%BC%88%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C2020%E5%B9%B46%E6%9C%88%E5%8F%B7%E4%BB%98%E9%8C%B2%EF%BC%89-%E3%80%8C%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C%E3%80%8D%E7%B7%A8%E9%9B%86%E9%83%A8-ebook/dp/B087JT3SYS/ref=as_li_ss_il?_encoding=UTF8&psc=1&refRID=2GYR6DEYZRV4NKHV6GS3&linkCode=li3&tag=kento01e-22&linkId=0de4bf75ed7d6cfcb5ebcac500d06b6a&language=ja_JP",
    image:
      "//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B087JT3SYS&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=kento01e-22&language=ja_JP",
  },
];
