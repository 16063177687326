import { Box, BoxProps, Button, useTheme } from "@chakra-ui/core";
import { isEqual, range } from "lodash-es";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { State } from "../../state";
import { Dispatch } from "../../store";
import { useScoreChartState } from "./scoreChartUtil";

export const ScoreChart: React.FC<BoxProps> = (props: BoxProps) => {
  const {
    turn,
    canUpgrade,
    isPro,
    scores,
    onClickTurn,
    logisticChart
  } = useScoreChartState();
  const dispatch = useDispatch<Dispatch>();
  const isBranch = useSelector<State, boolean>(
    state => !!state.gameState.branch
  );

  return (
    <Box
      bg="gray.700"
      position="relative"
      rounded="lg"
      shadow="md"
      pr="20px"
      pt="16px"
      {...props}
    >
      <style>
        .referenceLines{" "}
        {`{
          display:none;
        }`}
        {`.referenceLines.turn${turn} { display:inline }`}
      </style>
      <ChartBody
        scores={scores}
        canUpgrade={canUpgrade}
        isPro={isPro}
        onClickTurn={onClickTurn}
        logisticChart={logisticChart}
      />
      {isBranch ? (
        <Button
          position="absolute"
          size="xs"
          variant="outline"
          variantColor="teal"
          backgroundColor="white"
          top={{ base: "0.3rem", md: "0.5rem" }}
          right={{ base: "0.3rem", md: "0.5rem" }}
          onClick={() => dispatch({ type: "disableBranch" })}
        >
          本筋に戻る
        </Button>
      ) : null}
    </Box>
  );
};

type ChartBodyProps = {
  scores: { chartValue: number; turn: number }[];
  canUpgrade: Array<boolean>;
  isPro: Array<boolean>;
  logisticChart: boolean;
  onClickTurn: (turn: number) => void;
};
const ChartBody: React.FC<ChartBodyProps> = React.memo(
  ({ scores, canUpgrade, isPro, onClickTurn, logisticChart }) => {
    const fillColor = useTheme().colors.gray[200];
    const gridColor = useTheme().colors.gray[600];
    const fillColorPro = useTheme().colors.yellow[100];
    const fillColorHighPerf = useTheme().colors.gray[100];
    const fillColorLowPerf = useTheme().colors.gray[400];
    const turns = scores.length;
    const maxX = Math.max(30, Math.ceil(turns / 30) * 30);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={scores}
          onClick={e => {
            if (e && typeof e.activeLabel === "number")
              onClickTurn(e.activeLabel);
          }}
          {...(logisticChart
            ? { style: { marginLeft: "12px" } }
            : { style: { marginLeft: "-12px" } })}
        >
          <CartesianGrid stroke={gridColor} />
          <ReferenceLine y={0} />
          {range(maxX + 1).map(idx => (
            <ReferenceLine
              x={idx}
              key={idx}
              className={`referenceLines turn${idx}`}
            />
          ))}
          <XAxis
            dataKey="turn"
            type="number"
            domain={[0, maxX]}
            tickCount={4}
            axisLine={false}
            tick={{ fontSize: "0.75rem", fill: fillColor }}
          />
          {logisticChart ? (
            <YAxis
              dataKey="chartValue"
              type="number"
              domain={[-1, 1]}
              tick={{ fontSize: "0.75rem", fill: fillColor }}
              ticks={[-1 / 2, 1 / 2]}
              hide={true}
              allowDataOverflow={true}
              onClick={() => onClickTurn(0)}
            />
          ) : (
            <YAxis
              dataKey="chartValue"
              type="number"
              domain={[-2000, 2000]}
              tick={{ fontSize: "0.75rem", fill: fillColor }}
              allowDataOverflow={true}
              onClick={() => onClickTurn(0)}
            />
          )}
          <Bar dataKey="chartValue" isAnimationActive={false} fill={fillColor}>
            {canUpgrade.map((flg, idx) => {
              const fillColor = isPro[idx]
                ? fillColorPro
                : flg
                ? fillColorLowPerf
                : fillColorHighPerf;
              return <Cell key={idx} fill={fillColor} />;
            })}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
  (l, r) =>
    isEqual(l.scores, r.scores) &&
    isEqual(l.canUpgrade, r.canUpgrade) &&
    isEqual(l.isPro, r.isPro)
);
