import Axios from "axios";
import { version } from "../package.json";
import { loadVersion, saveVersion } from "./localStorage";

export async function isUpdated(): Promise<boolean> {
  try {
    const serverVersion: string = (await Axios.get("/version.txt")).data;
    return version !== serverVersion;
  } catch {
    return false;
  }
}

export function isUpdateFinished(): string | null {
  try {
    const savedVersion = loadVersion();
    saveVersion(version);
    if (savedVersion === null) {
      return null;
    }
    if (savedVersion !== version) {
      return version;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}
