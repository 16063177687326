import { Map } from "immutable";
import { EvalResultMulti } from "../evalMulti";
import { getRealPriority } from "../perfMulti";

export type ScoreDatabaseMulti = Map<string, EvalResultMulti[]>;

export function setScore(
  db: ScoreDatabaseMulti,
  score: EvalResultMulti
): ScoreDatabaseMulti {
  const crr = (db.get(score.sfen) || []).filter(
    e => e.perf !== score.perf || e.reqMultiPv !== score.reqMultiPv
  );
  return db.set(score.sfen, [...crr, score]);
}

export function getScore(
  db: ScoreDatabaseMulti,
  sfen: string,
  minimumMultiPv: number
): EvalResultMulti | null {
  const scores = db
    .get(sfen)
    ?.filter(score => score.reqMultiPv >= minimumMultiPv);
  if (scores === undefined || scores.length === 0) return null;
  return scores.sort((a, b) => {
    const c = getRealPriority(b) - getRealPriority(a);
    if (c === 0) return b.reqMultiPv - a.reqMultiPv;
    return c;
  })[0];
}

export function removeScore(
  db: ScoreDatabaseMulti,
  sfen: string,
  reqMultiPv: number
): ScoreDatabaseMulti {
  const crr = (db.get(sfen) || []).filter(e => e.reqMultiPv !== reqMultiPv);
  return db.set(sfen, crr);
}

export function extractAllCompletedSfen(db: ScoreDatabaseMulti): string[] {
  const sfens = db
    .toArray()
    .filter(([_, scores]) => scores.every(s => s.type === "completed"))
    .map(([sfen, _]) => sfen);
  return [...new Set(sfens)];
}
