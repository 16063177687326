import { Badge, Box, BoxProps, Button, Heading, Icon, IconButton, Stack, Text } from "@chakra-ui/core";
import { chunk } from "lodash-es";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { reloadKifuApi } from "../../actions/kifuApi";
import { analytics } from "../../firebase";
import { handleRedirect } from "../../redirectHandler";
import { State } from "../../state";
import { GameListEntry, KifuApiEntry } from "../../state/kifuApi";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { Loading } from "../main/Loading";

export const KifuList: React.FC<BoxProps> = props => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, State["user"]>(state => state.user);

  useEffect(() => {
    if (user.type !== "loggedIn") return;
    handleRedirect(user.firebaseUser, dispatch);
  }, [user, dispatch]);

  useEffect(() => {
    analytics.logEvent("page_view_kifulist");
    window.document.title = "KENTO | 棋譜";
  }, []);

  useEffect(() => {
    if (user.type === "loggedOut") {
      history.push("/");
    }
  }, [user, history]);

  const isContentsLoaded = user.type !== "loading";

  return (
    <Container>
      <Box display="flex" alignItems="flex-end">
        <Heading w="100%" onClick={() => history.push("/")}>
          <span style={{ cursor: "pointer", userSelect: "none" }}>☗ KENTO</span>
        </Heading>
        <IconButton
          aria-label="close"
          icon="close"
          variant="ghost"
          onClick={() => history.push("/")}
        />
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box
        opacity={isContentsLoaded ? 1 : 0}
        transition="opacity .5s"
        fontSize="sm"
        mt="2"
        mx="2"
        {...props}
      >
        {user.type === "loggedIn" || user.type === "loggedInCache" ? (
          <MainContent />
        ) : null}
      </Box>
    </Container>
  );
};

export const MainContent: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const apis = useSelector<State, KifuApiEntry[]>(state => state.kifuApi);
  const entries = apis.flatMap(api =>
    api.gameList.map(entry => ({ ...entry, apiName: api.apiName }))
  );
  entries.sort((a, b) => b.displayTimestamp - a.displayTimestamp);
  const pageGameList = chunk(entries, 20);

  const reloadApi = () => dispatch(reloadKifuApi());
  const isLoading = apis.some(api => api.type === "loading");

  const location = useLocation();

  if (apis.length === 0) {
    return <EmptyNotification />;
  }

  let page = Number.parseInt(
    queryString.parse(location.search).page?.toString() || ""
  );
  if (isNaN(page)) page = 0;
  page = Math.max(0, Math.min(pageGameList.length - 1, page));

  const gameList = pageGameList[page] || [];
  return (
    <Box mb="16">
      <Box w="100%" display="flex" justifyContent="flex-end">
        <Button
          size="sm"
          onClick={reloadApi}
          isLoading={isLoading}
          variantColor="teal"
        >
          更新
        </Button>
      </Box>
      <Box>
        {gameList.map((entry, idx) => (
          <GameEntry key={idx} gameEntry={entry} />
        ))}
      </Box>
      <Nav page={page} maxPage={pageGameList.length - 1} />
    </Box>
  );
};

export const Nav: React.FC<{ page: number; maxPage: number }> = ({
  page,
  maxPage
}) => {
  return (
    <Box display="flex" justifyContent="center">
      {page > 0 ? (
        <Link to={`/games?page=${page - 1}`}>
          <IconButton
            variant="outline"
            aria-label="prevPage"
            icon="chevron-left"
            mx="2"
          />
        </Link>
      ) : (
        <IconButton
          variant="outline"
          aria-label="prevPage"
          icon="chevron-left"
          mx="2"
          isDisabled={true}
        />
      )}
      {page < maxPage ? (
        <Link to={`/games?page=${page + 1}`}>
          <IconButton
            variant="outline"
            aria-label="nextPage"
            icon="chevron-right"
            mx="2"
          />
        </Link>
      ) : (
        <IconButton
          variant="outline"
          aria-label="nextPage"
          icon="chevron-right"
          mx="2"
          isDisabled={true}
        />
      )}
    </Box>
  );
};

export const GameEntry: React.FC<{
  gameEntry: GameListEntry & { apiName: string | undefined };
}> = ({ gameEntry }) => {
  const dispatch = useDispatch<Dispatch>();
  return (
    <Link
      to={`/?kifu=${gameEntry.kifuUrl}`}
      onClick={e => dispatch({ type: "resetGameInitSequence" })}
    >
      <Box p="2" my="2" shadow="xs" borderWidth="1px" rounded="md">
        <Text fontSize="xs" color="gray.400" fontWeight="semibold">
          {moment.unix(gameEntry.displayTimestamp).format("lll")}
        </Text>
        <Heading fontSize="md" mt="1" mb="2">
          {gameEntry.displayName}
        </Heading>
        <Stack isInline>
          {gameEntry.tag
            .sort((a, b) => b.length - a.length)
            .map((tag, idx) => (
              <Badge
                variant={
                  ReservedTagColors[tag.toLowerCase()] ? "subtle" : "outline"
                }
                variantColor={ReservedTagColors[tag.toLowerCase()] || "gray"}
                key={idx}
              >
                {tag}
              </Badge>
            ))}
        </Stack>
      </Box>
    </Link>
  );
};

const EmptyNotification: React.FC = () => {
  return (
    <Box textAlign="center">
      <Heading size="sm" mb="3">
        <Icon name="info" mb="3px" mr="1" />
        棋譜ソースが登録されていません
      </Heading>
      <Box display="flex" justifyContent="center">
        <Link to="/setting">
          <Text color="teal.500">設定</Text>
        </Link>
        <Text>で棋譜ソースを追加してください。</Text>
      </Box>
    </Box>
  );
};

const ReservedTagColors: { [key: string]: string } = {
  勝ち: "green",
  win: "green",
  負け: "red",
  lose: "red"
};
