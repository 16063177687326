import {
  Box,
  BoxProps,
  CSSReset,
  Divider,
  Heading,
  Text,
  ThemeProvider
} from "@chakra-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { analytics } from "../../firebase";

export const Specified: React.FC<BoxProps> = props => {
  const history = useHistory();

  useEffect(() => {
    analytics.logEvent("page_view_specified");
    window.document.title = "KENTO";
  }, []);

  return (
    <ThemeProvider>
      <CSSReset />
      <Box
        my={{ base: 2, md: 4 }}
        mx="auto"
        px={{ base: 1, md: 8 }}
        maxWidth="1024px"
        color="gray.700"
      >
        <Box display="flex" alignItems="flex-end">
          <Heading w="100%" onClick={() => history.push("/")}>
            <span style={{ cursor: "pointer", userSelect: "none" }}>
              ☗ KENTO
            </span>
          </Heading>
        </Box>
        <Box fontSize="sm" mt="8" mx="2" {...props}>
          <Heading size="md" mt="4">
            特定商取引法に基づく表示
          </Heading>
          <Divider />
          <Heading size="sm" my="2">
            支払時期
          </Heading>
          <Text my="2">有料会員登録日より一ヶ月毎に自動決済。</Text>
          <Heading size="sm" my="2">
            返品に関する事項
          </Heading>
          <Text my="2">返品不可。</Text>
          <Heading size="sm" my="2">
            申込みの有効期限
          </Heading>
          <Text my="2">
            最新の決済日より一ヶ月間、または有料会員の退会日まで。
          </Text>
          <Heading size="sm" my="2">
            デジタルコンテンツの動作環境
          </Heading>
          <Text my="2">最新版 Google Chrome での正常動作を目指す。</Text>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
