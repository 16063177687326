export type LoginModal = {
  isModalOpen: boolean;
  showNoLoginButton?: boolean;
  message?: string;
  isCancellable?: boolean;
};

export function getInitialLoginModalState() {
  // return {
  //   isModalOpen: true,
  //   showNoLoginButton: false,
  //   message: "棋譜の読み込み・一括評価はログインしてご利用ください。"
  // };
  return { isModalOpen: false };
}
