import React, { useState, useEffect } from "react";
import { LoggedInUser } from "../../state/user";
import { useHistory } from "react-router-dom";
import {
  useToast,
  Heading,
  Divider,
  Box,
  Text,
  IconButton,
  Button,
} from "@chakra-ui/core";
import moment from "moment-timezone";
import { createSetupSession } from "../../payment";
import { redirectToCheckout } from "../../stripe";
import * as Sentry from "@sentry/browser";
import { QuantityModal } from "./QuantityModal";

export type SupporterProps = {
  user: LoggedInUser;
};
export const Supporter: React.FC<SupporterProps> = ({ user }) => {
  const history = useHistory<History>();
  const toast = useToast();
  const { userSubscription } = user;
  const supporterStatus =
    userSubscription.type === "active"
      ? `有効 (最新の支払日: ${moment
          .unix(userSubscription.lastPaymentAt)
          .format("LL")})`
      : "無効";
  const quantityText =
    userSubscription.type === "active"
      ? `${userSubscription.quantity} 口 - 月額 ${
          userSubscription.quantity * 500
        } 円`
      : "-";
  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState<boolean>(
    false
  );
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  useEffect(() => {
    if (
      new URLSearchParams(window.location.search).get("action") ===
      "updateSubscriptionSuccess"
    ) {
      toast({
        title: "支払い方法更新",
        description:
          "支払い方法を更新しました。画面への反映に数分かかる場合があります。",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.replace("/account");
    }

    if (
      new URLSearchParams(window.location.search).get("action") ===
      "retryCountExceeded"
    ) {
      toast({
        title: "登録ありがとうございます。",
        description:
          "KENTO Pro に登録頂きありがとうございます。アカウント画面への反映には 10 分程度時間がかかる場合があります。",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.replace("/account");
    }
  }, [history, toast]);

  const startSetupSession = async () => {
    setIsRedirecting(true);
    const sessionId = await createSetupSession(user);
    if (!sessionId) {
      toast({
        title: "エラー",
        description:
          "セッションの生成に失敗しました。時間をあけて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const error = await redirectToCheckout(sessionId);
    setIsRedirecting(false);
    toast({
      title: "エラー",
      description:
        "リダイレクトに失敗しました。時間を開けて再度お試しください。",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    Sentry.captureException(error);
  };

  return (
    <>
      {userSubscription.type === "active" ? (
        <QuantityModal
          user={user}
          isOpen={isQuantityModalOpen}
          onClose={() => setIsQuantityModalOpen(false)}
          defaultQuantity={userSubscription.quantity}
        />
      ) : null}
      <Heading size="md" mt="8">
        KENTO Pro プラン
      </Heading>
      <Divider />
      <Box pl="4">
        <Heading size="xs" mb="2">
          状態
        </Heading>
        <Box>
          <Text mb="6px">{supporterStatus}</Text>
        </Box>
        {userSubscription.type === "active" ? (
          <>
            <Heading size="xs" mb="2" mt="4">
              口数
            </Heading>
            <Box display="flex" alignItems="flex-end">
              <Text mb="6px">{quantityText}</Text>
              <IconButton
                aria-label="updateQuantity"
                icon="edit"
                size="sm"
                variant="ghost"
                onClick={() => setIsQuantityModalOpen(true)}
              />
            </Box>
            <Heading size="xs" mb="2" mt="4">
              カード情報
            </Heading>
            <Box display="flex" alignItems="flex-end">
              {userSubscription.card ? (
                <Text mb="6px">
                  {userSubscription.card.brand} / 下4桁{" "}
                  {userSubscription.card.last4}
                </Text>
              ) : (
                <Text mb="6px">未登録</Text>
              )}
              <IconButton
                aria-label="updatePaymentMethod"
                icon="edit"
                size="sm"
                variant="ghost"
                onClick={startSetupSession}
                isLoading={isRedirecting}
              />
            </Box>
          </>
        ) : (
          <Button
            size="xs"
            variantColor="teal"
            onClick={() => history.push("/supporter")}
          >
            登録する
          </Button>
        )}
      </Box>
    </>
  );
};
