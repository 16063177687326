import * as Sentry from "@sentry/browser";
import Axios from "axios";
import { Move } from "shogi-ts";
import { PerfTypeMulti } from "./perfMulti";
import { User } from "./state/user";
import { chunk } from "./util";

export async function loadCachesMulti(
  sfenPositions: string[],
  minimumMultiPv: number
): Promise<CompletedEvalResultMulti[]> {
  try {
    sfenPositions = [...new Set(sfenPositions)];
    if (sfenPositions.length === 0) return [];

    const payload = JSON.stringify({
      sfenPositions,
      multiPv: minimumMultiPv,
      // engine: Engine,
      // evalFn: EvalFn,
    });
    const response = (
      await Axios.post(
        `${process.env.REACT_APP_ENDPOINT_SERVERLESS}/batchGetEvalCache`,
        payload
      )
    ).data as { result: CompletedEvalResultMulti[] };

    return response.result;
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}

async function getIdToken(user: User | null): Promise<string | undefined> {
  if (user?.type !== "loggedIn") return undefined;
  return await user.firebaseUser.getIdToken();
}

export async function evalGame(
  user: User | null,
  sfenPositions: string[],
  perf: PerfTypeMulti,
  multiPv: number,
  evalChunkSize: number,
  onReceiveTempResult: (result: TempEvalResultMulti) => void,
  onReceiveResult: (result: CompletedEvalResultMulti) => void,
  onDailyLimitExceeded: () => void,
  onFailed: (message: string) => void,
  noCache: boolean,
  shadowBanned: boolean
) {
  const idToken = await getIdToken(user);
  sfenPositions = [...new Set(sfenPositions)];
  const ws = await initializeWebSocketConnection(
    process.env.REACT_APP_ENDPOINT_WS!
  );
  let action = "evalSeqMulti3008";
  if (idToken) {
    if (shadowBanned) {
      action = "evalSeqMulti3000WithAuth";
    } else {
      action = "evalLowPerf";
    }
  }
  for (const sfenChunk of chunk(sfenPositions, evalChunkSize)) {
    ws.send(
      JSON.stringify({
        action,
        perf,
        multiPv,
        sfenPositions: sfenChunk,
        noCache,
        idToken,
        // engine: Engine,
        // evalFn: EvalFn,
      })
    );
  }
  let isErrorMessageSent = false;
  ws.onmessage = (ev: MessageEvent) => {
    const evalResult = JSON.parse(ev.data) as
      | EvalResultMulti
      | { type: undefined };
    if (evalResult.type === "temp") {
      onReceiveTempResult(evalResult);
    } else if (evalResult.type === "completed") {
      onReceiveResult(evalResult);
    } else if (evalResult.type === "limit_exceeded" && !isErrorMessageSent) {
      isErrorMessageSent = true;
      onDailyLimitExceeded();
    } else if (!isErrorMessageSent) {
      isErrorMessageSent = true;
      onFailed("処理に失敗しました");
    }
  };
}

export async function evalPositionMulti(
  user: User | null,
  sfenPosition: string,
  perf: PerfTypeMulti,
  multiPv: number,
  onReceiveTempResult: (result: TempEvalResultMulti) => void,
  onDailyLimitExceeded: () => void,
  onFailed: (message: string) => void,
  noCache: boolean,
  shadowBanned: boolean
): Promise<CompletedEvalResultMulti> {
  const idToken = await getIdToken(user);
  const ws = await initializeWebSocketConnection(
    process.env.REACT_APP_ENDPOINT_WS!
  );
  let action = "evalSeqMulti3008";
  if (idToken) {
    if (shadowBanned) {
      action = "evalSeqMulti3000WithAuth";
    } else {
      action = "evalHighPerf";
    }
  }
  ws.send(
    JSON.stringify({
      idToken,
      action,
      perf,
      multiPv,
      sfenPositions: [sfenPosition],
      noCache,
      // engine: Engine,
      // evalFn: EvalFn,
    })
  );
  const result = await new Promise<CompletedEvalResultMulti>((resolve) => {
    ws.onmessage = (ev: MessageEvent) => {
      const evalResult = JSON.parse(ev.data) as
        | EvalResultMulti
        | { type: undefined };
      if (evalResult.type === "temp") {
        onReceiveTempResult(evalResult);
      } else if (evalResult.type === "completed") {
        resolve(evalResult);
      } else if (evalResult.type === "limit_exceeded") {
        onDailyLimitExceeded();
      } else {
        onFailed("処理に失敗しました");
      }
    };
  });
  ws.close();
  return result;
}

export type EvalResultMulti = TempEvalResultMulti | CompletedEvalResultMulti;

export type TempEvalResultMulti = {
  type: "temp";
  sfen: string;
  perf: PerfTypeMulti;
  reqMultiPv: number;
  seqId: number;
  score: TempScoreMulti;
};

export type CompletedEvalResultMulti = {
  type: "completed";
  sfen: string;
  perf: PerfTypeMulti;
  reqMultiPv: number;
  score: CompletedScoreMulti;
};

export type TempScoreMulti = {
  multiScores: ScoreEntryMulti[];
};

export type CompletedScoreMulti = TempScoreMulti & {
  bestScore: ScoreEntry & { bestMove?: Move };
};

export type ScoreEntry = {
  depth?: number;
  seldepth?: number;
  nodes?: number;
  pv?: Move[];
  scoreCp?: number;
  scoreMate?: number;
  hashfull?: number;
  nps?: number;
};

export type ScoreEntryMulti = ScoreEntry & { multiPv: number };

function initializeWebSocketConnection(url: string): Promise<WebSocket> {
  const ws = new WebSocket(url);
  return new Promise((resolve) => {
    ws.onopen = () => resolve(ws);
  });
}
