import { Box } from "@chakra-ui/core";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadKifUrl, loadSavedGame, startNarabeGame } from "../../actions/game";
import { analytics } from "../../firebase";
import { State } from "../../state";
import { GameInitSequence } from "../../state/gameInitSequence";
import { User } from "../../state/user";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { Logo } from "../Logo";
import { UserNotification } from "../UserNotification";
import { About } from "./About";
import { AddressBar, QueryParams } from "./AddressBar";
import {
  AffiliateBannerMobile,
  AffiliateBannerPC,
  useShowsAffiliateBanner,
} from "./AffiliateBanner";
import { AppMenuOrLoginButton } from "./AppMenuOrLoginButton";
import { Board } from "./Board";
import { ErrorModal } from "./ErrorModal";
import { GameInitializeSequence } from "./GameInitializeSequence";
import { KifuLoader } from "./KifuLoader";
import { Loading } from "./Loading";
import { LoginModal } from "./LoginModal";
import { MobileCandidates } from "./MobileCandidates";
import { Navigation } from "./Navigation";
import { ScoreChart } from "./ScoreChart";
import { ScoreLoader } from "./ScoreLoader";
import { ScoreMoveMulti } from "./ScoreMoveMulti";
import { ScoreMoveRowMulti } from "./ScoreMoveRowMulti";
import { WarsError } from "./WarsError";
import { AskCalcGameModal } from "./AskCalcGameModal";

export const App: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    initializeApp(dispatch);
    window.document.title = "KENTO";
  }, [dispatch]);

  const showsAffiliateBanner = useShowsAffiliateBanner();
  if (showsAffiliateBanner) {
    analytics.logEvent("show_affiliate");
  }

  const gameInitSequence = useSelector<State, State["gameInitSequence"]>(
    (state) => state.gameInitSequence
  );
  const loginStatus = useSelector<State, User["type"]>(
    (state) => state.user.type
  );

  const contentVisibleTypes: GameInitSequence["type"][] = [
    "askingCalcGameScores",
    "calcGameScoreStarted",
    "finished",
  ];
  const isContentsLoaded =
    loginStatus !== "loading" &&
    contentVisibleTypes.includes(gameInitSequence.type);
  return (
    <Container>
      <AddressBar />
      <UserNotification />
      <ScoreLoader />
      <GameInitializeSequence />
      <Box
        alignItems="flex-end"
        display={{
          base: isContentsLoaded ? "none" : "flex",
          md: "flex",
        }}
      >
        <Logo />
        <Box
          display={{
            base: "none",
            md: "block",
          }}
        >
          <AppMenuOrLoginButton loginStatus={loginStatus} />
        </Box>
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box opacity={isContentsLoaded ? 1 : 0} transition="opacity .5s">
        <Box
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          height="48px"
          mt="2"
        >
          <ScoreMoveRowMulti w="100%" mb="2" />
          <Box ml="auto">
            <AppMenuOrLoginButton loginStatus={loginStatus} />
          </Box>
        </Box>
        <MobileCandidates display={{ base: "block", md: "none" }} />
        <About />
        <WarsError />
        <ErrorModal />
        <LoginModal />
        <AskCalcGameModal />
        <KifuLoader />
        <Box
          display="flex"
          position="relative"
          mt={{ base: 0, md: 4 }}
          justifyContent={{ base: "flex-start", lg: "center" }}
        >
          <Box display={{ base: "none", lg: "block" }} w="280px" />
          <Box ml={{ base: 0, md: "5rem", lg: 0 }}>
            <Board mx="auto" />
            <Navigation mt={{ base: 2, md: 4 }} />
          </Box>
          <Box>
            <ScoreMoveMulti
              display={{ base: "none", md: "block" }}
              mx={{ base: 4, lg: 8 }}
              mt={4}
              w={{ base: "100%", lg: "320px" }}
              position="relative"
            />
            {showsAffiliateBanner ? (
              <AffiliateBannerPC
                display={{ base: "none", md: "block" }}
                mx={{ base: 4, lg: 8 }}
                mt="3"
              />
            ) : null}
          </Box>
        </Box>
        <Box
          display="flex"
          mt={{ base: 2, md: 4 }}
          justifyContent="space-between"
        >
          {showsAffiliateBanner ? (
            <AffiliateBannerMobile
              h="140px"
              w="85px"
              mx="auto"
              display={{ base: "block", md: "none" }}
            />
          ) : null}
          <ScoreChart
            h={{ base: "140px", md: "200px" }}
            w={
              showsAffiliateBanner
                ? { base: "calc(100% - 100px)", md: "100%" }
                : "100%"
            }
            display="flex"
          />
        </Box>
      </Box>
    </Container>
  );
};

type GameInitialize =
  | { type: "loadKifUrl"; url: string; turn: number }
  | { type: "loadWarsUrl"; url: string; turn: number }
  | { type: "loadSavedGame"; url: string; turn: number }
  | { type: "emptyKif"; turn: number; moves: string[]; initpos?: string };

type Branch = {
  branchMoves?: string[];
  branchFrom?: number;
};

function initializeApp(dispatch: Dispatch) {
  const props = queryParamsToAppProps(
    queryString.parse(window.location.search)
  );
  const { branchFrom, branchMoves } = props;
  const branch =
    branchMoves !== undefined && branchFrom !== undefined
      ? { branchMoves, branchFrom }
      : undefined;

  if (props.type === "loadKifUrl") {
    dispatch(loadKifUrl(props.url, props.turn, branch));
    return;
  }
  if (props.type === "loadWarsUrl") {
    dispatch({ type: "warsErrorModal", dir: "open" });
    dispatch(startNarabeGame(props.turn, undefined, [], branch));
  }
  if (props.type === "loadSavedGame") {
    dispatch(loadSavedGame(props.url, props.turn, branch));
  }
  if (props.type === "emptyKif") {
    dispatch(startNarabeGame(props.turn, props.initpos, props.moves, branch));
  }
}

type AppInitialProps = GameInitialize & Branch;

function queryParamsToAppProps(params: QueryParams): AppInitialProps {
  const branchMoves = params.branch
    ? params.branch.split(".").flatMap((l) => l.split(","))
    : undefined;
  const branchFrom = params.branchFrom
    ? Number.parseInt(params.branchFrom, 10)
    : undefined;
  let turn = Number.parseInt(window.location.hash.slice(1), 10);
  if (Number.isNaN(turn)) turn = 9999;
  if (
    params.kifu &&
    (params.kifu.startsWith("https://kif-pona.heroz.jp/games/") ||
      params.kifu.startsWith("https://shogiwars.heroz.jp/games/"))
  ) {
    return {
      type: "loadWarsUrl",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom,
    };
  }
  if (
    params.kifu &&
    (params.kifu.endsWith(".kif") || params.kifu.endsWith(".csa"))
  ) {
    return {
      type: "loadKifUrl",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom,
    };
  }
  if (params.kifu && params.kifu.startsWith("saved")) {
    return {
      type: "loadSavedGame",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom,
    };
  }
  const moves = params.moves
    ? params.moves.split(".").flatMap((l) => l.split(","))
    : [];
  return {
    type: "emptyKif",
    turn,
    moves,
    branchMoves,
    branchFrom,
    initpos: params.initpos,
  };
}
