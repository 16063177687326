export function chunk<T>(input: T[], size: number): T[][] {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, [] as T[][]);
}

// ref: https://gist.github.com/mutewinter/b86fc06bae43fd3e13169bfb569b06b1
export function preventDoubleTapZoom() {
  const DELAY = 500;
  const MIN_ZOOM_TOUCH_DELTA = 10;
  let lastTapAt = 0;
  let lastClientX = 0;
  let lastClientY = 0;
  return (event: React.TouchEvent<any>) => {
    // Exit early if this involves more than one finger (e.g. pinch to zoom)
    if (event.touches.length > 1) {
      return;
    }

    const tapAt = new Date().getTime();
    const timeDiff = tapAt - lastTapAt;
    const { clientX, clientY } = event.touches[0];
    const xDiff = Math.abs(lastClientX - clientX);
    const yDiff = Math.abs(lastClientY - clientY);
    if (
      xDiff < MIN_ZOOM_TOUCH_DELTA &&
      yDiff < MIN_ZOOM_TOUCH_DELTA &&
      event.touches.length === 1 &&
      timeDiff < DELAY
    ) {
      event.preventDefault();
      // Trigger a fake click for the tap we just prevented
      (event.target as any).click();
    }
    lastClientX = clientX;
    lastClientY = clientY;
    lastTapAt = tapAt;
  };
}
