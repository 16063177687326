import {
  Box,
  Button,
  Divider,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteUserSubscription } from "../../payment";
import { LoggedInUser } from "../../state/user";

export type CancelSupporterPlan = {
  user: LoggedInUser;
};
export const CancelSupporterPlan: React.FC<CancelSupporterPlan> = ({
  user,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { firebaseUser } = user;
  const [isCancelButtonActive, setIsCancelButtonActive] = React.useState(false);
  const [emailToDelete, setEmailToDelete] = React.useState("");
  const handleEmailToDeleteChange = (value: string) => {
    setEmailToDelete(value);
    setIsCancelButtonActive(value === firebaseUser.email);
  };
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const cancelPlan = async () => {
    setIsDeleting(true);
    const isSuccess = await deleteUserSubscription(user);
    setIsDeleting(false);

    if (!isSuccess) {
      toast({
        title: "エラー",
        description:
          "処理に失敗しました。既に停止されている可能性があります。10 分程度時間をあけて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: "KENTO Pro プラン停止",
      description:
        "KENTO Pro プランを停止しました。アカウント画面への反映には 5 ~ 10 分かかります。",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    history.push("/");
  };
  return (
    <>
      <Heading size="md" mt="32" color="red.500">
        KENTO Pro プラン停止
      </Heading>
      <Divider />
      <Box pl="4">
        <Text color="red.500">この操作は取り消しできません。</Text>
        <Box width="320px" mt="4">
          <Input
            placeholder="登録メールアドレスを入力してください"
            value={emailToDelete}
            size="sm"
            onChange={(e: any) => handleEmailToDeleteChange(e.target.value)}
          />
        </Box>
        <Button
          mt="4"
          variantColor="red"
          size="sm"
          onClick={cancelPlan}
          isDisabled={!isCancelButtonActive}
          isLoading={isDeleting}
        >
          停止する
        </Button>
      </Box>
    </>
  );
};
