import {
  Array,
  Literal,
  Number,
  Partial,
  Record,
  String,
  Undefined,
  Dictionary,
  Boolean,
} from "runtypes";

export const CardRecord = Record({
  brand: String,
  last4: String,
});

export const ActiveUserSubscriptionRecord = Record({
  type: Literal("active"),
  lastPaymentAt: Number,
  quantity: Number,
  card: CardRecord.Or(Undefined),
});

export const NoSubscriptionRecord = Record({
  type: Literal("no_payment"),
  customerId: String.Or(Undefined),
});

export const UserSubscriptionRecord = ActiveUserSubscriptionRecord.Or(
  NoSubscriptionRecord
);

export const GameListEntryRecord = Record({
  tag: Array(String),
  kifuUrl: String,
  displayName: String,
  displayTimestamp: Number,
});
export const ValidEntryRecord = Record({
  type: Literal("valid"),
  url: String,
  apiName: String,
  gameList: Array(GameListEntryRecord),
});
export const InvalidEntryRecord = Record({
  type: Literal("invalid"),
  url: String,
  gameList: Array(GameListEntryRecord),
}).And(
  Partial({
    apiName: String,
    message: String,
  })
);
export const KifuApiEntryRecord = ValidEntryRecord.Or(InvalidEntryRecord);

export const NotificationRecord = Record({
  isNotificationRead: Dictionary(Boolean, "string"),
});

export const PartialUserDataRecord = Partial({
  multiPv: Number,
  kifuApi: Array(KifuApiEntryRecord),
  forceRedirectSupporterTimestamp: Number.Or(Undefined),
  displayMobileMultiPv: Number,
  logisticChart: Boolean,
  notification: NotificationRecord,
  sb: Boolean,
});

export const UserDataRecord = Record({
  multiPv: Number,
  kifuApi: Array(KifuApiEntryRecord),
  forceRedirectSupporterTimestamp: Number.Or(Undefined),
  displayMobileMultiPv: Number,
  logisticChart: Boolean,
  notification: NotificationRecord,
  sb: Boolean,
});
