import * as Sentry from "@sentry/browser";
import { clearSavedUser, loadUser, saveUser } from "../localStorage";
import { getUserSubscription } from "../payment";
import { UserData, UserSubscription } from "../sharedTypes";
import { ThunkAction } from "../store";
import { getUserData, saveUserData } from "../userData";
import { reloadKifuApi } from "./kifuApi";

export type UserActions =
  | UserLoginAction
  | UserLoginByCacheAction
  | UserLogoutAction
  | ReloadUserAction
  | UserLoginFailedAction
  | UpdateUserDataAction;

export type UserLoginAction = {
  type: "userLogin";
  user: firebase.User;
  userSubscription: UserSubscription;
  userData: UserData;
};

export type UserLoginByCacheAction = {
  type: "userLoginByCache";
  userSubscription: UserSubscription;
  userData: UserData;
};

export type UserLogoutAction = {
  type: "userLogout";
};

export type ReloadUserAction = {
  type: "reloadUser";
};

export type UserLoginFailedAction = {
  type: "userLoginFailed";
  reason: string;
};

export type UpdateUserDataAction = {
  type: "updateUserData";
  userData: UserData;
};

export function loadSavedUser(): ThunkAction {
  return (dispatch) => {
    const user = loadUser();
    if (!user) return;
    dispatch({
      type: "userLoginByCache",
      userSubscription: user.userSubscription,
      userData: user.userData,
    });
  };
}

export function setLoggedInUser(
  user: firebase.User,
  updatesKifuApi: boolean = true
): ThunkAction {
  return async (dispatch) => {
    const userSubscription = await getUserSubscription(user);
    if (!userSubscription) {
      Sentry.captureMessage("getUserSubscription failed");
      dispatch({
        type: "userLoginFailed",
        reason: "invalid user subscription data",
      });
      return;
    }
    const userData = completeUserData(
      await getUserData(user),
      userSubscription
    );
    saveUser({
      userData,
      userSubscription,
    });
    dispatch({
      type: "userLogin",
      user,
      userSubscription,
      userData,
    });
    if (updatesKifuApi) {
      dispatch(reloadKifuApi());
    }
  };
}

export function userLogout(): ThunkAction {
  return (dispatch) => {
    clearSavedUser();
    dispatch({ type: "userLogout" });
  };
}

export function updateUserData(userData: UserData): ThunkAction {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (user.type !== "loggedIn") return;
    dispatch({ type: "updateUserData", userData });
    saveUser({ ...user, userData });
    await saveUserData(user.firebaseUser, userData);
  };
}

export function updatePartialUserData(
  partialUserData: Partial<UserData>
): ThunkAction {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (user.type !== "loggedIn") return;
    const userData = { ...user.userData, ...partialUserData };
    dispatch({ type: "updateUserData", userData });
    saveUser({ ...user, userData });
    await saveUserData(user.firebaseUser, userData);
  };
}

function completeUserData(
  userData: Partial<UserData>,
  userSubscription: UserSubscription
): UserData {
  let multiPv = 1;
  if (userSubscription.type === "active") {
    multiPv = userData.multiPv || 3;
  }
  const kifuApi = userData.kifuApi || [];
  const forceRedirectSupporterTimestamp =
    userData.forceRedirectSupporterTimestamp || undefined;
  const displayMobileMultiPv = userData.displayMobileMultiPv || 3;
  const logisticChart = userData.logisticChart!!;
  const notification = userData.notification || { isNotificationRead: {} };
  const sb = userData.sb !== undefined ? userData.sb : false;
  return {
    multiPv,
    kifuApi,
    forceRedirectSupporterTimestamp,
    displayMobileMultiPv,
    logisticChart,
    notification,
    sb,
  };
}

export function updateUserDataOnActivation(): ThunkAction {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (user.type !== "loggedIn" || user.userSubscription.type !== "active")
      return;

    const { userData } = user;
    dispatch(
      updateUserData({ ...userData, multiPv: 3, displayMobileMultiPv: 3 })
    );
  };
}
