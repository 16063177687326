import { Box, CSSReset, ThemeProvider, BoxProps } from "@chakra-ui/core";
import React from "react";
import { FlashMessage } from "./FlashMessage";
import { Analytics } from "./Analytics";
import { UpdateNotification } from "./UpdateNotification";

export type ContainerProps = {
  children: React.ReactNode;
} & BoxProps;
export const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <ThemeProvider>
      <CSSReset />
      <Box
        py={{ base: 2, md: 4 }}
        mx="auto"
        px={{ base: 1, md: 8 }}
        maxWidth="1024px"
        color="gray.700"
        {...props}
      >
        <FlashMessage />
        <Analytics />
        <UpdateNotification />
        {children}
      </Box>
    </ThemeProvider>
  );
};
