import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/core";
import React, { useCallback, useState } from "react";
import CopyToClipBoard from "react-copy-to-clipboard";
import { FaTwitter } from "react-icons/fa";
import { FiArrowUpCircle, FiShare } from "react-icons/fi";
import { MdRotate90DegreesCcw } from "react-icons/md";
import { TiArrowShuffle } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { doPass } from "../../actions/moveInput";
import { State } from "../../state";
import { Dispatch } from "../../store";
import { getScoreView, ScoreView, useUpgradeScore } from "./scoreMoveUtil";
import { useShare } from "./share";

type NavigationProps = BoxProps;

export const Navigation: React.FC<NavigationProps> = (
  props: NavigationProps
) => {
  const gameState = useSelector<State, State["gameState"]>(
    state => state.gameState
  );

  const currentGame = gameState.branch?.branch || gameState.game;
  const isChecked = currentGame.isChecked[gameState.turn];
  const setting = useSelector<State, State["setting"]>(state => state.setting);
  const dispatch = useDispatch<Dispatch>();
  const { canUpgrade } = useSelector<State, ScoreView>(state =>
    getScoreView(state, state.gameState.turn)
  );
  const upgradeScore = useUpgradeScore();
  const onClickRotateButton = () => {
    dispatch({ type: "rotateBoard", rotate: !setting.rotateBoard });
  };

  const {
    isWebShareApiEnable,
    doWebShare,
    urlToShare,
    twitterLink
  } = useShare();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      {...props}
    >
      <Box
        position="absolute"
        left="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          aria-label="rotate"
          icon={MdRotate90DegreesCcw}
          fontSize="21px"
          size="sm"
          variant={setting.rotateBoard ? "solid" : "ghost"}
          variantColor={setting.rotateBoard ? "teal" : "gray"}
          onClick={onClickRotateButton}
          mr="1"
        />
        <IconButton
          aria-label="pass"
          icon={TiArrowShuffle}
          fontSize="21px"
          size="sm"
          variant="ghost"
          onClick={() => dispatch(doPass())}
          isDisabled={isChecked}
          mr="1"
        />
      </Box>
      <IconButton
        icon="arrow-back"
        aria-label="prev"
        onClick={() => dispatch({ type: "backward" })}
        size="sm"
        mr={1}
        variant="outline"
        style={{ touchAction: "none" }}
      />
      <Box
        fontSize="sm"
        fontWeight="semibold"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        width="50px"
      >
        #{gameState.turn}
      </Box>
      <IconButton
        icon="arrow-forward"
        aria-label="forward"
        onClick={() => dispatch({ type: "forward" })}
        size="sm"
        ml={1}
        variant="outline"
        style={{ touchAction: "none" }}
      />
      <Box
        position="absolute"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {canUpgrade ? (
          <IconButton
            aria-label="upgrade"
            fontSize="21px"
            icon={FiArrowUpCircle}
            size="sm"
            variant="ghost"
            mr="1"
            onClick={upgradeScore}
          />
        ) : null}
        {isWebShareApiEnable ? (
          <IconButton
            icon={FiShare}
            fontSize="21px"
            size="sm"
            aria-label="share"
            variant="ghost"
            mr="1"
            onClick={doWebShare}
          />
        ) : (
          <ShareButton urlToShare={urlToShare} twitterLink={twitterLink} />
        )}
      </Box>
    </Box>
  );
};

type ShareButtonProps = {
  urlToShare: string;
  twitterLink: string;
};
const ShareButton: React.FC<ShareButtonProps> = ({
  urlToShare,
  twitterLink
}) => {
  const [copied, setCopied] = useState(false);
  const onCopySuccess = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }, []);

  return (
    <Popover placement="bottom" {...{ usePortal: true }}>
      <PopoverTrigger>
        <IconButton
          icon={FiShare}
          fontSize="21px"
          size="sm"
          aria-label="share"
          variant="ghost"
          mr="1"
        />
      </PopoverTrigger>
      <PopoverContent zIndex={10}>
        <PopoverArrow />
        <PopoverBody>
          <Box display="flex" alignItems="center">
            <Input size="sm" defaultValue={urlToShare} mr="1" />
            <CopyToClipBoard text={urlToShare} onCopy={onCopySuccess}>
              <Button size="xs" variantColor="teal" className="copyToClipboard">
                {copied ? "COPIED" : "COPY"}
              </Button>
            </CopyToClipBoard>
          </Box>
          <Box display="flex" mt="2">
            <Box>
              <a href={twitterLink}>
                <IconButton
                  icon={FaTwitter}
                  fontSize="21px"
                  size="sm"
                  variant="ghost"
                  aria-label="twitter"
                />
              </a>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
