import { State } from "../state";
import { Action } from "../actions";

export function flashMessageQueueReducer(
  state: State["flashMessageQueue"],
  action: Action
): State["flashMessageQueue"] {
  switch (action.type) {
    case "flashMessageOpen": {
      const { title, messages, modalType, content } = action;
      return [...state, { title, messages, modalType, content }];
    }
    case "flashMessageClose": {
      return state.slice(1);
    }
    default:
      return state;
  }
}
