import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBranchScoreMulti,
  reloadScoreCaches
} from "../../actions/gameScore";
import { State } from "../../state";
import { GameInitSequence } from "../../state/gameInitSequence";
import { Dispatch } from "../../store";
import { getScoreView, ScoreView } from "./scoreMoveUtil";

export const ScoreLoader: React.FC = () => {
  const currentSfen = useSelector<State, string>(
    state => state.gameState.currentSfen
  );
  const gameInitSequence = useSelector<State, GameInitSequence>(
    state => state.gameInitSequence
  );
  const currentScore = useSelector<State, ScoreView>(state =>
    getScoreView(state, state.gameState.turn)
  );

  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    if (
      !currentScore.bestScore &&
      !currentScore.isCalculating &&
      gameInitSequence.type === "finished"
    ) {
      dispatch(loadBranchScoreMulti(currentSfen));
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(reloadScoreCaches());
    }, 120_000);
    return () => clearInterval(timer);
  }, [dispatch]);
  return <></>;
};
