import { Box, BoxProps, Text } from "@chakra-ui/core";
import React from "react";
import { Piece } from "./Board/Piece";

export const Loading: React.FC<BoxProps> = (props: BoxProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...props}>
      <style>
        {`
        @keyframes loading {
          0% { transform: rotate(0deg); }
          50% { transform: rotate(5deg); }
        }
      `}
      </style>
      <Piece
        piece={{ piece: "p", side: "b" }}
        bg="white"
        animation="loading 1s ease-in-out infinite"
        width="50px"
      />
      <Text
        textTransform="uppercase"
        fontWeight="semibold"
        fontSize="xl"
        color="gray.300"
      >
        loading...
      </Text>
    </Box>
  );
};
