import { getUserDetail, LoggedInUser, User } from "./state/user";

export function isSupporterTestingTarget(user: User): boolean {
  const userDetail = getUserDetail(user);
  if (!userDetail) return false;
  if (userDetail.userSubscription.type === "active") return false;
  return true;
  // const bucketNum = getBucketNumber(user);
  // return [0, 3].includes(bucketNum);
}

const Buckets = 20;
export function getBucketNumber(user: LoggedInUser): number {
  let bucketNumber = 1;
  for (const char of user.firebaseUser.uid) {
    bucketNumber *= 13;
    bucketNumber += char.charCodeAt(0) % 13;
    bucketNumber %= Buckets;
  }
  return bucketNumber;
}
