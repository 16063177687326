export type ErrorMessageAction = {
  type: "errorMessage";
  message: string | null;
};

export function emailAlreadyExistsErrorAction(
  email: string
): ErrorMessageAction {
  return {
    type: "errorMessage",
    message: `ログインに失敗しました。メールアドレス ${email} は既に別のログイン方法で登録されています。別のログイン方法をお試しください。`
  };
}
