import { isEqual } from "lodash-es";
import {
  createExtendedMoveFromSfen,
  doMove,
  ExtendedGame,
  ExtendedMove,
  Game,
  generateSfen,
  getInitialPosition,
  isChecked,
  Move,
  parseSfen
} from "shogi-ts";
import { Action } from "../actions";
import { Branch } from "../actions/game";
import { ScoreEntry } from "../evalMulti";
import { getScoreView } from "../pages/main/scoreMoveUtil";
import { State } from "../state";
import { GameState, KifuGame, NarabeGame } from "../state/game";

export function gameStateReducer(
  state: State,
  action: Action
): State["gameState"] {
  const gameState = commonReducer(state, action);
  switch (gameState.type) {
    case "kifu":
      return kifuGameReducer({ ...state, gameState }, action);
    case "narabe":
      return narabeGameReducer({ ...state, gameState }, action);
  }
}

function kifuGameReducer(state: State, action: Action): State["gameState"] {
  const { gameState } = state;
  switch (action.type) {
    case "inputMoveComplete": {
      const currentGame = gameState.branch
        ? gameState.branch.branch
        : gameState.game;
      const { turn } = gameState;
      if (isEqual(currentGame.moves[turn], action.move)) {
        return completeGameState({ ...gameState, turn: turn + 1 });
      }
      return completeGameState({
        ...gameState,
        branch: {
          branch: doMoves(currentGame, turn, [action.move]),
          branchFrom: gameState.branch ? gameState.branch.branchFrom : turn,
          pv: gameState.branch?.pv?.slice(0, turn + 1) || []
        },
        turn: turn + 1
      });
    }
    default:
      return gameState;
  }
}

function narabeGameReducer(state: State, action: Action): State["gameState"] {
  const { gameState } = state;
  switch (action.type) {
    case "inputMoveComplete": {
      // 末尾に入力した or 明示的に本筋指定
      if (
        gameState.branch === undefined &&
        (gameState.game.turns === gameState.turn + 1 ||
          action.applyingBranch === "main")
      ) {
        if (isEqual(gameState.game.moves[gameState.turn], action.move)) {
          return completeGameState({ ...gameState, turn: gameState.turn + 1 });
        }
        const game = doMoves(gameState.game, gameState.turn, [action.move]);
        return completeGameState({
          ...gameState,
          game,
          turn: gameState.turn + 1
        });
      }
      // 分岐
      const currentGame = gameState.branch
        ? gameState.branch.branch
        : gameState.game;
      const { turn } = gameState;
      if (isEqual(currentGame.moves[turn], action.move)) {
        return completeGameState({ ...gameState, turn: turn + 1 });
      }
      return completeGameState({
        ...gameState,
        branch: {
          branch: doMoves(currentGame, turn, [action.move]),
          branchFrom: gameState.branch ? gameState.branch.branchFrom : turn,
          pv: gameState.branch?.pv?.slice(0, turn + 1) || []
        },
        turn: turn + 1
      });
    }
    default:
      return gameState;
  }
}

function commonReducer(state: State, action: Action): State["gameState"] {
  const { gameState } = state;
  switch (action.type) {
    case "loadGameSuccess": {
      const { branch, turns } = makeBranch(action.game, action.branch);
      return completeGameState({
        type: "kifu",
        game: action.game,
        url: action.url,
        turn: Math.min(turns - 1, Math.max(0, action.turn)),
        branch
      });
    }
    case "narabeGame": {
      const position =
        (action.initpos ? parseSfen(action.initpos) : null) ||
        getInitialPosition();
      const sfen = generateSfen(position);
      const emptyGame: Game = {
        positions: [position],
        sfenPositions: [sfen],
        isChecked: [false],
        moves: [],
        turns: 1
      };
      const game = doMovesBySfen(emptyGame, 0, action.sfenMoves);
      const { branch, turns } = makeBranch(game, action.branch);
      return completeGameState({
        type: "narabe",
        game,
        turn: Math.min(turns - 1, Math.max(0, action.turn)),
        branch
      });
    }
    case "narabeGameParsed": {
      const { branch } = makeBranch(action.game, action.branch);
      return completeGameState({
        type: "narabe",
        game: action.game,
        turn: action.turn,
        branch
      });
    }
    case "enableBranch": {
      const { game, turn } = gameState;
      const branch = doMovesBySfen(game, turn, []);
      return completeGameState({
        ...gameState,
        branch: { branch, branchFrom: turn, pv: [] }
      });
    }
    case "disableBranch":
      return completeGameState({
        ...gameState,
        turn: turnInRange(
          gameState.branch ? gameState.branch.branchFrom : gameState.turn,
          gameState
        ),
        branch: undefined
      });
    case "doPass": {
      const currentGame = gameState.branch
        ? gameState.branch.branch
        : gameState.game;
      const { turn } = gameState;
      return completeGameState({
        ...gameState,
        branch: {
          branch: doMoves(currentGame, turn, [{ type: "pass" }]),
          branchFrom: gameState.branch ? gameState.branch.branchFrom : turn,
          pv: []
        },
        turn: turn + 1
      });
    }
    case "applyPv": {
      const currentGame = gameState.branch
        ? gameState.branch.branch
        : gameState.game;
      const { turn } = gameState;
      const { candidates } = getScoreView(state, turn);
      const score = candidates[action.candidate];
      const pvMoves = score?.pv;
      if (!pvMoves) return gameState;

      const pv: Array<ScoreEntry | undefined> = [];
      for (let i = 0; i < pvMoves.length; i++) {
        pv[turn + i + 1] = { ...score, pv: pvMoves.slice(i + 1) };
      }
      return completeGameState({
        ...gameState,
        branch: {
          branch: doMoves(currentGame, turn, pvMoves),
          branchFrom: gameState.branch ? gameState.branch.branchFrom : turn,
          pv
        },
        turn: turn + 1
      });
    }
    case "setGameTurn":
      return completeGameState({
        ...gameState,
        turn: turnInRange(action.turn, gameState)
      });
    case "forward":
      return completeGameState({
        ...gameState,
        turn: turnInRange(gameState.turn + 1, gameState)
      });
    case "backward":
      return completeGameState({
        ...gameState,
        turn: turnInRange(gameState.turn - 1, gameState)
      });
  }
  return state.gameState;
}

function turnInRange(turn: number, gameState: GameState): number {
  const { turns } = !gameState.branch
    ? gameState.game
    : gameState.branch.branch;
  return Math.min(turns - 1, Math.max(0, turn));
}

type NewGameState =
  | Omit<KifuGame, "currentSfen" | "currentPosition">
  | Omit<NarabeGame, "currentSfen" | "currentPosition">;
function completeGameState(gameState: NewGameState): GameState {
  if (!gameState.branch) {
    const currentPosition = gameState.game.positions[gameState.turn];
    const currentSfen = generateSfen(currentPosition);
    return { ...gameState, currentPosition, currentSfen };
  }
  const currentPosition = gameState.branch.branch.positions[gameState.turn];
  const currentSfen = generateSfen(currentPosition);
  return { ...gameState, currentPosition, currentSfen };
}

function doMovesBySfen(
  game: Game,
  branchFrom: number,
  sfenMoves: string[]
): Game;
function doMovesBySfen(
  game: ExtendedGame,
  branchFrom: number,
  sfenMoves: string[]
): ExtendedGame;
function doMovesBySfen(
  game: ExtendedGame,
  branchFrom: number,
  sfenMoves: string[]
): ExtendedGame {
  let turn = branchFrom;
  const positions = [...game.positions.slice(0, turn + 1)];
  const sfenPositions = [...game.sfenPositions.slice(0, turn + 1)];
  const moves = [...game.moves.slice(0, turn)];
  let position = positions[turn];
  for (const moveSfen of sfenMoves) {
    const move = createExtendedMoveFromSfen(moveSfen, position);
    if (move === null) {
      console.error("cannot parse sfen moves");
      break;
    }
    position = doMove(position, move);
    positions.push(position);
    sfenPositions.push(generateSfen(position));
    moves.push(move);
    turn++;
  }
  return {
    positions,
    sfenPositions,
    isChecked: positions.map(isChecked),
    moves,
    turns: turn + 1
  };
}

function doMoves(game: Game, branchFrom: number, nextMoves: Move[]): Game;
function doMoves(
  game: ExtendedGame,
  branchFrom: number,
  nextMoves: ExtendedMove[]
): ExtendedGame;
function doMoves(
  game: ExtendedGame,
  branchFrom: number,
  nextMoves: ExtendedMove[]
): ExtendedGame {
  let turn = branchFrom;
  const positions = [...game.positions.slice(0, turn + 1)];
  const sfenPositions = [...game.sfenPositions.slice(0, turn + 1)];
  const moves = [...game.moves.slice(0, turn)];
  let position = positions[turn];
  for (const move of nextMoves) {
    position = doMove(position, move);
    positions.push(position);
    sfenPositions.push(generateSfen(position));
    moves.push(move);
    turn++;
  }
  return {
    positions,
    sfenPositions,
    isChecked: positions.map(isChecked),
    moves,
    turns: turn + 1
  };
}

function makeBranch(game: Game, actionBranch: Branch | undefined) {
  let branch = undefined;
  let turns = game.turns;
  if (actionBranch) {
    branch = {
      branch: doMovesBySfen(
        game,
        actionBranch.branchFrom,
        actionBranch.branchMoves
      ),
      branchFrom: actionBranch.branchFrom,
      pv: []
    };
    turns = branch.branch.turns;
  }
  return { branch, turns };
}
