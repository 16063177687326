import { UsageLimit } from "../../../serverless/src/sharedTypes/usage";
import {
  CompletedEvalResultMulti,
  evalGame,
  evalPositionMulti,
  loadCachesMulti,
  TempEvalResultMulti,
} from "../evalMulti";
import { analytics } from "../firebase";
import { tryNonLoginEval } from "../localStorage";
import { State } from "../state";
import { GameState } from "../state/game";
import { extractAllCompletedSfen, getScore } from "../state/scoreDatabaseMulti";
import { getEvalSetting, getUserDetail, User } from "../state/user";
import { ThunkAction } from "../store";
import { getDailyUsageLimit, recordUsage } from "../usage";
import { calcFailedAction, dailyLimitExceededAction } from "./flashMessage";
import { startNarabeGame } from "./game";

export type GameScoreActions =
  | ReceiveCacheGameScoresAction
  | CalcGameScoreStartAction
  | LoadBranchScoreMultiStartAction
  | EvalFailureAction
  | ReceiveCompletedEvalResultMultiAction
  | ReceiveTempEvalResultMultiAction
  | ReceiveCalcGameEvalResultAction
  | EmitBufferedTempEvalResultsMultiAction
  | EmitBufferedCalcGameEvalResultsAction
  | ReceiveReloadedCacheGameScoresAction
  | CalcGameScoresFinishedAction
  | AskCalcGameScoreAction;

export type LoadBranchScoreMultiStartAction = {
  type: "loadBranchScoreMultiStart";
  sfen: string;
};

export type CalcGameScoreStartAction = {
  type: "calcGameScoresStart";
};

export type CalcGameScoresFinishedAction = {
  type: "calcGameScoresFinished";
};

export type ReceiveCacheGameScoresAction = {
  type: "receiveCacheGameScores";
  evalResultsMulti: CompletedEvalResultMulti[];
  isCompleted: boolean;
};

export type ReceiveReloadedCacheGameScoresAction = {
  type: "receiveReloadedCacheGameScores";
  evalResultsMulti: CompletedEvalResultMulti[];
};

export type ReceiveCalcGameEvalResultAction = {
  type: "receiveCalcGameEvalResult";
  evalResult: CompletedEvalResultMulti;
};

export type ReceiveCompletedEvalResultMultiAction = {
  type: "receiveCompletedEvalResultMulti";
  evalResult: CompletedEvalResultMulti;
  reqMultiPv: number;
};

export type ReceiveTempEvalResultMultiAction = {
  type: "receiveTempEvalResultMulti";
  evalResult: TempEvalResultMulti;
};

export type EmitBufferedTempEvalResultsMultiAction = {
  type: "emitBufferedTempEvalResultsMulti";
  evalResults: TempEvalResultMulti[];
};

export type EmitBufferedCalcGameEvalResultsAction = {
  type: "emitBufferedCalcGameEvalResults";
  evalResults: CompletedEvalResultMulti[];
};

export type EvalFailureAction = {
  type: "evalFailure";
  sfen: string;
};

export type AskCalcGameScoreAction = {
  type: "askCalcGameScore";
  usageLimit: UsageLimit[];
};

export function loadScoreCaches(): ThunkAction {
  return async (dispatch, getState) => {
    const {
      gameState,
      setting: {
        ai: {
          debug: { useCache },
        },
      },
      user,
    } = getState();
    if (!useCache) {
      dispatch({
        type: "receiveCacheGameScores",
        evalResultsMulti: [],
        isCompleted: false,
      });
      return;
    }

    const evalSetting = getEvalSetting(user, gameState);

    const sfenPositions = getSfenPositions(gameState);
    const evalResults = await loadCachesMulti(
      sfenPositions,
      evalSetting.evalMultiPv
    );
    const isCompleted = sfenPositions.every((sfen) =>
      evalResults.some((r) => r.sfen === sfen)
    );
    dispatch({
      type: "receiveCacheGameScores",
      evalResultsMulti: evalResults,
      isCompleted,
    });
  };
}

export function calcGameScoreOrAsk(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.user.type !== "loggedIn") return;
    if (isUnrestrictedUser(state.user) || isShortGame(state)) {
      dispatch(calcGameScore());
      return;
    }

    const usageLimit = await getDailyUsageLimit(state.user.firebaseUser);

    if (isCalcGameScoreDailyLimitExceeded(usageLimit)) {
      dispatch(dailyLimitExceededAction(state.user));
      dispatch(startNarabeGame(0, undefined, [], undefined));
      return;
    }

    dispatch({ type: "askCalcGameScore", usageLimit });
  };
}

export function calcGameScore(): ThunkAction {
  return (dispatch, getState) => {
    const {
      gameState,
      scoreDatabaseMulti,
      setting: {
        ai: { debug },
      },
      user,
    } = getState();

    const evalSetting = getEvalSetting(user, gameState);

    const sfenPositions = getSfenPositions(gameState).filter((sfen) => {
      const score = getScore(scoreDatabaseMulti, sfen, evalSetting.evalMultiPv);
      return score?.type !== "completed";
    });
    if (sfenPositions.length === 0) return;

    const progress = new Map<string, boolean>();
    for (const sfen of sfenPositions) {
      progress.set(sfen, false);
    }

    dispatch({ type: "calcGameScoresStart" });
    evalGame(
      user,
      sfenPositions,
      evalSetting.evalGamePerf,
      evalSetting.evalMultiPv,
      evalSetting.evalGameChunkSize,
      (evalResult) => {
        dispatch({
          type: "receiveTempEvalResultMulti",
          evalResult,
        });
      },
      (evalResult) => {
        dispatch({
          type: "receiveCalcGameEvalResult",
          evalResult,
        });

        progress.set(evalResult.sfen, true);
        if ([...progress.values()].every((v) => v)) {
          dispatch({ type: "calcGameScoresFinished" });
        }
      },
      () => {
        dispatch(dailyLimitExceededAction(user));
        dispatch(startNarabeGame(0, undefined, [], undefined));
      },
      (message) => dispatch(calcFailedAction(message)),
      !debug.useCache,
      evalSetting.shadowBanned
    );

    if (sfenPositions.length > ShortGameLength) {
      recordUsage("kifu_analysis", user);
    }
  };
}

export function loadBranchScoreMulti(sfen: string): ThunkAction {
  return async (dispatch, getState) => {
    const { gameState, user } = getState();

    const evalSetting = getEvalSetting(user, gameState);

    if (gameState.type === "kifu") {
      analytics.logEvent("branch_score_kifu", {
        multiPv: evalSetting.evalMultiPv,
      });
    }
    if (gameState.type === "narabe") {
      analytics.logEvent("branch_score_narabe", {
        multiPv: evalSetting.evalMultiPv,
      });
    }

    dispatch({ type: "loadBranchScoreMultiStart", sfen });

    if (user.type === "loggedOut" && !tryNonLoginEval()) {
      dispatch(processNonLoginUserEval(sfen));
      return;
    }

    const evalResult = await evalPositionMulti(
      user,
      sfen,
      evalSetting.evalSinglePerf,
      evalSetting.evalMultiPv,
      (evalResult) => {
        dispatch({
          type: "receiveTempEvalResultMulti",
          evalResult,
        });
      },
      () => dispatch(dailyLimitExceededAction(user)),
      (message) => dispatch(calcFailedAction(message)),
      false,
      evalSetting.shadowBanned
    );
    dispatch({
      type: "receiveCompletedEvalResultMulti",
      evalResult,
      reqMultiPv: evalSetting.evalMultiPv,
    });

    recordUsage("single_move_analysis", user);
  };
}

export function loadHighPerfScoreMulti(sfen: string): ThunkAction {
  return async (dispatch, getState) => {
    const { gameState, user } = getState();

    const evalSetting = getEvalSetting(user, gameState);

    analytics.logEvent("load_highperf_score", {
      multiPv: evalSetting.evalMultiPv,
    });

    dispatch({ type: "loadBranchScoreMultiStart", sfen });

    if (user.type === "loggedOut" && !tryNonLoginEval()) {
      dispatch(processNonLoginUserEval(sfen));
      return;
    }

    const evalResult = await evalPositionMulti(
      user,
      sfen,
      evalSetting.evalSinglePerf,
      evalSetting.evalMultiPv,
      (evalResult) => {
        dispatch({
          type: "receiveTempEvalResultMulti",
          evalResult,
        });
      },
      () => dispatch(dailyLimitExceededAction(user)),
      (message) => dispatch(calcFailedAction(message)),
      false,
      evalSetting.shadowBanned
    );
    dispatch({
      type: "receiveCompletedEvalResultMulti",
      evalResult,
      reqMultiPv: evalSetting.evalMultiPv,
    });

    recordUsage("single_move_analysis", user);
  };
}

function getSfenPositions({ game, branch }: GameState): string[] {
  return [
    ...game.sfenPositions,
    ...(branch ? branch.branch.sfenPositions.slice(branch.branchFrom + 1) : []),
  ];
}

export function reloadScoreCaches(): ThunkAction {
  return async (dispatch, getState) => {
    const {
      scoreDatabaseMulti,
      gameState,
      setting: {
        ai: {
          debug: { useCache },
        },
      },
      user,
    } = getState();
    if (!useCache) return;

    const evalSetting = getEvalSetting(user, gameState);
    const sfenPositions = extractAllCompletedSfen(scoreDatabaseMulti);
    const evalResults = await loadCachesMulti(
      sfenPositions,
      evalSetting.evalMultiPv
    );
    dispatch({
      type: "receiveReloadedCacheGameScores",
      evalResultsMulti: evalResults,
    });
  };
}

function processNonLoginUserEval(sfen: string): ThunkAction {
  return async (dispatch) => {
    const cache = (await loadCachesMulti([sfen], 1))[0];
    if (cache) {
      dispatch({
        type: "receiveCompletedEvalResultMulti",
        evalResult: cache,
        reqMultiPv: 1,
      });
    } else {
      dispatch({ type: "loginModal", dir: "open", isCancellable: false });
    }
  };
}

const ShortGameLength = 20;

function isCalcGameScoreDailyLimitExceeded(usageLimit: UsageLimit[]): boolean {
  const actionUsageLimit = usageLimit.find((l) => l.action === "kifu_analysis");
  if (!actionUsageLimit) return false;
  return actionUsageLimit.limit <= 0;
}

function isUnrestrictedUser(user: User): boolean {
  return getUserDetail(user)?.userSubscription?.type === "active";
}

function isShortGame(state: State): boolean {
  return getSfenPositionsToEval(state).length <= ShortGameLength;
}

function getSfenPositionsToEval(state: State): string[] {
  const { gameState, scoreDatabaseMulti, user } = state;
  const evalSetting = getEvalSetting(user, gameState);
  return getSfenPositions(gameState).filter((sfen) => {
    const score = getScore(scoreDatabaseMulti, sfen, evalSetting.evalMultiPv);
    return score?.type !== "completed";
  });
}
