import { Action } from "../actions";
import { State } from "../state";
import { KifuApiEntry, GameListEntry } from "../state/kifuApi";

function sortEntries(entries: KifuApiEntry[]): KifuApiEntry[] {
  return entries.sort((a, b) => {
    if (a.url > b.url) return 1;
    if (a.url === b.url) return 0;
    return -1;
  });
}

function sortGames(entries: GameListEntry[]): GameListEntry[] {
  return entries.sort((a, b) => b.displayTimestamp - a.displayTimestamp);
}

export function kifuApiReducer(
  state: State["kifuApi"],
  action: Action
): State["kifuApi"] {
  switch (action.type) {
    case "userLoginByCache":
    case "userLogin": {
      return action.userData.kifuApi;
    }
    case "kifuApiLoadStart": {
      const stay = state.filter(k => k.url !== action.url);
      const crr = state.find(k => k.url === action.url);
      return sortEntries([
        ...stay,
        {
          ...crr,
          type: "loading",
          url: action.url,
          gameList: sortGames(crr?.gameList || [])
        }
      ]);
    }
    case "kifuApiLoadSuccess": {
      const stay = state.filter(k => k.url !== action.url);
      const { url, apiName, gameList } = action;
      return sortEntries([
        ...stay,
        {
          type: "valid",
          url,
          apiName,
          gameList: sortGames(gameList)
        }
      ]);
    }
    case "kifuApiLoadFailed": {
      const stay = state.filter(k => k.url !== action.url);
      const crr = state.find(k => k.url === action.url);
      return sortEntries([
        ...stay,
        {
          ...crr,
          type: "invalid",
          url: action.url,
          message: action.message,
          gameList: sortGames(crr?.gameList || [])
        }
      ]);
    }
    case "removeKifuApi": {
      return sortEntries(state.filter(k => k.url !== action.url));
    }
  }
  return state;
}
