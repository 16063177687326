import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBHBDc13NqCWfHITyqKFaOgWzTHdlP9oYM",
  authDomain: window.location.host,
  databaseURL: "https://kento-37cfb.firebaseio.com",
  projectId: "kento-37cfb",
  storageBucket: "kento-37cfb.appspot.com",
  messagingSenderId: "35626087436",
  appId: "1:35626087436:web:b3a5c72a6352a67c1eea29",
  measurementId: "G-459EEJ37BS",
};
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export default firebase;
