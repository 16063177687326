import Axios from "axios";
import { Action, GetTotalUsageResult, UsageLimit } from "./sharedTypes";
import { User } from "./state/user";

export async function recordUsage(
  action: Action["type"],
  user: User
): Promise<void> {
  if (user.type !== "loggedIn") return;
  const idToken = await user.firebaseUser.getIdToken();
  await Axios.get(
    `${process.env.REACT_APP_ENDPOINT_RECORD_USAGE}?action=${action}`,
    {
      headers: {
        Authorization: `token ${idToken}`,
      },
    }
  );
}

export async function getTotalUsage(
  user: firebase.User
): Promise<GetTotalUsageResult> {
  const idToken = await user.getIdToken();
  const result = (
    await Axios.get(
      `${process.env.REACT_APP_ENDPOINT_SERVERLESS}/getTotalUsage`,
      {
        headers: {
          Authorization: `token ${idToken}`,
        },
      }
    )
  ).data as GetTotalUsageResult;

  return result;
}

export async function getDailyUsageLimit(
  user: firebase.User
): Promise<UsageLimit[]> {
  const idToken = await user.getIdToken();
  const result = (
    await Axios.get(
      `${process.env.REACT_APP_ENDPOINT_SERVERLESS}/getDailyUsageLimit`,
      {
        headers: {
          Authorization: `token ${idToken}`,
        },
      }
    )
  ).data?.usageLimit as UsageLimit[];

  return result || [];
}
