import Axios from "axios";

export async function isValidEmail(email: string): Promise<boolean> {
  return (
    (
      await Axios.get(
        `${
          process.env.REACT_APP_ENDPOINT_SERVERLESS
        }/validateEmail?email=${encodeURIComponent(email)}`
      )
    ).data === "ok"
  );
}
