import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calcGameScoreOrAsk, loadScoreCaches } from "../../actions/gameScore";
import { State } from "../../state";
import { GameInitSequence } from "../../state/gameInitSequence";
import { User } from "../../state/user";
import { Dispatch } from "../../store";

export const GameInitializeSequence: React.FC = () => {
  const gameInitSequence = useSelector<State, GameInitSequence>(
    (state) => state.gameInitSequence
  );
  const user = useSelector<State, User>((state) => state.user);
  const isUserLoadFinished = user.type !== "loading";

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (!(gameInitSequence.type === "kifuLoaded" && isUserLoadFinished)) return;
    dispatch(loadScoreCaches());
  }, [gameInitSequence, isUserLoadFinished, dispatch]);

  useEffect(() => {
    if (gameInitSequence.type !== "scoreCacheLoaded") return;

    if (user.type === "loggedIn") {
      dispatch(calcGameScoreOrAsk());
      return;
    }

    if (user.type === "loggedOut") {
      dispatch({
        type: "loginModal",
        dir: "open",
        message: "新規棋譜の読み込みはログイン後可能になります。",
      });
    }
  }, [gameInitSequence, user, dispatch]);
  return <></>;
};
