import Axios from "axios";
import {
  CreateSessionResult,
  UserSubscription,
  DeleteUserSubscriptionResult,
  CreateSetupSessionResult
} from "./sharedTypes";
import { User } from "./state/user";
import * as Sentry from "@sentry/browser";

const ApiEndpointPayment = process.env.REACT_APP_ENDPOINT_PAYMENT!;

export async function createSession(
  user: User,
  quantity: number
): Promise<string | null> {
  if (user.type !== "loggedIn") return null;
  const idToken = await user.firebaseUser.getIdToken();
  let params: {} = { quantity };
  if (user.firebaseUser.email && user.firebaseUser.emailVerified) {
    params = { ...params, email: user.firebaseUser.email };
  }
  const result = (
    await Axios.post(`${ApiEndpointPayment}/payment/createSession`, params, {
      headers: {
        Authorization: `token ${idToken}`
      }
    })
  ).data as CreateSessionResult;

  if (result?.type !== "success") {
    Sentry.captureException(JSON.stringify(result));
    return null;
  }
  return result.sessionId;
}

export async function createSetupSession(user: User): Promise<string | null> {
  if (user.type !== "loggedIn") return null;
  const idToken = await user.firebaseUser.getIdToken();
  const result = (
    await Axios.get(`${ApiEndpointPayment}/payment/createSetupSession`, {
      headers: {
        Authorization: `token ${idToken}`
      }
    })
  ).data as CreateSetupSessionResult;

  if (result?.type !== "success") {
    Sentry.captureMessage(JSON.stringify(result));
    return null;
  }
  return result.sessionId;
}

export async function deleteUserSubscription(user: User): Promise<boolean> {
  if (user.type !== "loggedIn") return false;
  const idToken = await user.firebaseUser.getIdToken();
  const result = (
    await Axios.get(`${ApiEndpointPayment}/payment/deleteUserSubscription`, {
      headers: {
        Authorization: `token ${idToken}`
      }
    })
  ).data as DeleteUserSubscriptionResult;
  if (result?.type !== "success") {
    Sentry.captureMessage(JSON.stringify(result));
    return false;
  }
  return true;
}

export async function getUserSubscription(
  user: firebase.User
): Promise<UserSubscription | null> {
  const idToken = await user.getIdToken();
  let retryCount = 0;
  while (retryCount <= 5) {
    const userSubscription = (
      await Axios.get(`${ApiEndpointPayment}/payment/getUserSubscription`, {
        headers: {
          Authorization: `token ${idToken}`
        }
      })
    ).data as UserSubscription;
    if (userSubscription?.type) return userSubscription;
    retryCount++;
    await new Promise(resolve => setTimeout(resolve, 2000));
  }
  return null;
}

export async function updateUserSubscriptionQuantity(
  user: firebase.User,
  quantity: number
): Promise<boolean> {
  const idToken = await user.getIdToken();
  return (
    await Axios.get(
      `${ApiEndpointPayment}/payment/updateUserSubscriptionQuantity?quantity=${quantity}`,
      {
        headers: {
          Authorization: `token ${idToken}`
        }
      }
    )
  ).data.result;
}

export async function processCompletedSession(
  user: firebase.User,
  sessionId: string,
  retry: number = 5
): Promise<boolean> {
  const idToken = await user.getIdToken();
  let isSuccess = false;
  while (retry > 0) {
    isSuccess =
      (
        await Axios.get(
          `${ApiEndpointPayment}/payment/processCompletedSession?sessionId=${sessionId}`,
          {
            headers: {
              Authorization: `token ${idToken}`
            }
          }
        )
      ).data.result === "success";
    if (isSuccess) break;
    await new Promise(resolve => setTimeout(resolve, 2000));
    retry--;
  }
  return isSuccess;
}
