import {
  Box,
  Button,
  InputGroup,
  InputRightAddon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/core";
import * as Sentry from "@sentry/browser";
import React, { useState } from "react";
import { createSession } from "../../payment";
import { LoggedInUser } from "../../state/user";
import { redirectToCheckout } from "../../stripe";

export type QuantityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: LoggedInUser;
};
export const QuantityModal: React.FC<QuantityModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const toast = useToast();
  const initialRef = React.useRef();
  const [quantity, setQuantity] = useState<string>("1");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const startSession = async () => {
    setIsSubmitting(true);
    const sessionId = await createSession(user, Number.parseInt(quantity));
    if (!sessionId) {
      setIsSubmitting(false);
      return;
    }
    const error = await redirectToCheckout(sessionId);
    toast({
      title: "エラー",
      description:
        "リダイレクトに失敗しました。時間を開けて再度お試しください。",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    Sentry.captureException(error);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef as any}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>支援口数を入力してください</ModalHeader>
        <ModalBody mb="4" mx="4">
          <Box
            display="flex"
            mt="4"
            mb="8"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="semibold" color="gray.500">
              月額500円
            </Text>
            <Text fontSize="lg" fontWeight="semibold" color="gray.500" mx="4">
              ×
            </Text>
            <InputGroup w="120px">
              <Select
                aria-labelledby="quantity"
                onChange={(e: any) => setQuantity(e.target.value)}
                value={quantity}
              >
                {Array.from(Array(10).keys()).map((i) => (
                  <option key={i} value={(i + 1).toString()}>
                    {i + 1}
                  </option>
                ))}
              </Select>
              <InputRightAddon>口</InputRightAddon>
            </InputGroup>
          </Box>
          <List listStyleType="disc" fontSize="sm">
            <ListItem>
              1口 (月額500円) で、すべての特典が受けられます。
            </ListItem>
            <ListItem>支援口数は登録後いつでも変更ができます。</ListItem>
            <ListItem>
              KENTO Pro プランは登録後いつでもキャンセルできます。
              (キャンセルした時点で特典は無効になります。)
            </ListItem>
          </List>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            variantColor="teal"
            onClick={startSession}
          >
            支払い手続きに進む
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
