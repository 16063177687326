import { Box, BoxProps, Text } from "@chakra-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { convertMoveJp } from "shogi-ts";
import { ScoreEntry } from "../../evalMulti";
import { State } from "../../state";
import { getUserDetail, User } from "../../state/user";
import { displayScore, getScoreView, ScoreView } from "./scoreMoveUtil";
import { Dispatch } from "../../store";
import { applyPv } from "../../actions/game";

export const MobileCandidates: React.FC<BoxProps> = props => {
  const user = useSelector<State, User>(state => state.user);
  const userDetail = getUserDetail(user);
  const displayRows = userDetail
    ? userDetail.userData.displayMobileMultiPv - 1
    : 2;
  return <Candidates displayRows={displayRows} {...props} />;
};

type CandidatesProps = {
  displayRows?: number;
} & BoxProps;
const Candidates: React.FC<CandidatesProps> = ({ displayRows, ...props }) => {
  const { type, candidates } = useSelector<State, ScoreView>(state =>
    getScoreView(state, state.gameState.turn)
  );
  const textColor = type === "low_perf" ? "gray.700" : "gray.700";
  const grayTextColor = type === "low_perf" ? "gray.500" : "gray.500";
  let displayEntries = candidates.slice(1);
  if (displayRows !== undefined) {
    displayEntries = displayEntries.slice(0, displayRows);
    while (displayEntries.length < displayRows) {
      displayEntries.push({});
    }
  }
  return (
    <Box {...props}>
      <Table>
        <tbody>
          {displayEntries.map((entry, idx) => (
            <Candidate
              rank={idx + 2}
              entry={entry}
              textColor={textColor}
              grayTextColor={grayTextColor}
              scoreType={type}
              key={idx}
            />
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

type CandidateProps = {
  rank: number;
  entry: ScoreEntry;
  textColor: string;
  grayTextColor: string;
  scoreType: "low_perf" | "normal" | "pv";
};
const Candidate: React.FC<CandidateProps> = ({
  rank,
  entry,
  textColor,
  grayTextColor,
  scoreType
}) => {
  const move = entry.pv?.[0];
  const moveJp = move ? convertMoveJp(move) : "-";
  const score = displayScore(entry);
  const pv = entry.pv?.slice(1, 4) || [];
  const pvJp = pv.map(convertMoveJp);

  const dispatch = useDispatch<Dispatch>();
  const onClickCandidate = () => {
    if (scoreType === "low_perf" || scoreType === "normal") {
      dispatch(applyPv(rank - 1));
    }
  };
  return (
    <Box as="tr" borderTopWidth={"1px"} mb="1" onClick={onClickCandidate}>
      <TData w="26px">
        <Text fontSize="xs" color="gray.400">
          #{rank}
        </Text>
      </TData>
      <TData>
        <Text fontWeight="semibold" fontSize="xs" color={textColor}>
          {score}
        </Text>
      </TData>
      <TData>
        <Text
          whiteSpace="nowrap"
          fontWeight="semibold"
          fontSize="xs"
          color={textColor}
        >
          {moveJp}
        </Text>
      </TData>
      <TData>
        <Box mb="-1px" display="flex" alignSelf="flex-end" flexWrap="nowrap">
          {pvJp.map((pv, idx) => (
            <Text
              fontSize="xs"
              color={grayTextColor}
              key={idx}
              whiteSpace="nowrap"
            >
              {pv}
            </Text>
          ))}
        </Box>
      </TData>
    </Box>
  );
};

export const Table = (props: BoxProps) => (
  <Box as="table" textAlign="left" width="full" {...props} />
);

export const TData = (props: BoxProps) => (
  <Box
    as="td"
    pl={2}
    py={2}
    borderColor="inherit"
    fontSize="sm"
    whiteSpace="normal"
    verticalAlign="bottom"
    {...props}
  />
);
