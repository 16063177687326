import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state";
import { getScoreView, ScoreView } from "./scoreMoveUtil";

export type ScoreChartState = {
  turn: number;
  canUpgrade: boolean[];
  isPro: boolean[];
  scores: { chartValue: number; turn: number }[];
  logisticChart: boolean;
  onClickTurn: (turn: number) => void;
};

export function useScoreChartState(): ScoreChartState {
  const dispatch = useDispatch();
  const onClickTurn = (turn: number) => {
    dispatch({ type: "setGameTurn", turn });
  };
  const logisticChart = useSelector<State, boolean>(({ user }) => {
    if (user.type === "loggedIn" || user.type === "loggedInCache") {
      return user.userData.logisticChart;
    }
    return true;
  });
  const scoreViews = useSelector<State, ScoreView[]>(state => {
    const ret: ScoreView[] = [];
    const currentGame = state.gameState.branch
      ? state.gameState.branch.branch
      : state.gameState.game;
    for (let i = 0; i < currentGame.turns; i++) {
      ret.push(getScoreView(state, i));
    }
    return ret;
  });
  const scores = scoreViews.map(({ bestScore }, turn) => {
    const INF = 10000000;
    let chartValue = bestScore?.scoreCp !== undefined ? bestScore.scoreCp : 0;
    if (bestScore?.scoreMate !== undefined) {
      if (bestScore.scoreMate === 0) {
        chartValue = turn % 2 === 0 ? -INF : INF;
      } else {
        chartValue = bestScore.scoreMate > 0 ? INF : -INF;
      }
    }
    if (logisticChart) chartValue = winRate(chartValue);
    return { chartValue, turn };
  });
  const canUpgrade = scoreViews.map(s => s.canUpgrade);
  const isPro = scoreViews.map(s => s.isProPerf);
  const turn = useSelector<State, number>(({ gameState: { turn } }) => turn);

  return { turn, canUpgrade, isPro, scores, logisticChart, onClickTurn };
}

function winRate(scoreCp: number): number {
  // 本来の変換式は sigmoid(scoreCp / 600) だが, 上が潰れすぎるため調整
  return (sigmoid(scoreCp / 900) - 1 / 2) * 2;
}

function sigmoid(t: number): number {
  return 1 / (1 + Math.exp(-t));
}
