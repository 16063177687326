import {
  Box,
  BoxProps,
  Button,
  Divider,
  Heading,
  Icon,
  IconButton,
  Input,
  Text
} from "@chakra-ui/core";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoggedInUser } from "../../actions/user";
import firebase, { analytics } from "../../firebase";
import { doActionWithReauth, handleRedirect } from "../../redirectHandler";
import { State } from "../../state";
import { LoggedInUser } from "../../state/user";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { Loading } from "../main/Loading";
import { CancelSupporterPlan } from "./CancelSupporterPlan";
import { Supporter } from "./Supporter";

export const Account: React.FC<BoxProps> = props => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, State["user"]>(state => state.user);

  useEffect(() => {
    if (user.type !== "loggedIn") return;
    handleRedirect(user.firebaseUser, dispatch);
  }, [user, dispatch]);

  useEffect(() => {
    analytics.logEvent("page_view_account");
    window.document.title = "KENTO | アカウント";
  }, []);

  useEffect(() => {
    if (user.type === "loggedOut") {
      history.push("/");
    }
  }, [user, history]);

  const isContentsLoaded = user.type === "loggedIn";

  return (
    <Container>
      <Box display="flex" alignItems="flex-end">
        <Heading w="100%" onClick={() => history.push("/")}>
          <span style={{ cursor: "pointer", userSelect: "none" }}>☗ KENTO</span>
        </Heading>
        <IconButton
          aria-label="close"
          icon="close"
          variant="ghost"
          onClick={() => history.push("/")}
        />
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box
        opacity={isContentsLoaded ? 1 : 0}
        transition="opacity .5s"
        fontSize="sm"
        mt="8"
        mx="2"
        {...props}
      >
        {user.type !== "loggedIn" ? null : <MainContent user={user} />}
      </Box>
    </Container>
  );
};

export const MainContent: React.FC<{ user: LoggedInUser }> = ({ user }) => {
  const { firebaseUser } = user;

  return (
    <Box>
      <AccountInfo firebaseUser={firebaseUser} />
      {user.userSubscription.type === "active" ? (
        <Supporter user={user} />
      ) : null}
      <SocialLogin firebaseUser={firebaseUser} />
      {user.userSubscription.type === "active" ? (
        <CancelSupporterPlan user={user} />
      ) : (
        <DeleteAccount firebaseUser={firebaseUser} />
      )}
    </Box>
  );
};

type AccountInfoProps = {
  firebaseUser: firebase.User;
};
export const AccountInfo: React.FC<AccountInfoProps> = ({ firebaseUser }) => {
  const dispatch = useDispatch<Dispatch>();
  const updateEmail = () => {
    const email = prompt("メールアドレスを入力してください。");
    if (!email) return;
    doActionWithReauth(firebaseUser, {
      type: "updateEmail",
      email
    });
  };

  const sendEmailVerification = async () => {
    await firebaseUser.sendEmailVerification();
    dispatch({
      type: "flashMessageOpen",
      messages: [
        `ご登録頂いたメールアドレス (${firebaseUser.email}) に確認メールをお送りしました。`,
        "メールのリンクをクリックして登録完了してください。"
      ],
      modalType: "agree"
    });
  };

  let creationTime = "No data";
  if (firebaseUser.metadata.creationTime) {
    creationTime = moment(firebaseUser.metadata.creationTime).format("LLL");
  }
  let lastSignInTime = "No data";
  if (firebaseUser.metadata.lastSignInTime) {
    lastSignInTime = moment(firebaseUser.metadata.lastSignInTime).format("LLL");
  }

  return (
    <>
      <Heading size="md" mt="4">
        アカウント情報
      </Heading>
      <Divider />
      <Box pl="4">
        <Heading size="xs" mb="2">
          登録メールアドレス
          {firebaseUser.emailVerified ? null : "(未確認)"}
        </Heading>
        <Box>
          <Box display="flex" alignItems="flex-end">
            <Text mb="6px">
              {firebaseUser.emailVerified ? (
                <Icon name="check-circle" color="teal.500" mb="3px" mr="1" />
              ) : null}
              {firebaseUser.email}
            </Text>
            <IconButton
              aria-label="updateEmail"
              icon="edit"
              size="sm"
              variant="ghost"
              onClick={updateEmail}
            />
          </Box>
          {!firebaseUser.email ? (
            <Button size="xs" variantColor="teal" mt="2" onClick={updateEmail}>
              メールアドレスを登録
            </Button>
          ) : null}
          {firebaseUser.email && !firebaseUser.emailVerified ? (
            <Button
              size="xs"
              variantColor="teal"
              mt="2"
              onClick={sendEmailVerification}
            >
              確認メールを送信
            </Button>
          ) : null}
        </Box>
        <Heading size="xs" mb="2" mt="4">
          登録日
        </Heading>
        <Text>{creationTime}</Text>
        <Heading size="xs" mb="2" mt="4">
          最終ログイン日
        </Heading>
        <Text>{lastSignInTime}</Text>
      </Box>
    </>
  );
};

type SocialLoginProps = {
  firebaseUser: firebase.User;
};
const SocialLogin: React.FC<SocialLoginProps> = ({ firebaseUser }) => {
  const dispatch = useDispatch<Dispatch>();
  const socialLoginInfo = getSocialLoginInfo(firebaseUser);
  const enableUnlinkSocial =
    socialLoginInfo.reduce(
      (acc, crr) => (crr.providerData ? acc + 1 : acc),
      0
    ) >= 2;
  return (
    <>
      <Heading size="md" mt="8">
        ソーシャルログイン
      </Heading>
      <Divider />
      <Box pl="4">
        {socialLoginInfo.map((service, idx) => (
          <Box mb="6" key={idx}>
            {service.providerData ? (
              <>
                <Heading size="xs" mb="2">
                  {service.serviceName}
                </Heading>
                <Box display="flex" alignItems="flex-end">
                  <Icon name="check-circle" color="teal.500" mb="3px" mr="1" />
                  <Text>
                    {service.providerData.displayName} /{" "}
                    {service.providerData.email}
                  </Text>
                </Box>
                {enableUnlinkSocial ? (
                  <Button
                    size="xs"
                    variantColor="red"
                    mt="2"
                    onClick={async () => {
                      dispatch({ type: "reloadUser" });
                      const user = await firebaseUser.unlink(
                        service.providerId
                      );
                      dispatch(setLoggedInUser(user, false));
                    }}
                  >
                    リンク解除する
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                <Heading size="xs" mb="2">
                  {service.serviceName}
                </Heading>
                <Box>
                  <Button
                    size="xs"
                    variantColor="teal"
                    onClick={() =>
                      firebaseUser.linkWithRedirect(service.createProvider())
                    }
                  >
                    リンクする
                  </Button>
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

type DeleteAccountProps = {
  firebaseUser: firebase.User;
};
const DeleteAccount: React.FC<DeleteAccountProps> = ({ firebaseUser }) => {
  const [isDeleteButtonActive, setIsDeleteButtonActive] = React.useState(false);
  const [emailToDelete, setEmailToDelete] = React.useState("");
  const handleEmailToDeleteChange = (value: string) => {
    setEmailToDelete(value);
    setIsDeleteButtonActive(value === firebaseUser.email);
  };

  const deleteAccount = () =>
    doActionWithReauth(firebaseUser, { type: "deleteAccount" });
  return (
    <>
      <Heading size="md" mt="32" color="red.500">
        アカウント削除
      </Heading>
      <Divider />
      <Box pl="4">
        <Text color="red.500">この操作は取り消しできません。</Text>
        <Text>
          リンク済みのソーシャルログイン情報は即座にサーバーから破棄されます。その他利用履歴等の情報は一定期間後にサーバーから破棄されます。
        </Text>
        <Box width="320px" mt="4">
          <Input
            placeholder="登録メールアドレスを入力してください"
            value={emailToDelete}
            size="sm"
            onChange={(e: any) => handleEmailToDeleteChange(e.target.value)}
          />
        </Box>
        <Button
          mt="4"
          variantColor="red"
          size="sm"
          onClick={deleteAccount}
          isDisabled={!isDeleteButtonActive}
        >
          アカウント削除する
        </Button>
      </Box>
    </>
  );
};

function getSocialLoginInfo(user: firebase.User) {
  return [
    {
      serviceName: "Google",
      providerId: "google.com",
      providerData: user.providerData.find(d => d?.providerId === "google.com"),
      createProvider: () => new firebase.auth.GoogleAuthProvider()
    },
    {
      serviceName: "Twitter",
      providerId: "twitter.com",
      providerData: user.providerData.find(
        d => d?.providerId === "twitter.com"
      ),
      createProvider: () => new firebase.auth.TwitterAuthProvider()
    }
  ];
}
