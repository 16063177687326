import * as Sentry from "@sentry/browser";
import "moment/locale/ja";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { releaseDate, version } from "../package.json";
import { Action } from "./actions";
import { emailAlreadyExistsErrorAction } from "./actions/errorMessage";
import { loadSavedUser, setLoggedInUser, userLogout } from "./actions/user";
import firebase, { analytics } from "./firebase";
import "./index.css";
import { loadSavedPath } from "./localStorage";
import { Account } from "./pages/account/Account";
import { KifuList } from "./pages/games/KifuList";
import { App } from "./pages/main/App";
import { ScreenShot } from "./pages/main/ScreenShot";
import { ReleaseNote } from "./pages/releases/ReleaseNote";
import { Setting } from "./pages/setting/Setting";
import { Supporter } from "./pages/supporter/Supporter";
import { Agreement } from "./pages/term/Agreement";
import { Specified } from "./pages/term/Specified";
import { ThankYou } from "./pages/thankyou/ThankYou";
import { isPWA } from "./pwaUtil";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./store";
import { isUpdated } from "./update";

Sentry.init({
  dsn: "https://7345b177f51a41a0a05e7f6364d6eff9@sentry.io/2088180",
  release: `${version}@${releaseDate}`
});

if (isPWA()) {
  const savedPath = loadSavedPath();
  if (savedPath && !window.location.search) {
    window.location.href = `${window.location.origin}/${savedPath}`;
  }
}

const store = configureStore();

async function handleSignInWithEmailLink() {
  let email = window.localStorage.getItem("emailForSignIn");
  while (!email) {
    email = window.prompt("メールアドレスを入力してください。");
  }

  try {
    const result = await firebase
      .auth()
      .signInWithEmailLink(email, window.location.href);
    if (result.user) {
      store.dispatch<Action>(setLoggedInUser(result.user) as any);
    }
    window.localStorage.removeItem("emailForSignIn");
  } catch (e) {
    console.error(e);
    store.dispatch<Action>({
      type: "flashMessageOpen",
      messages: [
        "ログインに失敗しました。リンクが無効な可能性があります。",
        "リロードして再度ログインしてください。"
      ],
      modalType: "dead"
    });
  }
}

function handleAuthState() {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      store.dispatch<Action>(setLoggedInUser(user) as any);
    } else {
      store.dispatch<Action>(userLogout() as any);
    }
  });
}

if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
  handleSignInWithEmailLink().then(() => handleAuthState());
} else {
  handleAuthState();
}

firebase
  .auth()
  .getRedirectResult()
  .then(result => {
    if (result.additionalUserInfo?.isNewUser) {
      const providerId = result.user?.providerData[0]?.providerId;
      if (providerId) {
        analytics.logEvent("sign_up", {
          method: providerId
        });
      }
      if (result.user?.email && result.user?.emailVerified === false) {
        result.user.sendEmailVerification();
        store.dispatch<Action>({
          type: "flashMessageOpen",
          messages: [
            `ご登録頂いたメールアドレス (${result.user.email}) に確認メールをお送りしました。`,
            "メールのリンクをクリックして登録完了してください。"
          ],
          modalType: "agree"
        });
      }
    }

    if (result.user) {
      store.dispatch<Action>(setLoggedInUser(result.user) as any);
    }
  })
  .catch(error => {
    if (error.code === "auth/account-exists-with-different-credential") {
      store.dispatch(emailAlreadyExistsErrorAction(error.email));
    } else {
      store.dispatch({
        type: "errorMessage",
        message: "ログインに失敗しました。"
      });
    }
  });

store.dispatch<Action>(loadSavedUser() as any);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/agreement">
          <Agreement />
        </Route>
        <Route exact path="/specified">
          <Specified />
        </Route>
        <Route exact path="/account">
          <Account />
        </Route>
        <Route exact path="/setting">
          <Setting />
        </Route>
        <Route exact path="/supporter">
          <Supporter />
        </Route>
        <Route exact path="/games">
          <KifuList />
        </Route>
        <Route exact path="/thankyou">
          <ThankYou />
        </Route>
        <Route exact path="/releases">
          <ReleaseNote />
        </Route>
        <Route exact path="/screenshot">
          <ScreenShot />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

document.addEventListener("visibilitychange", async () => {
  if (document.visibilityState === "visible" && (await isUpdated())) {
    alert("新しいバージョンが見つかりました。アップデートします。");
    store.dispatch({ type: "resetGameInitSequence" });
    serviceWorker.unregister();
    window.location.reload(true);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate(registration) {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload(true);
  }
});
