import React from "react";
import { AppMenu } from "./AppMenu";
import { LoginButton } from "./LoginButton";
import { User } from "../../state/user";

export const AppMenuOrLoginButton: React.FC<{ loginStatus: User["type"] }> = ({
  loginStatus
}) => {
  switch (loginStatus) {
    case "loggedInCache":
    case "loggedIn":
      return <AppMenu />;
    case "loggedOut":
      return <LoginButton />;
    default:
      return null;
  }
};
