import { State } from "../state";
import { Action } from "../actions";

export function scoreDetailReducer(
  state: State["scoreDetail"],
  action: Action
): State["scoreDetail"] {
  switch (action.type) {
    case "scoreDetail":
      if (action.dir === "open") {
        return { type: "open" };
      }
      return { type: "closed" };
    default:
      return state;
  }
}
