import { Box, BoxProps, Text } from "@chakra-ui/core";
import { isEqual } from "lodash-es";
import React from "react";
import { PieceOnBoard } from "shogi-ts";
import { BasePieceHeightPx, BasePieceWidthPx } from ".";
import { mapResponsiveValue } from "./util";

export type PieceProps = {
  piece: PieceOnBoard | null;
  count?: number;
  highlight?: boolean;
} & BoxProps;

export const Piece: React.FC<PieceProps> = React.memo(
  (props: PieceProps) => {
    const { piece, count, highlight } = props;
    if (piece === null)
      return (
        <Box
          w={BasePieceWidthPx}
          h={BasePieceHeightPx}
          bg={highlight ? "white" : "orange.100"}
          style={{ WebkitTapHighlightColor: "rgba(0,0,0,0)" }}
          {...props}
        />
      );

    const pieceText =
      piece.piece === "k" && piece.side === "b"
        ? PieceText["K"]
        : PieceText[piece.piece];
    const isSmallPiece = piece.piece === "p" || piece.piece === "+p";
    const pieceHeight = isSmallPiece
      ? mapResponsiveValue(BasePieceHeightPx, v => `calc(${v}*0.95)`)
      : BasePieceHeightPx;
    const pieceWidth = isSmallPiece
      ? mapResponsiveValue(BasePieceWidthPx, v => `calc(${v}*0.95)`)
      : BasePieceWidthPx;

    return (
      <Box
        bg={highlight ? "white" : "orange.100"}
        style={{ WebkitTapHighlightColor: "rgba(0,0,0,0)" }}
        position="relative"
        {...props}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          style={{ filter: "drop-shadow(1px 1px 1px rgba(50, 50, 0, 0.5))" }}
        >
          <Box
            w={pieceWidth}
            h={pieceHeight}
            bg="orange.300"
            style={{
              clipPath: "polygon(50% 8%, 82% 22%, 89% 94%, 11% 94%, 18% 22%)",
              WebkitClipPath:
                "polygon(50% 8%, 82% 22%, 89% 94%, 11% 94%, 18% 22%)",
              transform: `rotate(${piece.side === "b" ? "0" : "180deg"})`
            }}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            fontFamily="Kosugi Maru"
            fontSize={mapResponsiveValue(pieceHeight, v => `calc(${v}*0.5)`)}
            pointerEvents="none"
            userSelect="none"
          >
            <Box mb="8%">{pieceText}</Box>
          </Box>
        </Box>
        {count && count > 1 ? <Text {...countStyle()}>{count}</Text> : null}
      </Box>
    );
  },
  (l, r) =>
    isEqual([l.piece, l.count, l.highlight], [r.piece, r.count, r.highlight])
);

const countStyle = () => ({
  position: "absolute" as const,
  top: 0,
  right: {
    base: `calc(${BasePieceWidthPx.base}/16)`,
    md: `calc(${BasePieceWidthPx.md}/16)`
  },
  color: "white",
  fontSize: {
    base: `calc(${BasePieceHeightPx.base}/2.4)`,
    md: `calc(${BasePieceHeightPx.md}/2.4)`
  },
  textShadow:
    "1px 1px #4A5568, 1px -1px #4A5568, -1px 1px #4A5568, -1px -1px #4A5568",
  pointerEvents: "none" as const,
  userSelect: "none" as const
});

const PieceText = {
  p: "歩",
  l: "香",
  n: "桂",
  s: "銀",
  g: "金",
  b: "角",
  r: "飛",
  k: "王",
  K: "玉",
  "+p": "と",
  "+l": "杏",
  "+n": "圭",
  "+s": "全",
  "+b": "馬",
  "+r": "竜"
};
