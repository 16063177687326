import { State } from "../state";
import { Action } from "../actions";

export function errorMessageReducer(
  state: State["errorMessage"],
  action: Action
): State["errorMessage"] {
  switch (action.type) {
    case "errorMessage":
      return action.message;
    default:
      return state;
  }
}
