import { Box, BoxProps, Heading, IconButton } from "@chakra-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { analytics } from "../../firebase";
import { handleRedirect } from "../../redirectHandler";
import { State } from "../../state";
import { UserDetail } from "../../state/user";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { Loading } from "../main/Loading";
import { EvalSetting } from "./EvalSetting";
import { KifuAPI } from "./KifuAPI";

export const Setting: React.FC<BoxProps> = props => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, State["user"]>(state => state.user);

  useEffect(() => {
    if (user.type !== "loggedIn") return;
    handleRedirect(user.firebaseUser, dispatch);
  }, [user, dispatch]);

  useEffect(() => {
    analytics.logEvent("page_view_setting");
    window.document.title = "KENTO | 設定";
  }, []);

  useEffect(() => {
    if (user.type === "loggedOut") {
      history.push("/");
    }
  }, [user, history]);

  const isContentsLoaded = user.type === "loggedIn";

  return (
    <Container>
      <Box display="flex" alignItems="flex-end">
        <Heading w="100%" onClick={() => history.push("/")}>
          <span style={{ cursor: "pointer", userSelect: "none" }}>☗ KENTO</span>
        </Heading>
        <IconButton
          aria-label="close"
          icon="close"
          variant="ghost"
          onClick={() => history.push("/")}
        />
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box
        opacity={isContentsLoaded ? 1 : 0}
        transition="opacity .5s"
        fontSize="sm"
        mt="8"
        mx="2"
        {...props}
      >
        {user.type === "loggedIn" || user.type === "loggedInCache" ? (
          <MainContent user={user} />
        ) : null}
      </Box>
    </Container>
  );
};

export const MainContent: React.FC<{ user: UserDetail }> = ({ user }) => {
  return (
    <Box>
      <EvalSetting user={user} />
      <KifuAPI />
    </Box>
  );
};
