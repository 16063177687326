import { FlashMessage } from "../state/flashMessage";
import React, { ReactNode } from "react";
import { Box, Link, Icon } from "@chakra-ui/core";
import { User, getUserDetail } from "../state/user";

export type FlashMessageActions =
  | FlashMessageOpenAction
  | FlashMessageCloseAction;

export type FlashMessageOpenAction = {
  type: "flashMessageOpen";
  title?: string;
  messages: string[];
  modalType: FlashMessage["modalType"];
  content?: ReactNode;
};

export type FlashMessageCloseAction = {
  type: "flashMessageClose";
};

export function updateEmailFailedAction(
  email: string,
  error: string
): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    title: "メールアドレスの更新に失敗しました",
    messages: [
      `エラー (${error}) が発生しました。メールアドレスを確認して再度実行してください。`,
    ],
    modalType: "agree",
  };
}

export function deleteAccountFailedAction(
  error: string
): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    title: "退会処理に失敗しました",
    messages: [`エラー (${error}) が発生しました。再度実行してください。`],
    modalType: "agree",
  };
}

export function invalidGameAction(
  turn: number,
  reason: string
): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    title: "棋譜エラー",
    messages: [
      `${turn} 手目に不正な着手 (${reason}) があります。正常な着手のみ表示します。`,
    ],
    modalType: "agree",
  };
}

export function parseErrorAction(message?: string): FlashMessageOpenAction {
  const messages = [`棋譜読み込みに失敗しました。`];
  if (message) messages.push(message);
  return {
    type: "flashMessageOpen",
    title: "棋譜エラー",
    messages,
    modalType: "agree",
  };
}

export function firstLoginNotificationAction(): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    modalType: "light",
    messages: [],
    title: "使い方のヒント",
    content: (
      <Box>
        <Link
          color="teal.500"
          href="https://note.com/shogi_kento/n/nea5e736f5311"
          isExternal
        >
          棋譜の読み込み方法はこちら <Icon name="external-link" mx="2px" />
        </Link>
        をご覧ください。 (ヘルプメニューよりいつでも確認できます。)
      </Box>
    ),
  };
}

export function dailyLimitExceededAction(user: User): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    title: "利用できません",
    messages: [getDailyLimitExceededMessage(user)],
    modalType: "agree",
  };
}

function getDailyLimitExceededMessage(user: User): string {
  const userDetail = getUserDetail(user);
  if (userDetail?.userSubscription.type === "no_payment") {
    return "無料ユーザーの1日の利用回数上限に達しました。KENTO Pro プランにアップグレードしてください。";
  }
  return "1日の利用回数上限に達しました。";
}

export function calcFailedAction(message: string): FlashMessageOpenAction {
  return {
    type: "flashMessageOpen",
    title: "評価値計算に失敗",
    messages: [message],
    modalType: "agree",
  };
}
