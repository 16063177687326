import { Button, ButtonProps } from "@chakra-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";

export type LoginButtonProps = Omit<ButtonProps, "children">;

export const LoginButton: React.FC<LoginButtonProps> = (
  props: LoginButtonProps
) => {
  const dispatch = useDispatch<Dispatch>();
  const handleLoginClick = () => {
    dispatch({
      type: "loginModal",
      dir: "open",
      showNoLoginButton: false,
      isCancellable: true
    });
  };
  return (
    <Button variantColor="blue" onClick={handleLoginClick} {...props}>
      LOGIN
    </Button>
  );
};
