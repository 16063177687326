import {
  Box,
  BoxProps,
  Divider,
  Heading,
  Icon,
  IconButton,
  Link,
  Text,
} from "@chakra-ui/core";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setLoggedInUser,
  updateUserDataOnActivation,
} from "../../actions/user";
import { analytics } from "../../firebase";
import { processCompletedSession } from "../../payment";
import { State } from "../../state";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { Loading } from "../main/Loading";

export const ThankYou: React.FC<BoxProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, State["user"]>((state) => state.user);

  const isContentsLoaded =
    user.type === "loggedIn" && user.userSubscription.type === "active";

  useEffect(() => {
    window.document.title = "KENTO";
  }, []);

  useEffect(() => {
    if (user.type !== "loggedIn") return;

    const params = new URLSearchParams(window.location.search);
    if (
      params.get("action") === "registerSubscriptionSuccess" &&
      params.get("sessionId")
    ) {
      history.replace("/thankyou");
      const sessionId = params.get("sessionId")!;

      processCompletedSession(user.firebaseUser, sessionId).then(
        (isSuccess) => {
          if (!isSuccess) {
            history.replace("/account?action=retryCountExceeded");
            return;
          }
          analytics.logEvent("purchase_supporter_plan");
          dispatch(setLoggedInUser(user.firebaseUser, false));
          dispatch(updateUserDataOnActivation());
        }
      );
    }
  }, [user, dispatch, history]);

  return (
    <Container>
      <Box display="flex" alignItems="flex-end">
        <Heading w="100%" onClick={() => history.push("/setting")}>
          <span style={{ cursor: "pointer", userSelect: "none" }}>☗ KENTO</span>
        </Heading>
        <IconButton
          aria-label="close"
          icon="close"
          variant="ghost"
          onClick={() => history.push("/setting")}
        />
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box
        opacity={isContentsLoaded ? 1 : 0}
        transition="opacity .5s"
        mt="8"
        mb="8"
        mx="2"
        {...props}
      >
        {user.type === "loggedIn" ? <ThankYouContent /> : null}
      </Box>
    </Container>
  );
};

const ThankYouContent: React.FC = () => {
  const tweetButton = useRef();

  useEffect(() => {
    if (tweetButton) {
      (window as any).twttr.widgets.load(tweetButton.current);
    }
  }, [tweetButton]);

  return (
    <Box textAlign="center">
      <Box display="flex" justifyContent="center" alignItems="flex-end" my="16">
        <Text fontSize="6xl" fontWeight="semibold" color="teal.400" mx="4px">
          THANK YOU!
        </Text>
      </Box>
      <Heading size="sm" color="gray.500" mt="16">
        ご登録ありがとうございます!
      </Heading>
      <Divider />
      <Box my="4" maxWidth="780px" mx="auto" textAlign="left">
        <Text fontSize="sm" color="gray.600">
          機能要望や障害報告は
          <Link
            color="teal.500"
            href="https://twitter.com/shogi_kento"
            isExternal
          >
            公式 Twitter (@shogi_kento) <Icon name="external-link" mx="2px" />
          </Link>{" "}
          もしくはサポートメール (kentoshogi.staff@gmail.com)
          までご連絡ください。
        </Text>
      </Box>
    </Box>
  );
};
