import { useToast } from "@chakra-ui/core";
import React, { useEffect } from "react";
import { isUpdateFinished } from "../update";

export const UpdateNotification: React.FC = () => {
  const toast = useToast();

  useEffect(() => {
    const version = isUpdateFinished();
    if (!version) return;

    toast({
      title: "アップデート完了",
      description: `更新内容はリリースノートをご覧ください。 (version ${version})`,
      duration: 9000,
      status: "info",
      isClosable: true
    });
  }, [toast]);
  return <></>;
};
