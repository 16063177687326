import {
  Box,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton
} from "@chakra-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state";
import { Dispatch } from "../../store.js";

export const WarsError: React.FC = () => {
  const isOpen = useSelector<State, boolean>(state => state.showWarsError);
  const dispatch = useDispatch<Dispatch>();
  const onClose = () => dispatch({ type: "warsErrorModal", dir: "close" });
  const initialRef = React.useRef();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={initialRef as any}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" ref={initialRef}>
        <ModalHeader textAlign="center">
          <Icon name="info-outline" size="18px" mb="1" /> Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="4">
          <Box fontSize="sm">
            KENTO への将棋ウォーズの棋譜読み込みは{" "}
            <Link
              color="teal.500"
              href="http://tk2-221-20341.vs.sakura.ne.jp/shogi/w"
            >
              将棋ウォーズ棋譜検索
            </Link>{" "}
            (外部サービス) をご利用ください
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
