import { Action } from "../actions";
import { State } from "../state";

export function moveInputReducer(
  { moveInput }: State,
  action: Action
): State["moveInput"] {
  switch (action.type) {
    case "inputMoveFromHand":
      return {
        status: "waitingTo",
        from: { type: "fromHand", piece: action.piece },
        legalMoves: action.legalMoves
      };
    case "inputMoveFromCell":
      return {
        status: "waitingTo",
        from: { type: "fromCell", piece: action.piece, from: action.cell },
        legalMoves: action.legalMoves
      };
    case "inputMoveTo":
      if (
        moveInput.status !== "waitingTo" ||
        moveInput.from.type !== "fromCell"
      )
        return moveInput;
      return {
        status: "waitingPromote",
        from: moveInput.from,
        to: action.cell
      };
    case "inputMoveAlmostComplete":
      return {
        status: "waitingApplyingBranch",
        move: action.move
      };
  }

  if (ResetInputActions.includes(action.type)) {
    return { status: "waitingFrom" };
  }

  return moveInput;
}

const ResetInputActions: Array<Action["type"]> = [
  "inputMoveComplete",
  "clearMoveInput",
  "setGameTurn",
  "forward",
  "backward",
  "clearBranch",
  "disableBranch",
  "doPass",
  "applyPv"
];
