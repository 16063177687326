import { EvalResultMulti } from "./evalMulti";

export type PerfTypeMulti = keyof typeof PerfMulti;

export function getPriority(perfType: string): number {
  if (Object.keys(PerfMulti).includes(perfType)) {
    return PerfMulti[perfType as PerfTypeMulti].priority;
  }
  return 100;
}

export function getRealPriority(evalResult: EvalResultMulti): number {
  if (Object.keys(PerfMulti).includes(evalResult.perf)) {
    return getPriority(evalResult.perf) / evalResult.reqMultiPv;
  }
  if ((evalResult.perf as string) === "variable") {
    const nodes =
      Math.max(
        0,
        ...evalResult.score.multiScores.map(s => s.nodes || 0),
        evalResult.type === "completed"
          ? evalResult.score.bestScore.nodes || 0
          : 0
      ) || baseNodes * 10000;
    const realNodes = nodes / evalResult.reqMultiPv;
    return (realNodes / baseNodes) * 100;
  }
  return 0;
}

export function isPro(evalResult: EvalResultMulti): boolean {
  return getRealPriority(evalResult) > 300;
}

// 3g10s の期待ノード数
const baseNodes = 70_0000 * 10;

// Priority: 3g10s を 100 とした期待ノード数の比率
export const PerfMulti = {
  "3g10s": {
    priority: 100,
    memoryMb: 3008,
    avgTimeMs: 10000
  },
  "3g6s": {
    priority: 60,
    memoryMb: 3008,
    avgTimeMs: 6000
  },
  "3g2s": {
    priority: 20,
    memoryMb: 3008,
    avgTimeMs: 2000
  },
  "3g1s": {
    priority: 10,
    memoryMb: 3008,
    avgTimeMs: 1000
  }
};
