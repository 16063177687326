import Axios from "axios";
import { GetUserDataResult, UserData } from "./sharedTypes";

export async function getUserData(
  user: firebase.User
): Promise<Partial<UserData>> {
  const idToken = await user.getIdToken();
  const result = (
    await Axios.get(
      `${process.env.REACT_APP_ENDPOINT_SERVERLESS}/getUserData`,
      {
        headers: {
          Authorization: `token ${idToken}`
        }
      }
    )
  ).data as GetUserDataResult;

  if (result.type === "success") {
    return result.userData;
  }
  return {};
}

export async function saveUserData(
  user: firebase.User,
  userData: UserData
): Promise<void> {
  const idToken = await user.getIdToken();
  await Axios.post(
    `${process.env.REACT_APP_ENDPOINT_SERVERLESS}/userData`,
    JSON.stringify(userData),
    {
      headers: {
        Authorization: `token ${idToken}`
      }
    }
  );
}
