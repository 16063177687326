import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state";
import { Dispatch } from "../../store.js";

export const ErrorModal: React.FC = () => {
  const message = useSelector<State, string | null>(
    state => state.errorMessage
  );
  const isOpen = message !== null;
  const dispatch = useDispatch<Dispatch>();
  const onClose = () => dispatch({ type: "errorMessage", message: null });
  const initialRef = React.useRef();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={initialRef as any}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" ref={initialRef}>
        <ModalHeader textAlign="center">
          <Icon name="warning" size="18px" mb="1" /> エラー
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="4">
          <Box fontSize="sm">{message}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
