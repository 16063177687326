import Axios from "axios";
import { Array, Literal, Number, Record, String } from "runtypes";
import { GameListEntry, KifuApiEntry, LoadingEntry } from "../state/kifuApi";
import { getUserDetail } from "../state/user";
import { ThunkAction } from "../store";
import { updateUserData } from "./user";

export type KifuApiActions =
  | KifuApiLoadStartAction
  | KifuApiLoadSuccessAction
  | KifuApiLoadFailedAction
  | RemoveKifuApiAction;

export type KifuApiLoadStartAction = {
  type: "kifuApiLoadStart";
  url: string;
};

export type KifuApiLoadSuccessAction = {
  type: "kifuApiLoadSuccess";
  url: string;
  apiName: string;
  gameList: GameListEntry[];
};

export type KifuApiLoadFailedAction = {
  type: "kifuApiLoadFailed";
  url: string;
  message?: string;
};

export type RemoveKifuApiAction = {
  type: "removeKifuApi";
  url: string;
};

export function addKifuApi(url: string): ThunkAction {
  return async (dispatch, getState) => {
    const { kifuApi } = getState();
    if (kifuApi.some(e => e.url === url)) return;

    dispatch({ type: "kifuApiLoadStart", url });

    try {
      const data = await loadKifuApi(url);
      const result = ApiResponse.validate(data);

      if (!result.success) {
        dispatch({
          type: "kifuApiLoadFailed",
          url,
          message: result.message
        });
      } else {
        const { value } = result;
        dispatch({
          type: "kifuApiLoadSuccess",
          url,
          apiName: value.api_name,
          gameList: value.game_list.map(g => ({
            tag: g.tag,
            kifuUrl: g.kifu_url,
            displayName: g.display_name,
            displayTimestamp: g.display_timestamp
          }))
        });
      }
    } catch (error) {
      dispatch({ type: "kifuApiLoadFailed", url, message: error.toString() });
    }

    dispatch(syncKifuApiToUserData());
  };
}

export function removeKifuApi(url: string): ThunkAction {
  return async dispatch => {
    dispatch({ type: "removeKifuApi", url });
    dispatch(syncKifuApiToUserData());
  };
}

export function reloadKifuApi(): ThunkAction {
  return async (dispatch, getState) => {
    const { kifuApi } = getState();

    await Promise.all(
      kifuApi.map(async ({ url, apiName, gameList }) => {
        dispatch({ type: "kifuApiLoadStart", url });

        try {
          const data = await loadKifuApi(url);
          const result = ApiResponse.validate(data);

          if (!result.success) {
            dispatch({
              type: "kifuApiLoadFailed",
              url,
              apiName,
              gameList,
              message: result.message
            });
          } else {
            const { value } = result;
            dispatch({
              type: "kifuApiLoadSuccess",
              url,
              apiName: value.api_name,
              gameList: value.game_list.map(g => ({
                tag: g.tag,
                kifuUrl: g.kifu_url,
                displayName: g.display_name,
                displayTimestamp: g.display_timestamp
              }))
            });
          }
        } catch (error) {
          dispatch({
            type: "kifuApiLoadFailed",
            url,
            message: error.toString()
          });
        }
      })
    );

    dispatch(syncKifuApiToUserData());
  };
}

export function syncKifuApiToUserData(): ThunkAction {
  return async (dispatch, getState) => {
    const { kifuApi, user } = getState();
    const userDetail = getUserDetail(user);
    if (!userDetail) return;
    const { userData } = userDetail;
    dispatch(
      updateUserData({
        ...userData,
        kifuApi: kifuApi.filter(e => e.type !== "loading") as Exclude<
          KifuApiEntry,
          LoadingEntry
        >[]
      })
    );
  };
}

const ApiGameListEntry = Record({
  tag: Array(String),
  kifu_url: String,
  display_name: String,
  display_timestamp: Number
});

const ApiResponse = Record({
  api_version: Literal("2020-02-02"),
  api_name: String,
  game_list: Array(ApiGameListEntry)
});

async function loadKifuApi(url: string): Promise<string> {
  return (
    await Axios.get(
      `${process.env.REACT_APP_ENDPOINT_PROXY}?uri=${encodeURIComponent(url)}`
    )
  ).data;
}
