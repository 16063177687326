import React from "react";
import { Heading } from "@chakra-ui/core";
import { useDispatch } from "react-redux";
import { Dispatch } from "../store";

export const Logo: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  return (
    <Heading w="100%">
      <span
        style={{ cursor: "pointer", userSelect: "none" }}
        onClick={() => {
          dispatch({ type: "openAboutModal" });
        }}
      >
        ☗ KENTO
      </span>
    </Heading>
  );
};
