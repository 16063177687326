import {
  Box,
  BoxProps,
  Button,
  Divider,
  Heading,
  IconButton,
  Link,
  Text,
  useToast,
} from "@chakra-ui/core";
import * as Sentry from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { analytics } from "../../firebase";
import { createSession } from "../../payment";
import { UserSubscription } from "../../sharedTypes";
import { State } from "../../state";
import { Dispatch } from "../../store";
import { redirectToCheckout } from "../../stripe";
import { Container } from "../Container";
import { Loading } from "../main/Loading";
import { LoginModal } from "../main/LoginModal";
import { QuantityModal } from "./QuantityModal";

export const Supporter: React.FC<BoxProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector<State, State["user"]>((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    analytics.logEvent("page_view_supporter");
    window.document.title = "KENTO | Pro プラン";
  }, []);

  const isContentsLoaded =
    user.type === "loggedIn" || user.type === "loggedOut";
  const handleClickMultipleRegister = () => {
    analytics.logEvent("button_click_register_supporter");
    if (user.type === "loggedIn") {
      setIsModalOpen(true);
      return;
    }
    if (user.type === "loggedOut") {
      dispatch({ type: "loginModal", dir: "open" });
    }
  };

  const toast = useToast();
  const startSession = async () => {
    if (user.type === "loggedOut") {
      dispatch({ type: "loginModal", dir: "open" });
      return;
    }
    setIsSubmitting(true);
    const sessionId = await createSession(user, 1);
    if (!sessionId) {
      setIsSubmitting(false);
      return;
    }
    const error = await redirectToCheckout(sessionId);
    toast({
      title: "エラー",
      description:
        "リダイレクトに失敗しました。時間を開けて再度お試しください。",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    Sentry.captureException(error);
  };

  return (
    <Container>
      <Box display="flex" alignItems="flex-end">
        <Heading w="100%" onClick={() => history.push("/")}>
          <span style={{ cursor: "pointer", userSelect: "none" }}>☗ KENTO</span>
        </Heading>
        <IconButton
          aria-label="close"
          icon="close"
          variant="ghost"
          onClick={() => history.push("/")}
        />
      </Box>
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : null}
      <Box
        opacity={isContentsLoaded ? 1 : 0}
        transition="opacity .5s"
        // fontSize="sm"
        mt="8"
        mb="8"
        mx="2"
        {...props}
      >
        {user.type === "loggedIn" ? (
          <QuantityModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            user={user}
          />
        ) : (
          <LoginModal />
        )}
        <Payment
          userSubscription={
            user.type === "loggedIn" ? user.userSubscription : undefined
          }
          onClickMultipleRegister={handleClickMultipleRegister}
          onClickRegister={startSession}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Container>
  );
};

export const Payment: React.FC<{
  userSubscription?: UserSubscription;
  onClickRegister: () => void;
  onClickMultipleRegister: () => void;
  isSubmitting: boolean;
}> = ({
  userSubscription,
  onClickRegister,
  onClickMultipleRegister,
  isSubmitting,
}) => {
  const isSubmitButtonDisabled = userSubscription?.type === "active";
  return (
    <Box textAlign="center" fontSize="sm" color="gray.600">
      <Box>
        <Heading size="md" color="teal.500" mt="4" textAlign="center">
          KENTO Pro
        </Heading>
        <Divider />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          my="4"
        >
          <Text fontSize="md" color="gray.500" mb="8px">
            月額
          </Text>
          <Text fontSize="4xl" fontWeight="semibold" mx="4px">
            500
          </Text>
          <Text fontSize="md" color="gray.500" mb="8px">
            円
          </Text>
        </Box>
        <Text textAlign="center">
          KENTO Pro に登録して多くの特典機能をご利用ください。
        </Text>
        <Box>
          <Button
            isDisabled={isSubmitButtonDisabled}
            variantColor="pink"
            size="lg"
            mt="4"
            onClick={onClickRegister}
            isLoading={isSubmitting}
          >
            {userSubscription?.type === "active"
              ? "登録済み"
              : "KENTO Pro に登録する"}
          </Button>
        </Box>
      </Box>
      <Heading size="md" color="teal.500" mt="12">
        KENTO Pro 特典
      </Heading>
      <Divider />
      <Box my="8">
        <Heading size="md" mb="2">
          ① 複数候補手の表示
        </Heading>
        <Text>
          検討時に最善手だけでなく複数の候補手を計算・表示できるようになります。
        </Text>
      </Box>
      <Box my="8">
        <Heading size="md" mb="2">
          ② 利用制限の緩和
        </Heading>
        <Text>
          解析棋譜数などの利用制限について、無制限または大幅に緩和された条件で利用できます。
        </Text>
      </Box>
      <Box my="8">
        <Heading size="md" mb="2">
          ③ 広告非表示、新機能の先行リリース
        </Heading>
        <Text>
          Pro 限定の機能や、新機能の先行リリースなどを随時追加していきます。
        </Text>
        <Text fontSize="sm" color="gray.500" mt="8">
          ※特典内容・利用制限範囲は予告なく変更される場合があります。
        </Text>
      </Box>
      <Heading size="sm" color="teal.500" mt="16">
        ☗ KENTO について
      </Heading>
      <Divider />
      <Box my="4" maxWidth="780px" mx="auto" textAlign="left">
        <Text>
          KENTO は{" "}
          <span style={{ fontWeight: "bold" }}>
            「最新鋭の将棋 AI を、だれでも、どこでも」
          </span>
          をコンセプトに開発されたサービスです。 クラウド上の高性能マシンで将棋
          AI
          を動かしており、運営にはサーバー代をはじめとした多くの費用がかかっています。
        </Text>
      </Box>
      <Box mt="16">
        <Link href="/specified" fontSize="xs" color="gray.400" isExternal>
          特定商取引法に基づく表示
        </Link>
      </Box>
    </Box>
  );
};
