import { State } from "../state";
import { Action } from "../actions";

export function userReducer(
  state: State["user"],
  action: Action
): State["user"] {
  switch (action.type) {
    case "userLogin":
      return {
        type: "loggedIn",
        firebaseUser: action.user,
        userSubscription: action.userSubscription,
        userData: action.userData
      };
    case "userLoginByCache":
      return {
        type: "loggedInCache",
        userSubscription: action.userSubscription,
        userData: action.userData
      };
    case "userLogout":
      return { type: "loggedOut" };
    case "reloadUser":
      return { type: "loading" };
    case "updateUserData":
      if (state.type !== "loggedIn") return state;
      return {
        ...state,
        userData: action.userData
      };
  }
  return state;
}
