import { UserSubscription, UserData } from "../sharedTypes";
import { PerfTypeMulti } from "../perfMulti";
import { GameState } from "./game";

export type User = LoadingUser | LoggedInUser | LoggedOutUser | CacheUser;

export type LoadingUser = {
  type: "loading";
};

export type UserDetail = {
  userSubscription: UserSubscription;
  userData: UserData;
};

export type LoggedInUser = {
  type: "loggedIn";
  firebaseUser: firebase.User;
} & UserDetail;

export type LoggedOutUser = {
  type: "loggedOut";
};

export type CacheUser = {
  type: "loggedInCache";
} & UserDetail;

export function getUserDetail(
  user: User
): { userSubscription: UserSubscription; userData: UserData } | null {
  if (user.type === "loggedInCache" || user.type === "loggedIn") {
    return user;
  }
  return null;
}

export type EvalSetting = {
  evalMultiPv: number;
  evalGamePerf: PerfTypeMulti;
  evalGameChunkSize: number;
  evalSinglePerf: PerfTypeMulti;
  shadowBanned: boolean;
};

export function getEvalSetting(user: User, gameState: GameState): EvalSetting {
  const userDetail = getUserDetail(user);
  if (!userDetail) {
    return {
      evalMultiPv: 1,
      evalGamePerf: "3g1s",
      evalGameChunkSize: 6,
      evalSinglePerf: "3g1s",
      shadowBanned: false,
    };
  }
  if (userDetail.userSubscription.type === "active") {
    const evalMultiPv = userDetail.userData.multiPv;
    const evalSinglePerf = evalMultiPv > 1 ? "3g10s" : "3g6s";
    return {
      evalMultiPv,
      evalGamePerf: "3g2s",
      evalGameChunkSize: 3,
      evalSinglePerf,
      shadowBanned: false,
    };
  }
  return {
    evalMultiPv: 1,
    evalGamePerf: "3g1s",
    evalGameChunkSize: 6,
    evalSinglePerf: "3g6s",
    shadowBanned: userDetail.userData.sb,
  };
}

type UserPlan = "non_member" | "free" | "supporter";
export function getUserPlan(user: User): UserPlan {
  const userDetail = getUserDetail(user);
  if (userDetail) {
    if (userDetail.userSubscription.type === "active") return "supporter";
    return "free";
  }
  return "non_member";
}
