import { Box, BoxProps, useTheme } from "@chakra-ui/core";
import { isEqual, range } from "lodash-es";
import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { useScoreChartState } from "./scoreChartUtil";

export const ScoreChartSlim: React.FC<BoxProps> = (props: BoxProps) => {
  const { turn, canUpgrade, scores, onClickTurn } = useScoreChartState();
  // if (window.screen.width > 768) return null;

  return (
    <Box bg="gray.600" shadow="md" rounded="md" {...props}>
      <style>
        .referenceLines{" "}
        {`{
          display:none;
        }`}
        {`.referenceLines.turn${turn} { display:inline }`}
      </style>
      <ChartBody
        scores={scores}
        canUpgrade={canUpgrade}
        onClickTurn={onClickTurn}
      />
    </Box>
  );
};

type ChartBodyProps = {
  scores: { chartValue: number }[];
  canUpgrade: Array<boolean>;
  onClickTurn: (turn: number) => void;
};
const ChartBody: React.FC<ChartBodyProps> = React.memo(
  ({ scores, canUpgrade, onClickTurn }) => {
    const fillColor = useTheme().colors.gray[100];
    const fillColorHighPerf = useTheme().colors.gray[100];
    const fillColorLowPerf = useTheme().colors.gray[200];
    const turns = scores.length;
    const maxX = Math.max(30, Math.ceil(turns / 30) * 30);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={scores}
          onClick={e => {
            if (e && typeof e.activeLabel === "number")
              onClickTurn(e.activeLabel);
          }}
          // style={{ marginRight: "-20px", paddingBottom: "0" }}
          margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <ReferenceLine y={0} />
          {range(maxX + 1).map(idx => (
            <ReferenceLine
              x={idx}
              key={idx}
              className={`referenceLines turn${idx}`}
            />
          ))}
          <XAxis dataKey="turn" type="number" domain={[0, maxX]} hide={true} />
          <YAxis
            dataKey="chartValue"
            type="number"
            domain={[-1, 1]}
            hide={true}
          />
          <Bar dataKey="chartValue" isAnimationActive={false} fill={fillColor}>
            {canUpgrade.map((flg, idx) => (
              <Cell
                key={idx}
                fill={flg ? fillColorLowPerf : fillColorHighPerf}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  },
  (l, r) => isEqual(l.scores, r.scores)
);
