import {
  Box,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Icon,
} from "@chakra-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { version, releaseDate } from "../../../package.json";
import { State } from "../../state";
import { Dispatch } from "../../store.js";

export const About: React.FC = () => {
  const isOpen = useSelector<State, boolean>(
    (state) => state.about.isModalOpen
  );
  const dispatch = useDispatch<Dispatch>();
  const onClose = () => dispatch({ type: "closeAboutModal" });
  const initialRef = React.useRef();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={initialRef as any}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" ref={initialRef}>
        <ModalHeader textAlign="center">☗ KENTO</ModalHeader>
        <ModalBody mb="4">
          <Box fontSize="sm">
            <List>
              <ListItem>
                Version: {version} ({releaseDate})
              </ListItem>
              <ListItem>
                Engine:{" "}
                <Link
                  href="https://github.com/yaneurao/YaneuraOu/tree/v6.00"
                  color="teal.500"
                  isExternal
                >
                  YO6.00
                </Link>
                {" + "}
                <Link
                  href="https://twitter.com/tayayan_ts/status/1416621532164497411"
                  color="teal.500"
                  isExternal
                >
                  水匠4
                </Link>
                {" + "}
                <Link
                  href="https://github.com/yaneurao/YaneuraOu/releases/tag/BOOK-700T-Shock"
                  color="teal.500"
                  isExternal
                >
                  700T-shock-book
                </Link>
              </ListItem>
              <ListItem>
                Environment: AWS Lambda (1024 ~ 3008 MB Memory)
              </ListItem>
              <ListItem>
                Contact:{" "}
                <Link
                  href="https://twitter.com/shogi_kento"
                  color="teal.500"
                  isExternal
                >
                  @shogi_kento
                </Link>
              </ListItem>
            </List>
            <Box mt="4" textAlign="center">
              <Link color="teal.500" href="/agreement" isExternal>
                利用規約とプライバシーポリシー{" "}
                <Icon name="external-link" mx="2px" />
              </Link>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
