import { Box } from "@chakra-ui/core";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadKifUrl, loadSavedGame, startNarabeGame } from "../../actions/game";
import { State } from "../../state";
import { GameInitSequence } from "../../state/gameInitSequence";
import { Dispatch } from "../../store";
import { Container } from "../Container";
import { QueryParams } from "./AddressBar";
import { Board } from "./Board";
import { GameInitializeSequence } from "./GameInitializeSequence";
import { KifuLoader } from "./KifuLoader";
import { Loading } from "./Loading";
import { ScoreMoveMulti } from "./ScoreMoveMulti";
import { ScoreChartSlim } from "./ScoreChartSlim";

export const ScreenShot: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    initializeApp(dispatch);
    window.document.title = "KENTO";
  }, [dispatch]);

  const gameInitSequence = useSelector<State, State["gameInitSequence"]>(
    state => state.gameInitSequence
  );

  const contentVisibleTypes: GameInitSequence["type"][] = [
    "scoreCacheLoaded",
    "calcGameScoreStarted",
    "finished"
  ];
  const isContentsLoaded = contentVisibleTypes.includes(gameInitSequence.type);
  return (
    <Container py="0.40rem">
      <GameInitializeSequence />
      {!isContentsLoaded ? (
        <Loading position="absolute" top="0" left="0" w="100vw" h="95vh" />
      ) : (
        <Box display="none">ready-to-capture</Box>
      )}
      <Box opacity={isContentsLoaded ? 1 : 0} transition="opacity .5s">
        <KifuLoader />
        <Box
          display="flex"
          position="relative"
          mt="0"
          justifyContent={{ base: "flex-start", lg: "center" }}
        >
          <Box display={{ base: "none", lg: "block" }} w="280px" />
          <Box ml={{ base: 0, md: "5rem", lg: 0 }}>
            <Board mx="auto" />
          </Box>
          <ScoreMoveMulti
            display={{ base: "none", md: "block" }}
            mx={{ base: 4, lg: 8 }}
            mt={4}
            w={{ base: "100%", lg: "320px" }}
            position="relative"
            isScreenShot={true}
          />
        </Box>
        <ScoreChartSlim h="60px" mt="2" w="100%" display="flex" />
      </Box>
    </Container>
  );
};

type GameInitialize =
  | { type: "loadKifUrl"; url: string; turn: number }
  | { type: "loadWarsUrl"; url: string; turn: number }
  | { type: "loadSavedGame"; url: string; turn: number }
  | { type: "emptyKif"; turn: number; moves: string[]; initpos?: string };

type Branch = {
  branchMoves?: string[];
  branchFrom?: number;
};

function initializeApp(dispatch: Dispatch) {
  const props = queryParamsToAppProps(
    queryString.parse(window.location.search)
  );
  const { branchFrom, branchMoves } = props;
  const branch =
    branchMoves !== undefined && branchFrom !== undefined
      ? { branchMoves, branchFrom }
      : undefined;

  if (props.type === "loadKifUrl") {
    dispatch(loadKifUrl(props.url, props.turn, branch));
    return;
  }
  if (props.type === "loadWarsUrl") {
    dispatch({ type: "warsErrorModal", dir: "open" });
    dispatch(startNarabeGame(props.turn, undefined, [], branch));
  }
  if (props.type === "loadSavedGame") {
    dispatch(loadSavedGame(props.url, props.turn, branch));
  }
  if (props.type === "emptyKif") {
    dispatch(startNarabeGame(props.turn, props.initpos, props.moves, branch));
  }
}

type AppInitialProps = GameInitialize & Branch;

function queryParamsToAppProps(params: QueryParams): AppInitialProps {
  const branchMoves = params.branch
    ? params.branch.split(".").flatMap(l => l.split(","))
    : undefined;
  const branchFrom = params.branchFrom
    ? Number.parseInt(params.branchFrom, 10)
    : undefined;
  let turn = Number.parseInt(window.location.hash.slice(1), 10);
  if (Number.isNaN(turn)) turn = 9999;
  if (
    params.kifu &&
    (params.kifu.startsWith("https://kif-pona.heroz.jp/games/") ||
      params.kifu.startsWith("https://shogiwars.heroz.jp/games/"))
  ) {
    return {
      type: "loadWarsUrl",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom
    };
  }
  if (
    params.kifu &&
    (params.kifu.endsWith(".kif") || params.kifu.endsWith(".csa"))
  ) {
    return {
      type: "loadKifUrl",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom
    };
  }
  if (params.kifu && params.kifu.startsWith("saved")) {
    return {
      type: "loadSavedGame",
      url: params.kifu,
      turn,
      branchMoves,
      branchFrom
    };
  }
  const moves = params.moves
    ? params.moves.split(".").flatMap(l => l.split(","))
    : [];
  return {
    type: "emptyKif",
    turn,
    moves,
    branchMoves,
    branchFrom,
    initpos: params.initpos
  };
}
