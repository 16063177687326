import {
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  IconButton,
  Spinner,
  Text
} from "@chakra-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addKifuApi, removeKifuApi } from "../../actions/kifuApi";
import { State } from "../../state";
import { KifuApiEntry } from "../../state/kifuApi";
import { Dispatch } from "../../store";

export const KifuAPI: React.FC = () => {
  const kifuApiEntries = useSelector<State, KifuApiEntry[]>(
    state => state.kifuApi
  );
  return (
    <>
      <Heading size="md" mt="8">
        棋譜ソース
      </Heading>
      <Divider />
      <Box pl="4">
        {kifuApiEntries.map((entry, idx) => (
          <APIEntry kifuApi={entry} key={idx} />
        ))}
        <Box mt="2">
          <AddQuestEntry />
          <AddEntry />
        </Box>
      </Box>
    </>
  );
};

const APIEntry: React.FC<{ kifuApi: KifuApiEntry }> = ({ kifuApi }) => {
  const dispatch = useDispatch<Dispatch>();
  const deleteApi = () => {
    if (!window.confirm("削除しますか?")) return;
    dispatch(removeKifuApi(kifuApi.url));
  };
  return (
    <Box display="flex" alignItems="flex-end">
      {kifuApi.type === "loading" ? (
        <Spinner size="sm" speed="0.65s" mb="8px" mr="1" />
      ) : null}
      <Text mb="6px" mr="2">
        {kifuApi.type === "valid" ? (
          <Icon name="check-circle" color="teal.500" mb="3px" mr="1" />
        ) : null}
        {kifuApi.type === "invalid" ? (
          <Icon name="warning" color="red.500" mb="3px" mr="1" />
        ) : null}
        {kifuApi.apiName || kifuApi.url}
      </Text>
      <IconButton
        aria-label="removeApi"
        icon="delete"
        size="sm"
        variant="ghost"
        onClick={deleteApi}
      />
    </Box>
  );
};

const AddEntry: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const addEntry = () => {
    const url = prompt(
      "API URL を入力してください。(URL にはパスワード等の秘密情報を絶対に含めないでください。)"
    );
    if (!url) return;
    dispatch(addKifuApi(url));
  };
  return (
    <Button size="xs" variantColor="teal" onClick={addEntry}>
      API 追加
    </Button>
  );
};

const AddQuestEntry: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const addEntry = () => {
    const questId = prompt("将棋クエスト ID を入力してください。");
    if (!questId) return;
    dispatch(
      addKifuApi(
        `https://jisn7d4exc.execute-api.ap-northeast-1.amazonaws.com/prod/quest/games?userId=${questId}`
      )
    );
  };
  return (
    <Button size="xs" variantColor="teal" mr="1" onClick={addEntry}>
      将棋クエスト ID 追加
    </Button>
  );
};
