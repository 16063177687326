import { combineEpics, Epic, ofType } from "redux-observable";
import { bufferTime, filter, map } from "rxjs/operators";
import { Action } from "../actions";
import {
  ReceiveTempEvalResultMultiAction,
  ReceiveCalcGameEvalResultAction
} from "../actions/gameScore";
import { State } from "../state";

const bufferedEvalResultMultiEpic: Epic<Action, Action, State> = action$ => {
  return action$.pipe(
    ofType("receiveTempEvalResultMulti"),
    bufferTime(300),
    filter(actions => actions.length > 0),
    map(bufferedActions => {
      const evalResults = bufferedActions.map(
        action => (action as ReceiveTempEvalResultMultiAction).evalResult
      );
      return { type: "emitBufferedTempEvalResultsMulti", evalResults };
    })
  );
};

const bufferedCalcGameEvalResultEpic: Epic<Action, Action, State> = action$ => {
  return action$.pipe(
    ofType("receiveCalcGameEvalResult"),
    bufferTime(300),
    filter(actions => actions.length > 0),
    map(bufferedActions => {
      const evalResults = bufferedActions.map(
        action => (action as ReceiveCalcGameEvalResultAction).evalResult
      );
      return { type: "emitBufferedCalcGameEvalResults", evalResults };
    })
  );
};

export const rootEpic = combineEpics(
  bufferedEvalResultMultiEpic,
  bufferedCalcGameEvalResultEpic
);
