import { Action } from "../actions";
import { State } from "../state";

export function gameInitSequenceReducer(
  state: State,
  action: Action
): State["gameInitSequence"] {
  const { gameInitSequence } = state;
  switch (action.type) {
    case "resetGameInitSequence":
    case "loadGameStart":
      return { type: "initial" };
    case "narabeGame":
    case "narabeGameParsed":
    case "loadGameSuccess":
      return { type: "kifuLoaded" };
    case "receiveCacheGameScores":
      if (action.isCompleted) {
        return { type: "finished" };
      }
      return { type: "scoreCacheLoaded" };
    case "askCalcGameScore":
      return { type: "askingCalcGameScores", usageLimit: action.usageLimit };
    case "calcGameScoresStart":
      return { type: "calcGameScoreStarted" };
    case "calcGameScoresFinished":
      return { type: "finished" };
    default:
      return gameInitSequence;
  }
}
