type ResponsiveValue<T> = {
  base: T;
  md: T;
};

export function mapResponsiveValue<T>(
  v: ResponsiveValue<T>,
  f: (v: T) => T
): ResponsiveValue<T> {
  return {
    base: f(v.base),
    md: f(v.md)
  };
}
