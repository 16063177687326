import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isSupporterTestingTarget } from "../abTesting";
import { firstLoginNotificationAction } from "../actions/flashMessage";
import { updatePartialUserData } from "../actions/user";
import { analytics } from "../firebase";
import { State } from "../state";
import { Dispatch } from "../store";
import { getTotalUsage } from "../usage";

export const UserNotification: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const user = useSelector<State, State["user"]>((state) => state.user);
  const isTarget = isSupporterTestingTarget(user);
  const firebaseUser = user.type === "loggedIn" ? user.firebaseUser : null;
  const lastShowedAt =
    user.type === "loggedIn"
      ? user.userData.forceRedirectSupporterTimestamp || 0
      : 0;

  useEffect(() => {
    if (!isTarget || !firebaseUser) return;

    shouldRedirectSupporter(firebaseUser, lastShowedAt).then((result) => {
      if (!result) return;
      dispatch(
        updatePartialUserData({
          forceRedirectSupporterTimestamp: moment().unix(),
        })
      );
      analytics.logEvent("force_redirect_supporter");
      history.push("/supporter");
    });
  }, [firebaseUser, isTarget, lastShowedAt, history, dispatch]);

  const isFirstLoginNotificationRead =
    user.type === "loggedIn"
      ? user.userData.notification.isNotificationRead["firstLogin"]
      : false;
  useEffect(() => {
    if (user.type !== "loggedIn" || isFirstLoginNotificationRead) return;

    dispatch(
      updatePartialUserData({
        notification: {
          isNotificationRead: {
            ...user.userData.notification.isNotificationRead,
            firstLogin: true,
          },
        },
      })
    );
    dispatch(firstLoginNotificationAction());
  }, [isFirstLoginNotificationRead, dispatch, user]);

  return <></>;
};

async function shouldRedirectSupporter(
  user: firebase.User,
  lastShowedAt: number
): Promise<boolean> {
  const currentTimestamp = moment().unix();
  const usage = await getTotalUsage(user);
  if (usage.type !== "success") return false;
  const hasEnoughUsage = usage.usage.some(
    (u) =>
      (u.action === "kifu_analysis" && u.totalUsage >= 3) ||
      (u.action === "single_move_analysis" && u.totalUsage >= 20)
  );

  return hasEnoughUsage && currentTimestamp - lastShowedAt > 60 * 60 * 24 * 7;
}
