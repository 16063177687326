export type Setting = {
  ai: AISetting;
  evalBranchImmediately: boolean;
  clickBoardToMove: boolean;
  rotateBoard: boolean;
};

export type AISetting = {
  debug: {
    useCache: boolean;
  };
};

// Single
export function getInitialSetting(): Setting {
  return {
    ai: {
      debug: {
        useCache: true
      }
    },
    evalBranchImmediately: false,
    clickBoardToMove: false,
    rotateBoard: false
  };
}
