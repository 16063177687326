import {
  Box,
  Button,
  Flex,
  FlexProps,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay
} from "@chakra-ui/core";
import { cloneDeep } from "lodash-es";
import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Cell, Piece as ShogiPiece, Position } from "shogi-ts";
import {
  handleCellClick,
  handleHandClick,
  handleInputApplyingBranch,
  handleInputPromote
} from "../../../actions/moveInput";
import { State } from "../../../state";
import { MoveInput } from "../../../state/moveInput";
import { Dispatch } from "../../../store";
import { HandBoard } from "./HandBoard";
import { Piece } from "./Piece";

export const BasePieceHeightPx = { base: "calc(100vw * 2 / 21)", md: "40px" };
export const BasePieceWidthPx = { base: "calc(100vw / 12)", md: "34px" };

export const BorderPx = 2;

function cellToIdx({ file, rank }: Cell, isRotate: boolean): number {
  const idx = (rank - 1) * 9 + 9 - file;
  if (isRotate) return 80 - idx;
  return idx;
}

function rotatePosition(position: Position): Position {
  const cells = cloneDeep(position.cells).map(row =>
    row.map(p =>
      p ? { ...p, side: (p.side === "b" ? "w" : "b") as "b" | "w" } : null
    )
  );
  cells.forEach(row => row.reverse());
  cells.reverse();
  const side = position.side === "b" ? "w" : "b";
  const hand = { b: position.hand.w, w: position.hand.b };
  return { cells, side, hand };
}

export type BoardProps = FlexProps;
export const Board: React.FC<BoardProps> = (props: BoardProps) => {
  const dispatch = useDispatch<Dispatch>();

  const onClickCell = (cell: Cell) => {
    dispatch(handleCellClick(cell));
  };
  const onClickHand = (piece: ShogiPiece, side: "b" | "w") => {
    dispatch(handleHandClick(piece, side));
  };
  const handlePromoteClick = (promote: boolean) => {
    dispatch(handleInputPromote(promote));
  };

  const handleApplyingBranchClick = (branch: "branch" | "main") => {
    dispatch(handleInputApplyingBranch(branch));
  };

  useSelector<State, string>(state => state.gameState.currentSfen);
  const setting = useSelector<State, State["setting"]>(state => state.setting);
  const origBoardPosition = useStore<State>().getState().gameState
    .currentPosition;
  const boardPosition = setting.rotateBoard
    ? rotatePosition(origBoardPosition)
    : origBoardPosition;

  const moveInput = useSelector<State, State["moveInput"]>(
    state => state.moveInput
  );

  const highlightIdices: number[] = [];
  if (moveInput.status === "waitingTo" && moveInput.from.type === "fromCell") {
    highlightIdices.push(cellToIdx(moveInput.from.from, setting.rotateBoard));
  }
  if (origBoardPosition.lastMove) {
    highlightIdices.push(
      cellToIdx(origBoardPosition.lastMove.to, setting.rotateBoard)
    );
    if (origBoardPosition.lastMove.type === "move_from_cell") {
      highlightIdices.push(
        cellToIdx(origBoardPosition.lastMove.from, setting.rotateBoard)
      );
    }
  }
  const highlightHandPiece =
    moveInput.status === "waitingTo" && moveInput.from.type === "fromHand"
      ? [moveInput.from.piece]
      : [];

  const isEnableMoveInput = useSelector<State, boolean>(
    ({ gameState, setting }) => !setting.clickBoardToMove || !!gameState.branch
  );

  const onClickBoard = (e: any) => {
    if (isEnableMoveInput) return;
    const elem = e.currentTarget;
    const { clientWidth } = elem;
    const offsetLeft = elem.getBoundingClientRect().x;
    const isLeft = e.clientX - offsetLeft < clientWidth / 2.0;
    if (isLeft) dispatch({ type: "backward" });
    else dispatch({ type: "forward" });
  };

  return (
    <Flex
      position="relative"
      onClick={onClickBoard}
      style={{ touchAction: "manipulation" }}
      userSelect="none"
      {...props}
    >
      <PromoteSelect
        moveInput={moveInput}
        side={boardPosition.side}
        handleClick={handlePromoteClick}
      />
      <ApplyingBranchSelect
        moveInput={moveInput}
        handleClick={handleApplyingBranchClick}
        onClose={() => dispatch({ type: "clearMoveInput" })}
      />
      <Flex
        opacity={moveInput.status === "waitingPromote" ? 0.5 : 1}
        transition="opacity .2s"
        onClick={() => {
          if (moveInput.status === "waitingPromote") {
            dispatch({ type: "clearMoveInput" });
          }
        }}
      >
        <HandBoard
          side="w"
          hand={boardPosition.hand.w}
          onClickHand={onClickHand}
          highlightPieces={boardPosition.side === "w" ? highlightHandPiece : []}
        />
        <Box
          shadow="md"
          mx={{ base: "1px", md: 3 }}
          borderColor="gray.600"
          border="2px"
          borderRadius="md"
          position="relative"
        >
          <Grid
            templateColumns={{
              base: `repeat(9, ${BasePieceWidthPx.base})`,
              md: `repeat(9, ${BasePieceWidthPx.md})`
            }}
            templateRows={{
              base: `repeat(9, ${BasePieceHeightPx.base})`,
              md: `repeat(9, ${BasePieceHeightPx.md})`
            }}
            gap="2px"
            bg="gray.600"
          >
            {boardPosition.cells.flat().map((piece, idx) => (
              <Piece
                key={idx}
                piece={piece}
                highlight={highlightIdices.includes(idx)}
                onClick={() => {
                  const cell: Cell = {
                    file: 9 - (idx % 9),
                    rank: Math.floor(idx / 9) + 1
                  };
                  onClickCell(cell);
                }}
              />
            ))}
          </Grid>
        </Box>
        <HandBoard
          side="b"
          hand={boardPosition.hand.b}
          onClickHand={onClickHand}
          highlightPieces={boardPosition.side === "b" ? highlightHandPiece : []}
        />
      </Flex>
    </Flex>
  );
};

const PromoteSelect: React.FC<{
  moveInput: MoveInput;
  side: "b" | "w";
  handleClick: (promote: boolean) => void;
}> = ({ moveInput, side, handleClick }) => {
  if (moveInput.status !== "waitingPromote") return null;
  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
      borderColor="gray.600"
      border="2px"
      borderRadius="md"
      shadow="md"
      bg="orange.100"
      zIndex={10}
    >
      <Box display="flex">
        <Piece
          m={3}
          piece={{ piece: `+${moveInput.from.piece}` as ShogiPiece, side }}
          onClick={() => handleClick(true)}
        />
        <Piece
          m={3}
          piece={{ piece: moveInput.from.piece, side }}
          onClick={() => handleClick(false)}
        />
      </Box>
    </Box>
  );
};

const ApplyingBranchSelect: React.FC<{
  moveInput: MoveInput;
  handleClick: (branch: "branch" | "main") => void;
  onClose: () => void;
}> = ({ moveInput, handleClick, onClose }) => {
  return (
    <Modal
      isOpen={moveInput.status === "waitingApplyingBranch"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalBody mt="4">
          <Box textAlign="center">
            <Icon name="warning" size="16px" mb="1" mr="1" />
            以降の局面が破棄されます。本筋を更新しますか？
          </Box>
          <ModalFooter>
            <Button
              mr="3"
              variantColor="teal"
              onClick={() => handleClick("main")}
            >
              はい
            </Button>
            <Button variant="ghost" onClick={() => handleClick("branch")}>
              破棄せずに分岐する
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
