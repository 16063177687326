export type KifuApiEntry = LoadingEntry | ValidEntry | InvalidEntry;

export type LoadingEntry = {
  type: "loading";
  url: string;
  apiName?: string;
  gameList: GameListEntry[];
};

export type ValidEntry = {
  type: "valid";
  url: string;
  apiName: string;
  gameList: GameListEntry[];
};

export type InvalidEntry = {
  type: "invalid";
  url: string;
  apiName?: string;
  message?: string;
  gameList: GameListEntry[];
};

export type GameListEntry = {
  tag: string[];
  kifuUrl: string;
  displayName: string;
  displayTimestamp: number;
};

export function getInitialKifuApi(): KifuApiEntry[] {
  return [];
}
