import { State } from "../state";
import { Action } from "../actions";

export function kifuLoaderReducer(
  state: State["kifuLoader"],
  action: Action
): State["kifuLoader"] {
  switch (action.type) {
    case "openKifuLoaderModal":
      return { isModalOpen: true };
    case "closeKifuLoaderModal":
      return { isModalOpen: false };
  }
  return state;
}
