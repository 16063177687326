import { UsageLimit } from "../../../serverless/src/sharedTypes/usage";

export type GameInitSequence =
  | Initial
  | KifuLoaded
  | ScoreCacheLoaded
  | AskingCalcGameScores
  | CalcGameScoreStarted
  | Finished;

export type Initial = { type: "initial" };

export type KifuLoaded = { type: "kifuLoaded" };

export type ScoreCacheLoaded = { type: "scoreCacheLoaded" };

export type AskingCalcGameScores = {
  type: "askingCalcGameScores";
  usageLimit: UsageLimit[];
};

export type CalcGameScoreStarted = { type: "calcGameScoreStarted" };

export type Finished = { type: "finished" };

export function getInitialGameInitSequenceState(): GameInitSequence {
  return { type: "initial" };
}
