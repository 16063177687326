import { State } from "../state";
import { Action } from "../actions";

export function settingReducer(state: State, action: Action): State["setting"] {
  const { setting } = state;
  switch (action.type) {
    case "rotateBoard":
      return {
        ...setting,
        rotateBoard: action.rotate
      };
    default:
      return setting;
  }
}
