import React, { useEffect } from "react";
import {
  BoxProps,
  Box,
  Heading,
  List,
  ListItem,
  ThemeProvider,
  CSSReset,
  Divider,
  Text
} from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import { analytics } from "../../firebase";

const disclaimer = [
  "ユーザーは、当サービスを自身の責任と判断で利用するものとします。",
  "当サービスを利用することにより発生したいかなる損害も、当方は責任を負わないものとします。",
  "当サービスは、事前の予告なくサービス内容を変更、停止、終了する場合があります。当方は、これによってユーザーに生じた損害について一切の責任を負いません。",
  "サービスの運営には万全を期しておりますが、これを保証するものではございません。",
  "当方は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。"
];

const prohibition = [
  "他のユーザーのユーザー資格を利用して当サービスを利用すること",
  "自動化された手段により当サービスのサーバーに不当な負荷を加えること",
  "リバースエンジニアリング等の手段によって知り得た、当方が明示的に公開していない当サービスに関しての情報を公開すること",
  "当サービスのサーバー上の計算処理を、当サービスが定めた利用範囲を超えて呼び出すプログラムを公開すること"
];

const privacyPolicy = [
  "当サービスにご登録頂いた個人情報は、当サービスがその機能を提供する目的でのみ使用します。",
  "当サービスでは、個人情報は適切に管理し、第三者に開示することはありません。ただし、ユーザー本人のご了解がある場合と、法令等への協力のため開示が必要となる場合はこの限りではありません。",
  "本サービスは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。",
  "修正された最新のプライバシーポリシーは常に本ページにて開示されます。"
];

export const Agreement: React.FC<BoxProps> = props => {
  const history = useHistory();

  useEffect(() => {
    analytics.logEvent("page_view_agreement");
    window.document.title = "KENTO | 利用規約";
  }, []);

  return (
    <ThemeProvider>
      <CSSReset />
      <Box
        my={{ base: 2, md: 4 }}
        mx="auto"
        px={{ base: 1, md: 8 }}
        maxWidth="1024px"
        color="gray.700"
      >
        <Box display="flex" alignItems="flex-end">
          <Heading w="100%" onClick={() => history.push("/")}>
            <span style={{ cursor: "pointer", userSelect: "none" }}>
              ☗ KENTO
            </span>
          </Heading>
        </Box>
        <Box fontSize="sm" mt="8" mx="2" {...props}>
          <Heading size="md" mt="4">
            利用規約
          </Heading>
          <Divider />
          <Heading size="sm" my="2">
            免責事項
          </Heading>
          <List styleType="disc">
            {disclaimer.map((line, idx) => (
              <ListItem key={idx}>{line}</ListItem>
            ))}
          </List>
          <Heading size="sm" my="2">
            禁止事項
          </Heading>
          <Text my="2">
            ユーザーはいかなる場合においても以下のことを行わないものとします。
          </Text>
          <List styleType="disc">
            {prohibition.map((line, idx) => (
              <ListItem key={idx}>{line}</ListItem>
            ))}
          </List>
          <Heading size="md" mt="12">
            プライバシーポリシー
          </Heading>
          <Divider />
          <List styleType="disc" mt="2">
            {privacyPolicy.map((line, idx) => (
              <ListItem key={idx}>{line}</ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
