import { Map } from "immutable";
import { Move } from "shogi-ts";
import { About, getInitialAboutState } from "./about";
import { FlashMessage } from "./flashMessage";
import { GameState, getInitialState as getInitialGameState } from "./game";
import {
  GameInitSequence,
  getInitialGameInitSequenceState,
} from "./gameInitSequence";
import { getInitialKifuApi, KifuApiEntry } from "./kifuApi";
import { getInitialKifuLoaderState, KifuLoader } from "./kifuLoader";
import { getInitialLoginModalState, LoginModal } from "./loginModal";
import { MoveInput } from "./moveInput";
import { ScoreDatabaseMulti } from "./scoreDatabaseMulti";
import { ScoreDetail } from "./scoreDetail";
import { getInitialSetting, Setting } from "./setting";
import { User } from "./user";

export type State = {
  gameInitSequence: GameInitSequence;
  gameState: GameState;
  moveInput: MoveInput;
  scoreDatabaseMulti: ScoreDatabaseMulti;
  kifuLoader: KifuLoader;
  about: About;
  setting: Setting;
  showWarsError: boolean;
  loginModal: LoginModal;
  user: User;
  errorMessage: string | null;
  flashMessageQueue: FlashMessage[];
  scoreDetail: ScoreDetail;
  kifuApi: KifuApiEntry[];
};

export const initialState: State = {
  gameInitSequence: getInitialGameInitSequenceState(),
  gameState: getInitialGameState(),
  moveInput: { status: "waitingFrom" },
  scoreDatabaseMulti: Map(),
  kifuLoader: getInitialKifuLoaderState(),
  about: getInitialAboutState(),
  setting: getInitialSetting(),
  showWarsError: false,
  loginModal: getInitialLoginModalState(),
  user: { type: "loading" },
  errorMessage: null,
  flashMessageQueue: [],
  scoreDetail: { type: "closed" },
  kifuApi: getInitialKifuApi(),
};

export type Score = {
  bestMove?: Move;
  depth?: number;
  seldepth?: number;
  nodes?: number;
  pv?: Move[];
  scoreCp?: number;
  scoreMate?: number;
  hashfull?: number;
  nps?: number;
};

export function getLoginStatus(state: State) {
  return state.user.type;
}
