import { Map } from "immutable";
import { Action } from "../actions";
import { TempEvalResultMulti } from "../evalMulti";
import { getRealPriority, PerfTypeMulti } from "../perfMulti";
import { State } from "../state";
import {
  getScore,
  ScoreDatabaseMulti,
  setScore,
  removeScore
} from "../state/scoreDatabaseMulti";
import { getEvalSetting } from "../state/user";

function setEmptyEntry(
  db: ScoreDatabaseMulti,
  sfen: string,
  perf: PerfTypeMulti,
  multiPv: number
): ScoreDatabaseMulti {
  const crr = (db.get(sfen) || []).filter(
    e => e.perf !== perf || e.reqMultiPv !== multiPv
  );
  const next = [
    ...crr,
    {
      type: "temp",
      seqId: -1,
      perf,
      sfen,
      reqMultiPv: multiPv,
      score: { multiScores: [] }
    } as TempEvalResultMulti
  ];
  return db.set(sfen, next);
}

export function scoreDatabaseMultiReducer(
  state: State,
  action: Action
): State["scoreDatabaseMulti"] {
  let db = state.scoreDatabaseMulti;
  const evalSetting = getEvalSetting(state.user, state.gameState);
  switch (action.type) {
    case "narabeGame":
    case "loadGameSuccess": {
      return Map();
    }
    case "calcGameScoresStart": {
      for (const sfen of state.gameState.game.sfenPositions) {
        if (getScore(db, sfen, evalSetting.evalMultiPv)) continue;
        db = setEmptyEntry(
          db,
          sfen,
          evalSetting.evalGamePerf,
          evalSetting.evalMultiPv
        );
      }
      if (state.gameState.branch) {
        for (const sfen of state.gameState.branch.branch.sfenPositions) {
          if (getScore(db, sfen, evalSetting.evalMultiPv)) continue;
          db = setEmptyEntry(
            db,
            sfen,
            evalSetting.evalGamePerf,
            evalSetting.evalMultiPv
          );
        }
      }
      return db;
    }
    case "loadBranchScoreMultiStart": {
      return setEmptyEntry(
        db,
        action.sfen,
        evalSetting.evalSinglePerf,
        evalSetting.evalMultiPv
      );
    }
    case "receiveCompletedEvalResultMulti": {
      const { evalResult } = action;
      db = removeScore(db, evalResult.sfen, action.reqMultiPv);
      return setScore(db, evalResult);
    }
    case "receiveReloadedCacheGameScores":
    case "receiveCacheGameScores": {
      for (const evalResult of action.evalResultsMulti) {
        db = setScore(db, evalResult);
      }
      return db;
    }
    case "emitBufferedTempEvalResultsMulti": {
      for (const evalResult of action.evalResults) {
        db = processTempEvalResult(db, evalResult);
      }
      return db;
    }
    case "emitBufferedCalcGameEvalResults": {
      for (const evalResult of action.evalResults) {
        db = setScore(db, evalResult);
      }
      return db;
    }
  }
  return db;
}

function processTempEvalResult(
  db: ScoreDatabaseMulti,
  evalResult: TempEvalResultMulti
): ScoreDatabaseMulti {
  const currentScore = getScore(db, evalResult.sfen, evalResult.reqMultiPv);
  if (
    currentScore === undefined ||
    (currentScore?.type === "temp" && evalResult.seqId > currentScore.seqId) ||
    (currentScore?.type === "completed" &&
      getRealPriority(evalResult) > getRealPriority(currentScore))
  ) {
    return setScore(db, evalResult);
  }
  return db;
}
