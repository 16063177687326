import {
  Badge,
  Box,
  BoxProps,
  Divider,
  Heading,
  Spinner,
  Text
} from "@chakra-ui/core";
import React from "react";
import { FaCrown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { convertMoveJp } from "shogi-ts";
import { ScoreEntry } from "../../evalMulti";
import { State } from "../../state";
import {
  displayScore,
  getScoreView,
  ScoreView,
  useUpgradeScore
} from "./scoreMoveUtil";
import { Dispatch } from "../../store";
import { applyPv } from "../../actions/game";

const WebFont = "kozuka-gothic-pro";
export type ScoreMoveMultiProps = { isScreenShot?: boolean } & BoxProps;

export const ScoreMoveMulti: React.FC<ScoreMoveMultiProps> = ({
  isScreenShot,
  ...props
}: ScoreMoveMultiProps) => {
  const {
    type,
    candidates,
    bestScore,
    isProPerf,
    canUpgrade,
    isCalculating
  } = useSelector<State, ScoreView>(state =>
    getScoreView(state, state.gameState.turn)
  );
  const upgradeScore = useUpgradeScore();

  const isSinglePv = candidates.length <= 1;
  const textColor = canUpgrade ? "gray.700" : "gray.700";
  const grayTextColor = canUpgrade ? "gray.500" : "gray.500";
  const onClickScore = canUpgrade ? upgradeScore : () => {};

  return (
    <Box {...props}>
      <Box onClick={onClickScore}>
        <Box display="flex" alignItems="flex-end">
          {isProPerf ? (
            <Box as={FaCrown} color="yellow.500" mb="0.25rem" mr="2" />
          ) : null}
          <Text
            {...(isScreenShot ? { fontFamily: WebFont } : {})}
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="sm"
            letterSpacing="wide"
            color="teal.600"
            mr="2"
          >
            score
          </Text>
          {!isScreenShot && type === "low_perf" ? (
            <Badge h="21px" lineHeight="21px" variantColor="red">
              低精度
            </Badge>
          ) : null}
          {!isScreenShot && type === "pv" ? (
            <Badge h="21px" lineHeight="21px" variantColor="teal">
              読み筋
            </Badge>
          ) : null}
        </Box>
        <Box display="flex">
          {isCalculating ? (
            <Box height="45px" display="flex" mr={1} alignItems="center">
              <Spinner speed="0.65s" />
            </Box>
          ) : null}
          {displayScore(bestScore) ? (
            <Heading
              lineHeight="45px"
              fontWeight="semibold"
              display="flex"
              alignItems="flex-end"
            >
              <Box
                {...(isScreenShot ? { fontFamily: WebFont } : {})}
                color={textColor}
              >
                {displayScore(bestScore)}
              </Box>
            </Heading>
          ) : null}
        </Box>
      </Box>
      <Text
        {...(isScreenShot ? { fontFamily: WebFont } : {})}
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="sm"
        letterSpacing="wide"
        color="teal.600"
        mt="4"
      >
        candidates
      </Text>
      <Box mt="2" ml={isSinglePv ? 0 : "-6px"}>
        {candidates.map((candidate, idx) => (
          <Candidate
            key={idx}
            rank={idx}
            candidate={candidate}
            textColor={textColor}
            grayTextColor={grayTextColor}
            lastElem={idx === candidates.length - 1}
            isSinglePv={isSinglePv}
            scoreType={type}
            my="1"
            isScreenShot={isScreenShot}
          />
        ))}
      </Box>
    </Box>
  );
};

type CandidateProps = {
  candidate: ScoreEntry;
  textColor: string;
  grayTextColor: string;
  lastElem: boolean;
  isSinglePv: boolean;
  rank: number;
  scoreType: "low_perf" | "normal" | "pv";
  isScreenShot?: boolean;
} & BoxProps;
const Candidate: React.FC<CandidateProps> = ({
  candidate,
  rank,
  textColor,
  grayTextColor,
  lastElem,
  isSinglePv,
  scoreType,
  isScreenShot,
  ...props
}) => {
  const dispatch = useDispatch<Dispatch>();
  const onClickCandidate = () => {
    if (scoreType === "low_perf" || scoreType === "normal") {
      dispatch(applyPv(rank));
    }
    if (scoreType === "pv" && rank === 0) {
      dispatch({ type: "forward" });
    }
  };
  const move = candidate.pv?.[0];
  const moveJp = move ? convertMoveJp(move) : "-";
  const pvLength = isSinglePv ? 7 : 4;

  if (rank === 0) {
    const pv = candidate.pv?.slice(1, pvLength) || [];
    const pvJp = pv.map(convertMoveJp);
    return (
      <Box {...props} onClick={onClickCandidate}>
        <Box display="flex" alignItems="flex-end">
          {isSinglePv ? null : (
            <Box width="22px" mr="1">
              <Text
                {...(isScreenShot ? { fontFamily: WebFont } : {})}
                fontSize="sm"
                letterSpacing="wide"
                color="gray.400"
              >
                #{rank + 1}
              </Text>
            </Box>
          )}
          <Box>
            <Text
              fontWeight="semibold"
              fontSize="2xl"
              mb="-3px"
              color={textColor}
              {...(isScreenShot ? { fontFamily: WebFont } : {})}
            >
              {moveJp}
            </Text>
            <Box display="flex" flexWrap="wrap" ml="1">
              {pvJp.map((pv, idx) => (
                <Text
                  {...(isScreenShot ? { fontFamily: WebFont } : {})}
                  color={grayTextColor}
                  key={idx}
                  fontSize="sm"
                  whiteSpace="nowrap"
                  mr="1px"
                >
                  {pv}
                </Text>
              ))}
            </Box>
          </Box>
        </Box>
        {!lastElem ? <Divider /> : null}
      </Box>
    );
  }

  return (
    <Box {...props} onClick={onClickCandidate}>
      <Box display="flex" alignItems="flex-end">
        <Box width="22px" mr="1">
          <Text
            {...(isScreenShot ? { fontFamily: WebFont } : {})}
            fontSize="sm"
            letterSpacing="wide"
            color="gray.400"
          >
            #{rank + 1}
          </Text>
        </Box>
        <Box width="120px">
          <Text
            fontWeight="semibold"
            fontSize="lg"
            mb="-3px"
            color={textColor}
            {...(isScreenShot
              ? { fontFamily: WebFont, fontSize: "md", mb: "0" }
              : {})}
          >
            {moveJp}
          </Text>
        </Box>
        <Box>
          <Text
            {...(isScreenShot ? { fontFamily: WebFont } : {})}
            fontWeight="semibold"
            fontSize="sm"
            color={grayTextColor}
          >
            {displayScore(candidate)}
          </Text>
        </Box>
      </Box>
      {!lastElem ? <Divider /> : null}
    </Box>
  );
};
