import axios from "axios";
import {
  Game,
  parseCsa,
  ParseError,
  parseKif,
  ParseSuccess,
  validateGame
} from "shogi-ts";
import { analytics } from "../firebase";
import { ThunkAction } from "../store";
import { invalidGameAction, parseErrorAction } from "./flashMessage";

export type GameActions =
  | LoadGameStartAction
  | LoadGameSuccessAction
  | NarabeGameAction
  | NarabeGameParsedAction
  | EnableBranchAction
  | DisableBranchAction
  | ApplyPvAction;

export type LoadGameStartAction = {
  type: "loadGameStart";
  url: string;
};

export type LoadGameSuccessAction = {
  type: "loadGameSuccess";
  game: Game;
  url: string;
  turn: number;
  branch?: Branch;
};

export type NarabeGameAction = {
  type: "narabeGame";
  sfenMoves: string[];
  turn: number;
  initpos?: string;
  branch?: Branch;
};

export type NarabeGameParsedAction = {
  type: "narabeGameParsed";
  game: Game;
  turn: number;
  branch?: Branch;
};

export type EnableBranchAction = {
  type: "enableBranch";
};

export type DisableBranchAction = {
  type: "disableBranch";
};

export type ApplyPvAction = {
  type: "applyPv";
  candidate: number;
};

export type Branch = {
  branchMoves: string[];
  branchFrom: number;
};

export function startNarabeGame(
  turn: number,
  initpos: string | undefined,
  sfenMoves: string[],
  branch: Branch | undefined
): ThunkAction {
  analytics.logEvent("open_narabe");
  return dispatch => {
    dispatch({ type: "narabeGame", turn, initpos, sfenMoves, branch });
  };
}

export function startParsedGame(
  game: Game,
  branch: Branch | undefined
): ThunkAction {
  analytics.logEvent("start_parsed_game");
  return dispatch => {
    dispatch({ type: "narabeGameParsed", turn: game.turns - 1, game, branch });
  };
}

export function loadKifUrl(
  url: string,
  turn: number,
  branch: Branch | undefined
): ThunkAction {
  return async dispatch => {
    dispatch({ type: "loadGameStart", url });
    const { data } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_PROXY}?uri=${url}`
    );

    let parseResult: ParseSuccess | ParseError | undefined;
    if (url.endsWith(".kif")) {
      parseResult = parseKif(data);
    }
    if (url.endsWith(".csa")) {
      parseResult = parseCsa(data);
    }

    if (!parseResult || parseResult.type === "parse_error") {
      analytics.logEvent("error_parse_kifu");
      dispatch(parseErrorAction(parseResult ? parseResult.message : undefined));
      dispatch(startNarabeGame(0, undefined, [], undefined));
      return;
    }
    const result = validateGame(parseResult.game);
    if (result.type === "invalid") {
      analytics.logEvent("error_invalid_kifu");
      dispatch(invalidGameAction(result.turn, result.message));
    }

    const game =
      result.type === "valid" ? parseResult.game : (result.validGame as Game);

    analytics.logEvent("open_kifu");
    turn = Math.max(0, Math.min(game.turns, turn));
    dispatch({
      type: "loadGameSuccess",
      url,
      game,
      turn,
      branch
    });
  };
}

export function loadSavedGame(
  url: string,
  turn: number,
  branch: Branch | undefined
): ThunkAction {
  analytics.logEvent("open_saved");
  return async (dispatch, _getState) => {
    dispatch({ type: "loadGameStart", url });
    const id = url.slice(5);
    const game: Game = (
      await axios.get(`${process.env.REACT_APP_ENDPOINT_SAVED}?id=${id}`)
    ).data;
    dispatch({ type: "loadGameSuccess", url, game, turn, branch });
  };
}

export function applyPv(candidate: number): ThunkAction {
  analytics.logEvent("apply_pv");
  return async dispatch => {
    dispatch({ type: "applyPv", candidate });
  };
}
