import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UsageLimit } from "../../../../serverless/src/sharedTypes/usage";
import { startNarabeGame } from "../../actions/game";
import { calcGameScore } from "../../actions/gameScore";
import { State } from "../../state";
import { Dispatch } from "../../store.js";

export const AskCalcGameModal: React.FC = () => {
  const usageLimit = useSelector<State, UsageLimit[] | null>(
    ({ gameInitSequence }) =>
      gameInitSequence.type === "askingCalcGameScores"
        ? gameInitSequence.usageLimit
        : null
  );
  const isOpen = usageLimit !== null;
  const actionLimit = usageLimit?.find((l) => l.action === "kifu_analysis")
    ?.limit;
  const dispatch = useDispatch<Dispatch>();
  const initialRef = React.useRef();

  const handleStart = () => {
    dispatch(calcGameScore());
  };
  const handleCancel = () => {
    dispatch(startNarabeGame(0, undefined, [], undefined));
  };
  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={initialRef as any}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent rounded="lg" ref={initialRef}>
        <ModalHeader textAlign="center">棋譜を解析しますか？</ModalHeader>
        <ModalBody mb="4">
          <Box fontSize="sm">本日残り {actionLimit} 局</Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleStart} variantColor="blue" mr={3}>
            解析する
          </Button>
          <Button onClick={handleCancel}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
